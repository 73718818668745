import "react-resizable/css/styles.css";

import { differenceInDays } from "date-fns";
import { useState } from "react";
import { ResizableBox } from "react-resizable";

export interface CalendarTaskI {
  end: Date;
  id: number;
  name: string;
  start: Date;
}

export default function CalendarTask({
  task,
  index,
  incrementPerDay,
  currentMonth,
}: {
  currentMonth: Date;
  incrementPerDay: number;
  index: number;
  task: CalendarTaskI;
}) {
  const [startWidth, setStartWidth] = useState<number>();
  const [deltaWidth, setDeltaWidth] = useState<number>();
  return (
    <ResizableBox
      width={differenceInDays(task.end, task.start) * incrementPerDay}
      height={20}
      draggableOpts={{ grid: [incrementPerDay, 0] }}
      minConstraints={[10, 20]}
      maxConstraints={[Infinity, 20]}
      onResize={(e, { size }) => {
        setDeltaWidth((startWidth || 0) - size.width);
      }}
      onResizeStart={(e, { size }) => {
        setStartWidth(size.width);
        setDeltaWidth(0);
      }}
      onResizeStop={() => {
        setStartWidth(undefined);
      }}
      resizeHandles={["e"]}
      className="absolute left-1/2 mt-6 bg-background whitespace-nowrap"
      style={{
        marginLeft: `${differenceInDays(task.start, currentMonth) * incrementPerDay}px`,
        top: `${index * 32}px`,
        // width: `${differenceInDays(task.end, task.start) * incrementPerDay}px`,
      }}
    >
      {task.name}
      {startWidth && (
        <div className="absolute right-0">
          {-(deltaWidth || 0) / incrementPerDay}d
        </div>
      )}
    </ResizableBox>
  );
}
