import { useAppSelector } from "@redux/hooks";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useEscapeBack() {
  const navigate = useNavigate();
  const { isPopupOpen } = useAppSelector((state) => state.app);
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!isPopupOpen && event.key === "Escape") {
        navigate(-1);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [history, isPopupOpen]);
}
