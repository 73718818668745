import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  currentUser?: string;
  isLoggedIn: boolean;
  token: string | null;
}

const initialState: AuthState = {
  isLoggedIn: false,
  token: null,
};

export const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    clearToken(state) {
      state.token = null;
      state.isLoggedIn = false;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    setCurrentUser(state, action: PayloadAction<string>) {
      state.currentUser = action.payload;
    },
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
      state.isLoggedIn = true;
    },
  },
});
