import { DropdownChoice } from "../slate-extension";

import * as all from "./choice-types";

import { useCurrentProject } from "hooks/useCurrentProject";
import { flatten } from "lodash";
const useChoiceTypes = ({
  search,
  withoutChoicesFrom = [],
}: {
  search: string;
  withoutChoicesFrom?: string[];
}) => {
  const { projectUsers } = useCurrentProject();
  const allUsernames = Object.values(projectUsers);

  const searchChoices = (dropdownChoice: DropdownChoice[]) => {
    return dropdownChoice
      .filter((c) => c.value.toLowerCase().startsWith(search.toLowerCase()))
      .slice(0, 5); //first 5 results;
  };
  const results = flatten(
    Object.entries(all)
      .filter(([key, c]) => !withoutChoicesFrom.includes(key))
      .map(([key, c]) =>
        searchChoices(c.getChoices({ allUsers: allUsernames })),
      ),
  ).map((choice, index) => ({ ...choice, index }));
  return { results };
};

export default useChoiceTypes;
