import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { appSlice } from "@redux/slices/app.slice";
import { authSlice } from "@redux/slices/auth.slice";

import {
  useLogoutMutation,
  useUpdateMeMutation,
} from "modules/api/generated-api";
import { useEffect } from "react";

export const useDeviceId = () => {
  const dispatch = useAppDispatch();
  const [updateMe] = useUpdateMeMutation();
  const [logout] = useLogoutMutation();
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const { deviceId, pushToken, pushTokenUpdated } = useAppSelector(
    (state) => state.app.flags,
  );

  useEffect(() => {
    if (deviceId) return;
    dispatch(
      appSlice.actions.setFlag({
        deviceId: Math.random().toString(36).substr(2, 10),
      }),
    );
  }, [deviceId, dispatch]);

  useEffect(() => {
    if (pushTokenUpdated || !pushToken || !deviceId || !isLoggedIn) return;
    dispatch(
      appSlice.actions.setFlag({
        pushTokenUpdated: true,
      }),
    );
    updateMe({
      input: { token: { device: deviceId, token: pushToken } },
    }).catch((err) => {
      dispatch(
        appSlice.actions.setFlag({
          pushTokenUpdated: false,
        }),
      );
      throw err;
    });
  }, [deviceId, pushToken, pushTokenUpdated, dispatch, updateMe, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn || !pushTokenUpdated) return;
    dispatch(
      appSlice.actions.setFlag({
        pushTokenUpdated: false,
      }),
    );
    logout({ input: { device: deviceId || "" } }).finally(() => {
      dispatch(authSlice.actions.clearToken());
    });
  }, [deviceId, dispatch, isLoggedIn, logout, pushTokenUpdated]);

  return { deviceId };
};
