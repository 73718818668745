import { lightOrDark } from "modules/design/functions";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const styles = tv({
  compoundVariants: [
    {
      class: {
        container: "px-2 py-0 min-h-fit text-sm",
      },
      mode: "minimal",
    },
    {
      class: {
        container: "",
      },
      mode: "normal",
    },
  ],
  slots: {
    container: "px-4 py-1 rounded-lg min-h-8 truncate max-w-40",
  },
  variants: {
    mode: {
      minimal: {},
      normal: {},
    },
  },
});

type Variant = {
  [K in keyof (typeof styles)["variants"]]: keyof (typeof styles)["variants"][K];
};

type LabelMode = Variant["mode"];

export function Label({
  color,
  title,
  className,
  mode = "normal",
}: {
  className?: string;
  color: string;
  mode?: LabelMode;
  title: string;
}) {
  const classNames = styles({ mode });
  const isLightOrDark = useMemo(() => lightOrDark(color), [color]);

  return (
    <div
      className={twMerge(
        classNames.container(),
        isLightOrDark === "dark" ? "text-white" : "text-black",
        className,
      )}
      style={{ backgroundColor: color }}
    >
      {title}
    </div>
  );
}
