import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  GetOneTaskQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class DailyProgressCreatedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { dailyProgressCreated } = subscriptionResponse;
    if (dailyProgressCreated) {
      // @TODO: remove if not needed
      // if (dailyProgressCreated.task) {
      //   const { task } = findTask({
      //     columnId: dailyProgressCreated.task.column!._id,
      //     draft,
      //     taskId: dailyProgressCreated.task._id,
      //   });
      //   // eslint-disable-next-line unused-imports/no-unused-vars
      //   const { task: discardedTask, ...reduxDailyProgress } =
      //     dailyProgressCreated;
      //   task.dailyProgress?.push(reduxDailyProgress);
      // }
      if (draft.project.currentSprint) {
        draft.project.currentSprint.dailyProgress.push(dailyProgressCreated);
      }
    }
  }
  handleTaskUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneTaskQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }): void {
    const { dailyProgressCreated } = subscriptionResponse;
    if (dailyProgressCreated) {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { task: discardedTask, ...reduxDailyProgress } =
        dailyProgressCreated;
      draft.task.dailyProgress?.push(reduxDailyProgress);
    }
  }
  getTaskId(subscriptionResponse: ProjectSubscriptionResponse) {
    return subscriptionResponse.dailyProgressCreated!.task?._id;
  }
  forSubscription: keyof ProjectSubscriptionResponse = "dailyProgressCreated";
}
