import { ForwardedRef, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const styles = tv({
  compoundVariants: [
    {
      class: { button: "bg-primary hover:bg-primary-hover focus:ring-primary" },
      color: "primary",
    },
    {
      class: {
        button: "bg-success hover:bg-success-hover focus:ring-success",
      },
      color: "secondary",
    },
    {
      class: {
        button:
          "bg-white dark:bg-zinc-900 dark:text-white hover:bg-white-hover focus:ring-white text-ink",
      },
      color: "white",
    },
    {
      class: {
        button: "px-2 py-0",
      },
      size: "small",
    },
    {
      class: {
        button: "text-ink shadow-none hover:text-ink-dim",
      },
      color: "transparent",
    },
  ],
  slots: {
    button:
      "px-4 py-2 font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 drop-shadow-md",
  },
  variants: {
    color: {
      primary: {},
      secondary: {},
      transparent: {},
      white: {},
    },
    size: {
      normal: {},
      small: {},
    },
  },
});

type Variant = {
  [K in keyof (typeof styles)["variants"]]: keyof (typeof styles)["variants"][K];
};

type ButtonColor = Variant["color"];
type ButtonSize = Variant["size"];

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  color?: ButtonColor;
  size?: ButtonSize;
  text: string;
}

const Button = forwardRef(
  (
    {
      className,
      color = "primary",
      size = "normal",
      text,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const classNames = styles({ color, size });

    return (
      <button
        ref={ref}
        {...props}
        className={twMerge(
          classNames.button(),
          className,
          props.disabled && "opacity-50",
        )}
      >
        {text}
      </button>
    );
  },
);

export default Button;
