import { useAppSelector } from "@redux/hooks";

import { AddEffortModal, AddEffortModalInputs } from "./AddEffortModal";
import { HintPopover } from "./HintPopover.component";

import { Assignee } from "components/Assignee";
import { Button, IconButton } from "components/Button";
import { SprintTimeSpent } from "components/TimeSpent/SprintTimeSpent";
import { useCurrentProject } from "hooks/useCurrentProject";
import { isNumber, replace, uniq } from "lodash";
import { SprintPlanItem } from "modules/api/generated-api";
import { calculateDays } from "modules/daily-progress/functions";
import { useDailyTasks } from "modules/daily-progress/hooks/useDailyTasks";
import { useEffect, useMemo, useRef, useState } from "react";
import ContentEditable from "react-contenteditable";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { FaCheck } from "react-icons/fa6";
import { GiSprint } from "react-icons/gi";
import { MdOutlineDelete } from "react-icons/md";
import { twMerge } from "tailwind-merge";

export function SprintPlanItemComponent({
  errors,
  onUpdate,
  sprintPlanItem,
  taskProgress,
}: {
  errors: Merge<FieldError, FieldErrorsImpl<SprintPlanItem>> | undefined;
  onUpdate: (update: Partial<SprintPlanItem>, shouldDelete?: boolean) => void;
  sprintPlanItem: SprintPlanItem;
  taskProgress?: ReturnType<typeof useDailyTasks>["tasks"];
}) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { tasks, projectUsers } = useCurrentProject();
  const contentEditableRef = useRef();

  const [addingEffort, setAddingEffort] = useState(false);
  const [addingEffortDefaults, setAddingEffortDefaults] = useState<
    AddEffortModalInputs | undefined
  >({
    user: currentUser,
  } as AddEffortModalInputs);
  const [effort, setEffort] = useState(sprintPlanItem.effort);
  const [featureName, setFeatureName] = useState(sprintPlanItem.featureName);
  const [showSprintPopover, setShowSprintPopover] = useState(false);

  useEffect(() => {
    setEffort(sprintPlanItem.effort);
  }, [sprintPlanItem.effort]);
  useEffect(() => {
    setFeatureName(sprintPlanItem.featureName);
  }, [sprintPlanItem.featureName]);

  const {
    totalDays: { hours, days },
    workedDays,
  } = useMemo(() => {
    const workedHours = taskProgress
      ? uniq(effort.map((effort) => effort.task))
          .flatMap((effortTask) => {
            return taskProgress
              .filter((task) => task._id === effortTask)
              .flatMap((task) => task.dailyProgress);
          })
          .reduce((acc, curr) => acc + curr.timeSpent, 0)
      : null;
    const totalHours = effort
      .map((eff) => eff.estimate ?? 0)
      .reduce((acc, curr) => acc + curr, 0);
    return {
      totalDays: calculateDays(totalHours),
      ...(workedHours ? { workedDays: calculateDays(workedHours) } : {}),
    };
  }, [effort, taskProgress]);

  return (
    <div
      className={twMerge(
        "border group/plan-item border-ink p-3 rounded-xl drop-shadow-lg group pb-5 min-h-50 gap-2 flex flex-col relative",
        sprintPlanItem.complete && "bg-success",
      )}
    >
      {/* Buttons */}
      <div className="absolute top-0 bottom-0 right-0 flex flex-col items-center justify-center gap-1">
        <IconButton
          color="transparent"
          className="size-8 px-0 py-0 -mr-4 hidden group-hover/plan-item:flex"
          onClick={() => {
            onUpdate({}, true);
          }}
          icon={
            <MdOutlineDelete
              className={twMerge(
                "fill-success bg-background rounded-full size-8 border-2 border-success p-0.5",
              )}
            />
          }
        />
        <IconButton
          color="transparent"
          className="size-8 px-0 py-0 -mr-4 hidden group-hover/plan-item:flex"
          onClick={() => {
            onUpdate({ complete: !sprintPlanItem.complete });
          }}
          icon={
            <FaCheck
              className={twMerge(
                "fill-success bg-background rounded-full size-8 border-2 border-success p-0.5",
              )}
            />
          }
        />
      </div>

      <div className="flex">
        <div className="inline-flex flex-1">
          {featureName.length === 0 && (
            <div className="absolute pointer-events-none text-ink italic">
              Add feature name...
            </div>
          )}
          <ContentEditable
            className={twMerge(
              "min-w-40",
              "inline-flex bg-transparent outline-none cursor-text",
            )}
            innerRef={contentEditableRef.current}
            html={featureName}
            disabled={false}
            onChange={(evt) => {
              const strippedHtml = replace(evt.target.value, /<[^>]*>/g, "");
              setFeatureName(strippedHtml);
              onUpdate({
                featureName: strippedHtml,
              });
            }}
          />
        </div>

        <div className="flex gap-1">
          {workedDays && !(workedDays.days === 0 && workedDays.hours === 0) && (
            <SprintTimeSpent
              className="inlne-flex flex-shrink-0 bg-error"
              time={{ days: workedDays.days, hours: workedDays.hours }}
            />
          )}
          <SprintTimeSpent
            className="inlne-flex flex-shrink-0"
            time={{ days, hours }}
          />
          {sprintPlanItem.noSprints && sprintPlanItem.noSprints > 0 && (
            <div
              onMouseEnter={() => setShowSprintPopover(true)}
              onMouseLeave={() => setShowSprintPopover(false)}
              className="relative flex items-center justify-center gap-0.5 bg-error text-white text-sm px-1 rounded-lg py-0 h-5"
            >
              <div>{sprintPlanItem.noSprints}x</div>
              <GiSprint />
              <HintPopover
                isOpen={showSprintPopover}
                message={`This feature has been there since the past ${sprintPlanItem.noSprints > 1 ? `${sprintPlanItem.noSprints} sprints` : "sprint"}`}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        {sprintPlanItem.effort.map((effort, index) => {
          const { days, hours } = calculateDays(effort.estimate || 0);
          return (
            <button
              key={`effort-${index}`}
              className={twMerge(
                "flex items-center justify-center p-2 bg-white rounded-xl gap-2 cursor-pointer",
                errors?.effort?.[index] && "border-2 border-error",
              )}
              onClick={() => {
                setAddingEffortDefaults({
                  ...effort,
                  updatingIndex: index,
                } as AddEffortModalInputs);
                setAddingEffort(true);
              }}
            >
              <div className="text-black">
                {tasks.find((task) => task._id === effort.task)?.counterId}
              </div>
              <Assignee name={projectUsers[effort.user || ""]?.name} />
              <SprintTimeSpent className="" time={{ days, hours }} />
            </button>
          );
        })}

        <Button
          className="rounded-full text-xl px-0 size-10 max-w-10"
          size="small"
          type="button"
          text="+"
          onClick={() => {
            setAddingEffort(true);
          }}
        />
      </div>
      {addingEffort && (
        <AddEffortModal
          defaultValues={addingEffortDefaults}
          onClose={(_values) => {
            if (_values) {
              const { updatingIndex, deleting, ...values } = _values;
              if (deleting) {
                const newEffort = [
                  ...effort.filter((_, index) => index !== updatingIndex),
                ];
                setEffort(newEffort);
                onUpdate({
                  effort: newEffort,
                });
              } else if (values) {
                let newEffort = effort.map((e) => ({ ...e }));
                if (isNumber(updatingIndex)) {
                  Object.assign(newEffort[updatingIndex], values);
                } else {
                  newEffort.push(values as SprintPlanItem["effort"][0]);
                }
                setEffort(newEffort);
                onUpdate({
                  effort: newEffort,
                });
              }
            }
            setAddingEffortDefaults(undefined);
            setAddingEffort(false);
          }}
        />
      )}
    </div>
  );
}
