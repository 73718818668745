import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme";
import * as Popover from "@radix-ui/react-popover";
import { TagSearchMention } from "components/SmartTextInput/TagSearchMention";
import { TimeSpent } from "components/TimeSpent";
import { twMerge } from "tailwind-merge";

export function ProgressInputPopover({ isOpen }: { isOpen: boolean }) {
  const preferredColorScheme = usePrefersColorScheme();

  return (
    <Popover.Root open={isOpen}>
      <div className="absolute inset-0 flex justify-center pointer-events-none -my-4">
        <Popover.Trigger className=""></Popover.Trigger>
      </div>
      <Popover.Portal>
        <Popover.Content
          onOpenAutoFocus={(e) => e.preventDefault()}
          className={twMerge(
            "bg-success rounded shadow-lg p-4 max-w-[250px] float-start",
            preferredColorScheme,
          )}
        >
          <Popover.Arrow className="fill-success" />
          <div className="">
            Use <span className="font-extrabold">"@"</span> to change the
            estimate or tag people
            <div className="mt-2 italic text-sm">
              <div>
                - <span className="font-extrabold">@2</span> sets the estimate
                to{" "}
                <TimeSpent
                  className={twMerge("inline-block bg-error")}
                  time={2}
                />
              </div>
              <div>
                - <span className="font-extrabold">@john</span> can tag
                <TagSearchMention
                  attributes={{}}
                  element={{ character: "John Doe" }}
                  children={null}
                />
                or anyone else in the team
              </div>
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
