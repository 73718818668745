import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  GetOneTaskQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class DailyProgressUpdatedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { dailyProgressUpdated } = subscriptionResponse;
    if (dailyProgressUpdated) {
      // @TODO: remove if not needed
      // if (dailyProgressUpdated.task) {
      //   const { task } = findTask({
      //     columnId: dailyProgressUpdated.task.column!._id,
      //     draft,
      //     taskId: dailyProgressUpdated.task._id,
      //   });
      //   if (task.dailyProgress) {
      //     const dailyProgressIndex = task.dailyProgress.findIndex(
      //       ({ _id }) => _id === dailyProgressUpdated._id,
      //     );
      //     // eslint-disable-next-line unused-imports/no-unused-vars
      //     const { task: discardedTask, ...reduxDailyProgress } =
      //       dailyProgressUpdated;
      //     task.dailyProgress[dailyProgressIndex] = {
      //       ...task.dailyProgress[dailyProgressIndex],
      //       ...reduxDailyProgress,
      //     };
      //   }
      // }
      const { currentSprint } = draft.project;
      if (currentSprint) {
        const dailyProgressIndex = currentSprint.dailyProgress.findIndex(
          ({ _id }) => _id === dailyProgressUpdated._id,
        );
        Object.assign(
          currentSprint.dailyProgress[dailyProgressIndex],
          dailyProgressUpdated,
        );
      }
    }
  }
  handleTaskUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneTaskQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }): void {
    const { dailyProgressUpdated } = subscriptionResponse;
    if (dailyProgressUpdated) {
      if (draft.task.dailyProgress) {
        const dailyProgressIndex = draft.task.dailyProgress.findIndex(
          ({ _id }) => _id === dailyProgressUpdated._id,
        );
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { task: discardedTask, ...reduxDailyProgress } =
          dailyProgressUpdated;
        draft.task.dailyProgress[dailyProgressIndex] = {
          ...draft.task.dailyProgress[dailyProgressIndex],
          ...reduxDailyProgress,
        };
      }
    }
  }
  getTaskId(subscriptionResponse: ProjectSubscriptionResponse) {
    return subscriptionResponse.dailyProgressUpdated!.task?._id;
  }
  forSubscription: keyof ProjectSubscriptionResponse = "dailyProgressUpdated";
}
