import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { AddLabelModal } from "./AddLabelModal";
import { AddProjectModal } from "./AddProjectModal";

import { Button, IconButton } from "components/Button";
import { Select } from "components/Dropdown/Select";
import { NotificationBell } from "components/Notification/NotificationBell.component";
import useAuth from "hooks/useAuth";
import { useCurrentProject } from "hooks/useCurrentProject";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  useEndCurrentSprintMutation,
  useGetAllUserProjectsQuery,
  useMeQuery,
  User,
} from "modules/api/generated-api";
import { useSelectLabelStyling } from "modules/design/hooks/useSelectLabelStyling";
import { useNavbar } from "modules/navigation/hooks/useNavbar";
import useRouteData from "modules/navigation/hooks/useRouteData";
import { useStandupTimeframe } from "modules/standup/hooks/useStandupTimeframe";
import { useEffect, useState } from "react";
import { NonUndefined } from "react-hook-form";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import { PROJECT_PATH, SPRINT_PATH, STANDUP_PATH } from "routes/paths";
import { twMerge } from "tailwind-merge";

export function Sidebar({}: {}) {
  const { data: userData } = useMeQuery();
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const projects = useAppSelector((state) => state.projects);
  const { vars } = useAppSelector((state) => state.projectsCache);
  const { data } = useGetAllUserProjectsQuery();
  const { tfRemaining } = useStandupTimeframe();
  const [addingProject, setAddingProject] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { project, projectLabels, projectUsers } = useCurrentProject();
  const [endSprint] = useEndCurrentSprintMutation();
  const projectsArray = data?.getAllUserProjects.elements;
  const selectedProject = projectsArray?.find(
    (project) => project._id === projects.current,
  );

  useEffect(() => {
    if (!data) return;
    if (
      data.getAllUserProjects.elements.findIndex(
        (project) => project._id === projectId,
      ) === -1 &&
      data.getAllUserProjects.elements.length > 0
    ) {
      navigate(
        PROJECT_PATH.replace(
          ":projectId",
          data.getAllUserProjects.elements[0]._id,
        ),
      );
    }
  }, [projectId, data, navigate]);
  useEffect(() => {
    const filterCardsByUser = searchParams.get("filterCardsByUser");
    if (filterCardsByUser) {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByUser,
        }),
      );
    }
    const filterCardsByLabel = searchParams.getAll("filterCardsByLabel");
    if (filterCardsByLabel.length > 0) {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByLabel,
        }),
      );
    }
  }, [dispatch, searchParams]);

  const { title, hasBack } = useRouteData();
  const { navbarConfig } = useNavbar();
  const { classNames: labelClassNames, styles: labelStyles } =
    useSelectLabelStyling();

  const handleProjectChange = (
    selection: SingleValue<NonUndefined<typeof projectsArray>[0]>,
  ) => {
    if (selection != null) {
      dispatch(projectCacheSlice.actions.setUserSubmittingProgress(false));
      navigate(PROJECT_PATH.replace(":projectId", selection._id));
    }
  };
  const handleUserChange = (selection: SingleValue<User>) => {
    if (selection == null) {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByUser: undefined,
        }),
      );
      searchParams.delete("filterCardsByUser");
      setSearchParams(searchParams);
    } else {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByUser: selection._id,
        }),
      );
      searchParams.set("filterCardsByUser", selection._id);
      setSearchParams(searchParams);
    }
  };
  const handleLabelChange = (
    selection: MultiValue<(typeof projectLabels)[0]>,
  ) => {
    if (selection.length === 0) {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByLabel: undefined,
        }),
      );
      searchParams.delete("filterCardsByLabel");
      setSearchParams(searchParams);
    } else {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByLabel: selection.map((l) => l._id),
        }),
      );
      searchParams.delete("filterCardsByLabel");
      selection.map((l) => searchParams.append("filterCardsByLabel", l._id));
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {/* For mobile  */}
      <div className="absolute flex md:hidden h-14 w-full bg-primary  items-center px-2 text-white">
        <div className="size-10 flex justify-center">
          {hasBack && (
            <IconButton
              color="transparent"
              className="top-0 left-0"
              onClick={() => {
                navigate(-1);
              }}
              icon={<IoIosArrowBack className={twMerge("size-8 fill-white")} />}
            />
          )}
          {!isOpen && !hasBack && (
            <IconButton
              color="transparent"
              className="top-0 left-0"
              onClick={() => {
                setIsOpen(true);
              }}
              icon={
                <GiHamburgerMenu className={twMerge("size-8 fill-white")} />
              }
            />
          )}
        </div>
        <div className="flex-1 text-center text-xl line-clamp-1">
          {navbarConfig.title ?? title}
        </div>
        <div className="size-10 flex justify-center">
          {navbarConfig.rightIcon ?? <NotificationBell className="md:hidden" />}
        </div>
      </div>
      {/* For desktop */}
      <div
        className={twMerge(
          "flex flex-col -translate-x-60 md:translate-x-0 min-w-60 w-60 bg-background h-screen p-4 drop-shadow-2xl z-20 duration-100 absolute md:relative rounded-r-xl",
          isOpen && "translate-x-0",
        )}
      >
        <div className="border-b border-gray -mx-4 px-4 pb-4 flex items-center justify-center gap-4">
          <div>
            <div>{userData?.me?.name}</div>
            <div>{userData?.me?.email}</div>
          </div>
          <NotificationBell position="right" className="hidden md:flex" />
        </div>
        {projectsArray && (
          <div className="p-2">
            <div className="font-semibold">Project:</div>
            <Select
              value={selectedProject}
              onChange={handleProjectChange}
              getOptionLabel={(project) => project.name}
              getOptionValue={(project) => project._id}
              isMulti={false}
              options={projectsArray}
              isClearable={false}
              classNames={{
                option: () => "!text-black ",
              }}
            />
          </div>
        )}
        {projectUsers && (
          <div className={twMerge("p-2 rounded-lg")}>
            <div className="font-semibold">Filter by user</div>
            <Select
              value={projectUsers[vars.filterCardsByUser!]}
              onChange={handleUserChange}
              getOptionLabel={(user) => user.name}
              getOptionValue={(user) => user._id}
              isMulti={false}
              options={Object.values(projectUsers)}
              isClearable
              classNames={{
                option: () => "!text-black ",
              }}
            />
          </div>
        )}
        {projectLabels && (
          <div className={twMerge("p-2 rounded-lg")}>
            <div className="font-semibold">Filter by label</div>
            <Select
              value={vars.filterCardsByLabel?.map((l) => projectLabels[l])}
              onChange={handleLabelChange}
              getOptionLabel={(label) => label.title}
              getOptionValue={(label) => label._id}
              isMulti={true}
              options={Object.values(projectLabels)}
              isClearable={false}
              styles={labelStyles}
              classNames={labelClassNames}
              onCreateOption={(input) => {
                dispatch(
                  projectCacheSlice.actions.setVar({ addingLabel: input }),
                );
              }}
            />
          </div>
        )}
        <div className="flex flex-col gap-2 pt-4 border-t border-gray -mx-4 px-4">
          <Button
            onClick={() => {
              navigate(STANDUP_PATH.replace(":projectId", projectId!));
            }}
            text="Go to Standup"
          />
          <Button
            onClick={() => {
              navigate(SPRINT_PATH.replace(":projectId", projectId!));
            }}
            text="Go to Sprint"
          />
          {userData && userData.me._id === project?.owner._id && (
            <Button
              size="small"
              color="secondary"
              onClick={async () => {
                apiToastPromise(
                  endSprint({ input: { project: project._id! } }),
                  {
                    error: "Unable to end sprint",
                    loading: "Ending sprint...",
                    success: ({ data }) =>
                      `"${project.name}" is now at Sprint ${data?.endCurrentSprint.sprintNumber}`,
                  },
                );
              }}
              text={`New Sprint ${project.currentSprint?.sprintNumber ? `(Sprint ${project.currentSprint?.sprintNumber + 1})` : ""}`}
            />
          )}
        </div>

        <div className="flex-1"></div>
        <div className="text-center text-xs">
          {process.env.REACT_APP_VERSION}
        </div>
        <div className="flex">
          <Button
            onClick={() => {
              logout();
            }}
            color="white"
            text="Logout"
            className="flex-1"
          />
        </div>
        {addingProject && (
          <AddProjectModal
            onClose={(addedProject) => {
              setAddingProject(false);
              if (addedProject) {
                navigate(PROJECT_PATH.replace(":projectId", addedProject._id));
              }
            }}
          />
        )}
        {vars.addingLabel && (
          <AddLabelModal
            initialTitle={vars.addingLabel}
            onClose={() => {
              dispatch(
                projectCacheSlice.actions.setVar({ addingLabel: undefined }),
              );
            }}
          />
        )}
      </div>
      {isOpen && (
        <div
          className="absolute inset-0 z-10"
          onClick={() => {
            setIsOpen(false);
          }}
        ></div>
      )}
    </>
  );
}
