import { useAppSelector } from "@redux/hooks";

import { isBusinessDay } from "../functions";

import { addDays } from "date-fns";
import { useCurrentProject } from "hooks/useCurrentProject";
import { useCallback, useMemo } from "react";

export function useStandupTimeframeFunctions() {
  const { project } = useCurrentProject();
  const { currentUser } = useAppSelector((state) => state.auth);

  const calculateDateBasedOnTimeframes = useCallback(
    ({ daysToAdd }: { daysToAdd: number }) => {
      if (!project) return new Date();
      let date = project?.standupStart;
      let daysAdded = 0;
      while (daysAdded < daysToAdd) {
        date = addDays(date, 1);
        if (isBusinessDay(date)) {
          daysAdded++;
        }
      }
      return date;
    },
    [project],
  );

  const currentUserDay = useMemo(() => {
    return calculateDateBasedOnTimeframes({
      daysToAdd: project?.userTimeframes[currentUser!] ?? 0,
    });
  }, [calculateDateBasedOnTimeframes, currentUser, project?.userTimeframes]);

  return {
    calculateDateBasedOnTimeframes,
    currentUserDay,
    userTf: project?.userTimeframes[currentUser!],
  };
}
