import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface Crumb {
  name: string;
  path: string;
}

interface BreadcrumbProps {
  className?: string;
  crumbs: Crumb[];
}

export function Breadcrumbs({ crumbs, className }: BreadcrumbProps) {
  const location = useLocation();

  return (
    <nav className={twMerge("flex", className)} aria-label="breadcrumbs">
      <ol className="list-none flex">
        {crumbs.map((crumb, index) => (
          <li key={crumb.path} className="flex items-center">
            {index !== 0 && (
              <svg
                className="flex-shrink-0 h-5 w-5 mr-1 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
            {index !== crumbs.length - 1 ? (
              <Link
                to={crumb.path}
                className={`text-gray-500 hover:text-gray-700 ${
                  location.pathname === crumb.path ? "font-semibold" : ""
                }`}
              >
                {crumb.name}
              </Link>
            ) : (
              <span
                aria-current="page"
                className="ml-1 text-gray-900 font-semibold"
              >
                {crumb.name}
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
