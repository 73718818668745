import {
  DropdownChoice,
  TagSearchChoiceType,
} from "components/SmartTextInput/slate-extension";

export const availableEstimates = ["0.5", "1", "2", "4", "6", "8"];

const Estimate: TagSearchChoiceType = {
  getChoices: () =>
    availableEstimates.map(
      (choice) =>
        ({
          type: "estimate",
          value: choice,
        }) as DropdownChoice,
    ),
};

export default Estimate;
