import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme";
import * as Popover from "@radix-ui/react-popover";
import { Assignee } from "components/Assignee";
import { useCurrentProject } from "hooks/useCurrentProject";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  GetAllUserNotificationsQuery,
  useGetAllUserNotificationsQuery,
  useMarkAllAsReadMutation,
  useUpdateNotificationMutation,
} from "modules/api/generated-api";
import {
  getNotificationHeader,
  getUserIds,
} from "modules/notification/functions";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  isAllRead: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export function NotificationPopover({
  isOpen,
  onClose,
  className,
  isAllRead,
}: Props) {
  const preferredColorScheme = usePrefersColorScheme();
  const { isLoading, data } = useGetAllUserNotificationsQuery();
  const [markAllAsRead] = useMarkAllAsReadMutation();

  return (
    <Popover.Root open={isOpen}>
      <div className="absolute inset-0 flex justify-center pointer-events-none -my-4">
        <Popover.Trigger className=""></Popover.Trigger>
      </div>
      <Popover.Portal>
        <Popover.Content
          align="start"
          onOpenAutoFocus={(e) => e.preventDefault()}
          className={twMerge(
            "bg-background rounded-xl shadow-md mx-2 min-w-[200px] min-h-10 max-w-[400px] float-start",
            className,
            preferredColorScheme,
          )}
        >
          <Popover.Arrow className="fill-gray-dim mx-2" />
          <div className="flex flex-col w-full text-ink">
            <div className="flex p-4  shadow border-b border-gray">
              <div className="font-bold flex-1">Notifications</div>
              {!isAllRead && (
                <button
                  className="text-primary"
                  onClick={() => {
                    apiToastPromise(markAllAsRead(), {
                      error: "Error marking all notifications as read",
                      loading: "Marking all as read",
                      success: "All notifications read",
                    });
                  }}
                >
                  Mark all as read
                </button>
              )}
            </div>
            <div className="w-full max-h-[50svh] overflow-y-scroll">
              {data?.getAllUserNotifications.length === 0 && (
                <div className="p-4">🎉 No notifications!</div>
              )}
              {data?.getAllUserNotifications.map((notification) => (
                <NotificationRow
                  key={`notification-${notification._id}`}
                  onClose={onClose}
                  notification={notification}
                />
              ))}
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function NotificationRow({
  notification,
  onClose,
}: {
  notification: GetAllUserNotificationsQuery["getAllUserNotifications"][0];
  onClose: Props["onClose"];
}) {
  const { projectUsers } = useCurrentProject();
  const [updateNotification] = useUpdateNotificationMutation();
  const navigate = useNavigate();
  const message = useMemo(() => {
    const users = getUserIds(notification.message);
    let remainingMessage = notification.message;
    const messageParts = users.map((userId, index) => {
      const firstOccurrence = remainingMessage.indexOf(`_user:${userId}`);
      remainingMessage = remainingMessage.replace(`_user:${userId}`, "");
      return (
        <span key={`part-${index}`}>
          {firstOccurrence > 0 && (
            <span>{remainingMessage.substring(0, firstOccurrence)}</span>
          )}
          <b>{projectUsers[userId].name}</b>
        </span>
      );
    });
    return (
      <div>
        <div>{getNotificationHeader(notification, projectUsers)}</div>
        {messageParts}
        <span>{remainingMessage}</span>
      </div>
    );
  }, [notification, projectUsers]);
  return (
    <button
      className={twMerge(
        "bg-background w-full p-4 min-h-12 text-start border-b border-gray flex items-center",
        !notification.isRead && "bg-gray-dim",
      )}
      disabled={notification.url == null}
      onClick={() => {
        if (!notification.url) return;
        navigate(notification.url);
        updateNotification({ input: { id: notification._id, isRead: true } });
        onClose();
      }}
    >
      <div className="relative mr-2">
        <Assignee
          name={projectUsers[notification.fromUser._id].name}
          className="size-8 text-base bg-gray text-ink"
        />
        {!notification.isRead && (
          <div className="size-3 absolute -top-0.5 -left-0.5 bg-primary rounded-full border border-white" />
        )}
      </div>
      {message}
    </button>
  );
}
