import { useAppSelector } from "@redux/hooks";

import {
  getTaskDailyProgressForTimeframe,
  getTaskPlannedProgressForTimeframe,
  getTotal,
} from "../functions";

import { useCurrentProject } from "hooks/useCurrentProject";
import { groupBy, isNumber } from "lodash";
import { DailyProgress, Task } from "modules/api/generated-api";
import { useStandupTimeframe } from "modules/standup/hooks/useStandupTimeframe";
import { useCallback } from "react";

type ReturnedTasks = {
  [id: string]: (Task & {
    dailyProgress: DailyProgress[];
    totalProgress: number;
  })[];
};

export function useDailyTasks({
  filterNonDone = false,
  range,
  timeframe,
}: { filterNonDone?: boolean; range?: number; timeframe?: number } = {}) {
  const { taskColProject } = useAppSelector((state) => state.projectsCache);
  const { project, tasks } = useCurrentProject();
  const { projectTf } = useStandupTimeframe();
  const dailyProgressByUser: ReturnedTasks = {};
  const dailyProgressNotDoneByUser: ReturnedTasks = {};
  const plannedProgressByUser: ReturnedTasks = {};
  const reportedStatsByUser: {
    [id: string]: { timePlanned: number; timeSpent: number };
  } = {};
  const createTaskFilter = useCallback(
    (filterFn: Function) => {
      if (project && project.currentSprint) {
        const groupedDailyReportsWithTasks = groupBy(
          project.currentSprint.dailyProgress,
          (dailyProgress) => dailyProgress.task?._id,
        );
        const dailyReportsWithTasks = Object.keys(
          groupedDailyReportsWithTasks,
        ).map((taskId: string) => {
          let task;
          if (
            taskId &&
            !(taskId == "no-task" || taskId === "undefined") &&
            taskColProject[taskId]
          ) {
            const { column } = taskColProject[taskId];
            task = project.columns
              .find(({ _id }) => _id === column)!
              .tasks.find(({ _id }) => _id === taskId) as Task | undefined;
          }
          return {
            ...(task ? task : { _id: "no-task" }),
            dailyProgress: Object.values(groupedDailyReportsWithTasks[taskId]),
          };
        }) as ReturnedTasks["0"];

        return dailyReportsWithTasks
          .map((task) => ({
            ...task,
            dailyProgress: filterFn(task, {
              range,
              timeframe: isNumber(timeframe) ? timeframe : timeframe,
            }) as DailyProgress[],
          }))
          .filter((task) => task.dailyProgress.length > 0);
      } else {
        return [];
      }
    },
    [project, taskColProject, range, timeframe, projectTf],
  );
  const reportedTasks = createTaskFilter(getTaskDailyProgressForTimeframe);
  const plannedTasks = createTaskFilter(getTaskPlannedProgressForTimeframe);

  const addTask = useCallback(
    ({
      _filterDone,
      destination,
      reportToFill,
      source,
      userId,
    }: {
      _filterDone?: boolean;
      destination: ReturnedTasks;
      reportToFill?: keyof (typeof reportedStatsByUser)[0];
      source: typeof plannedTasks;
      userId: string;
    }) => {
      // TODO: check if on planned tasks there is some issue, maybe refiltering will resolve it
      // const taskFilter = (
      //   dailyProgress: (typeof plannedTasks)[0]["dailyProgress"][0],
      // ) => dailyProgress.user._id === userId;
      // const addedTasks = plannedTasks
      //   .filter((task) => task.dailyProgress.filter(taskFilter).length > 0)

      const addedTasks = source
        .map((task) => {
          const dailyProgress = task.dailyProgress
            .filter(
              (dailyProgress) =>
                dailyProgress.user._id === userId &&
                (_filterDone !== undefined
                  ? _filterDone === dailyProgress.isDone
                  : true),
            )
            .sort(
              (dailyProgress1, dailyProgress2) =>
                dailyProgress2.timeSpent - dailyProgress1.timeSpent,
            );
          const totalProgress = getTotal(dailyProgress);
          if (!reportedStatsByUser[userId])
            reportedStatsByUser[userId] = { timePlanned: 0, timeSpent: 0 };
          if (reportToFill) {
            reportedStatsByUser[userId][reportToFill] += totalProgress;
          }
          return {
            ...task,
            dailyProgress,
            totalProgress,
          };
        })
        .filter((task) => task.dailyProgress.length > 0);
      if (!destination[userId]) destination[userId] = [];
      if (addedTasks.length !== 0) {
        destination[userId] = addedTasks.sort(
          (task1, task2) => task2.totalProgress - task1.totalProgress,
        );
      }
    },
    [reportedTasks, plannedTasks],
  );

  project &&
    Object.keys(project.userTimeframes).forEach((userId) => {
      addTask({
        ...(filterNonDone ? { _filterDone: filterNonDone } : {}),
        destination: dailyProgressByUser,
        reportToFill: "timeSpent",
        source: reportedTasks,
        userId,
      });
      addTask({
        _filterDone: false,
        destination: dailyProgressNotDoneByUser,
        source: reportedTasks,
        userId,
      });
      addTask({
        destination: plannedProgressByUser,
        reportToFill: "timePlanned",
        source: plannedTasks,
        userId,
      });
    });

  return {
    dailyProgressByUser,
    dailyProgressNotDoneByUser,
    plannedProgressByUser,
    reportedStatsByUser,
    tasks: reportedTasks,
  };
}
