import { enhancedDataServiceApi } from "../modules/api/functions/enhanced-data-service/enhanced-data-service-api";

import { activitySlice } from "./slices/activity.slice";
import { appSlice } from "./slices/app.slice";
import { authSlice } from "./slices/auth.slice";
import { projectSlice } from "./slices/project.slice";
import { projectCacheSlice } from "./slices/project-cache.slice";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { restApi } from "modules/api/rest-api";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  activity: activitySlice.reducer,
  app: appSlice.reducer,
  auth: authSlice.reducer,
  projects: projectSlice.reducer,
  projectsCache: projectCacheSlice.reducer,
  [enhancedDataServiceApi.reducerPath]: enhancedDataServiceApi.reducer,
  [restApi.reducerPath]: restApi.reducer,
});

type RootReducer = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootReducer>(
  {
    blacklist: [
      "projectsCache",
      enhancedDataServiceApi.reducerPath,
      restApi.reducerPath,
    ],
    key: "root",
    storage,
  },
  rootReducer,
);

export const store = configureStore({
  devTools: process.env.REACT_APP_ENV === "dev",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([enhancedDataServiceApi.middleware, restApi.middleware]),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
