import { useAppSelector } from "@redux/hooks";

import { LOGIN_PATH } from "./paths";

import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoutes = () => {
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  return !isLoggedIn ? <Navigate to={LOGIN_PATH} /> : <Outlet />;
};
