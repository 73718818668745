import { RootState } from "@redux/hooks";

import { FileUpload, UploadFileDocument } from "./generated-api";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type FileUploadInput = {
  file: File;
};

export const restApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/graphql",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    upload: builder.mutation<FileUpload, FileUploadInput>({
      query: ({ file }) => {
        const formData = new FormData();
        const uploadFileMutation = {
          operationName: "UploadFile",
          query: UploadFileDocument,
        };
        formData.append("operations", JSON.stringify(uploadFileMutation));
        formData.append("map", '{"0":["variables.file"]}');
        formData.append("0", file);
        return {
          body: formData,
          headers: {
            "apollo-require-preflight": "true",
          },
          method: "POST",
          url: "",
        };
      },
      transformResponse(baseQueryReturnValue, meta, arg) {
        return (baseQueryReturnValue as any).data.uploadFile;
      },
    }),
  }),
  reducerPath: "restApi",
});

export const { useUploadMutation } = restApi;
