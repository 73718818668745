export interface TimePickerProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  register?: any;
}

export function TimePicker(props: TimePickerProps) {
  const { register, ...otherProps } = props;

  return (
    <input
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
      aria-label="Time"
      type="time"
      {...otherProps}
      {...(register ? register(props.id) : [])}
    />
  );
}
