import { useAppDispatch } from "@redux/hooks";

import { gql, useSubscription } from "@apollo/client";
import { notificationSubscriptionUpdateCaches } from "modules/api/functions/notification-subscription-update-caches";
import {
  SubscribeToNotificationsDocument,
  Subscription,
} from "modules/api/generated-api";
import { wsClient } from "modules/api/ws-api";
import { useEffect } from "react";

export function useSubscribeToNotifications() {
  const dispatch = useAppDispatch();
  const { data } = useSubscription<Subscription>(
    gql(SubscribeToNotificationsDocument),
    {
      client: wsClient,
    },
  );

  useEffect(() => {
    if (!data) return;
    notificationSubscriptionUpdateCaches({
      dispatch,
      subscriptionResponse: data.subscribeToNotifications,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.subscribeToNotifications]);
}
