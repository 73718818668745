import { useAppDispatch } from "@redux/hooks";
import { appSlice } from "@redux/slices/app.slice";

import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme";
import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "components/Button";
import { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

export function Modal({
  children,
  className,
  disableAutoFocus,
  disableClose,
  disableMobileOptimization,
  onClose,
}: {
  children: ReactNode;
  className?: string;
  disableAutoFocus?: boolean;
  disableClose?: boolean;
  disableMobileOptimization?: boolean;
  onClose: Function;
}) {
  const preferredColorScheme = usePrefersColorScheme();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(appSlice.actions.setPopupOpen(true));
    return () => {
      dispatch(appSlice.actions.setPopupOpen(false));
    };
  }, []);
  return (
    <Dialog.Root
      defaultOpen
      onOpenChange={(open) => {
        dispatch(appSlice.actions.setPopupOpen(open));
        if (!open) {
          onClose();
        }
      }}
    >
      {createPortal(
        <>
          <Dialog.Overlay className="z-20 fixed inset-0 bg-black/30"></Dialog.Overlay>
          <Dialog.Content
            onOpenAutoFocus={(event) => {
              if (disableAutoFocus) {
                event.preventDefault();
              }
            }}
            onEscapeKeyDown={(event) => {
              if (disableClose) {
                event.preventDefault();
              }
            }}
            onInteractOutside={(event) => {
              if (disableClose) {
                event.preventDefault();
              }
            }}
            className={twMerge(
              "z-20 fixed inset-0 text-ink flex justify-center items-center !pointer-events-none",
              preferredColorScheme,
            )}
          >
            <div
              className={twMerge(
                "p-4 bg-background pointer-events-auto rounded-xl drop-shadow-xl",
                !disableMobileOptimization &&
                  "h-full w-full md:w-auto md:h-auto rounded-none md:rounded-xl",
                className,
              )}
            >
              {!disableMobileOptimization && (
                <Button
                  text="Back"
                  className="absolute md:hidden"
                  onClick={() => onClose()}
                />
              )}
              {children}
            </div>
          </Dialog.Content>
        </>,
        document.getElementById("dialog-root")!,
      )}
    </Dialog.Root>
  );
}
