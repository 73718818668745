import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class TaskMovedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { taskMoved } = subscriptionResponse;
    if (taskMoved) {
      const oldColumn = draft.project.columns.find(
        ({ _id }) => taskMoved.taskMovedFrom === _id,
      );
      if (!oldColumn) return;
      const taskToMove = oldColumn.tasks.find(
        ({ _id }) => _id === taskMoved.task._id,
      );
      if (!taskToMove) return;
      oldColumn.tasks = oldColumn.tasks.filter(
        ({ _id }) => _id !== taskMoved.task._id,
      );
      const newColumn = draft.project.columns.find(
        ({ _id }) => taskMoved.task.column?._id === _id,
      );
      newColumn?.tasks.splice(taskMoved.position, 0, taskToMove);
    }
  }
  forSubscription: keyof ProjectSubscriptionResponse = "taskMoved";
}
