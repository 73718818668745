import { TaskActivityComment } from "./TaskActivityComment";
import { TaskActivityDailyProgress } from "./TaskActivityDailyProgress";
import { TaskActivityUpload } from "./TaskActivityUpload";
import { TaskActivity } from "./types";

import { Comment, DailyProgress, FileUpload } from "modules/api/generated-api";

export function TaskActivityRenderer({
  taskActivity,
}: {
  taskActivity: TaskActivity;
}) {
  const { reportType } = taskActivity;
  switch (reportType) {
    case "comment":
      return <TaskActivityComment comment={taskActivity as Comment} />;
    case "progress-report":
      return (
        <TaskActivityDailyProgress
          dailyProgress={taskActivity as DailyProgress}
        />
      );
    case "upload":
      return <TaskActivityUpload upload={taskActivity as FileUpload} />;
    default:
      return <div></div>; // Handle unexpected report types gracefully
  }
}
