import { useCurrentProject } from "hooks/useCurrentProject";
import { isNumber } from "lodash";
import { useMeQuery } from "modules/api/generated-api";
import { twMerge } from "tailwind-merge";

export function Timeframe({
  className,
  timeframe,
}: {
  className?: string;
  timeframe: "Last 24" | "Next 24" | number;
}) {
  const { data: userData } = useMeQuery();
  const { project } = useCurrentProject();
  if (
    !userData ||
    (isNumber(timeframe) &&
      timeframe !== project?.userTimeframes[userData.me._id] &&
      timeframe !== project?.userTimeframes[userData.me._id] + 1)
  ) {
    return null;
  }
  const toDisplay: "Last 24" | "Next 24" | "Later" = isNumber(timeframe)
    ? timeframe === project?.userTimeframes[userData.me._id]
      ? "Last 24"
      : timeframe === project?.userTimeframes[userData.me._id] + 1
        ? "Next 24"
        : "Later"
    : timeframe;
  return (
    <div
      className={twMerge(
        "h-6 px-2 rounded-full bg-error text-white text-center flex items-center justify-center text-sm min-w-20",
        toDisplay === "Next 24" && "bg-primary",
        className,
      )}
    >
      {toDisplay}
    </div>
  );
}
