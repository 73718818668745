import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { mapValues, omitBy } from "lodash";
import { GetOneProjectQuery } from "modules/api/generated-api";

export function findTask({
  columnId,
  draft,
  taskId,
}: {
  columnId: string;
  draft: MaybeDrafted<GetOneProjectQuery>;
  taskId: string;
}) {
  const columnIndex = draft.project.columns.findIndex(
    ({ _id }) => columnId === _id,
  );
  const column = draft.project.columns[columnIndex];
  const taskIndex = column?.tasks.findIndex(({ _id }) => taskId === _id);
  const task = draft.project.columns[columnIndex].tasks[taskIndex];
  return { columnIndex, task, taskIndex };
}

export function removeTypenames<T>(obj: object): T {
  if (Array.isArray(obj)) {
    return obj.map(removeTypenames) as any; // Recursively handle arrays
  } else if (obj && typeof obj === "object") {
    return omitBy(
      mapValues(obj, removeTypenames),
      (value, key) => key === "__typename",
    ) as any;
  }
  return obj as T;
}
