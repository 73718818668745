import { useAppSelector } from "@redux/hooks";

import { TaskPlanDisplay } from "./TaskPlanDisplay.component";

import "react-circular-progressbar/dist/styles.css";

import { Button } from "components/Button";
import { PartialProgressMeter } from "components/PartialProgressMeter";
import { TimeSpent } from "components/TimeSpent";
import { format } from "date-fns";
import { useCurrentProject } from "hooks/useCurrentProject";
import { useIncrementProjectTimeframeMutation } from "modules/api/generated-api";
import { useDailyTasks } from "modules/daily-progress/hooks/useDailyTasks";
import { useClock } from "modules/standup/hooks/useClock";
import { useStandupTimeframe } from "modules/standup/hooks/useStandupTimeframe";
import { useStandupTimeframeFunctions } from "modules/standup/hooks/useStandupTimeframeFunctions";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export function Standup() {
  const { currentUser } = useAppSelector((state) => state.auth);
  const [focusedUser, setFocusedUser] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [focusedUserTime, setFocusedUserTime] = useState<Date>(new Date());
  const [incrementProjectTimeframe] = useIncrementProjectTimeframeMutation();
  const [startedSince] = useState(new Date());
  const { project, projectUsers } = useCurrentProject();
  const { projectTf } = useStandupTimeframe();
  const { calculateDateBasedOnTimeframes } = useStandupTimeframeFunctions();
  const [standupTf, setStandupTf] = useState(projectTf);
  const { dailyProgressByUser, plannedProgressByUser, reportedStatsByUser } =
    useDailyTasks({ filterNonDone: true, timeframe: standupTf });
  const userIds = dailyProgressByUser
    ? Object.keys(dailyProgressByUser).filter(
        (u) => u !== "undefined" && dailyProgressByUser[u].length > 0,
      )
    : [];
  const { clock } = useClock({ startedSince });
  const { clock: userClock, percentage } = useClock({
    countdownSeconds: project && project.standupMinsPerUser * 60,
    startedSince: focusedUserTime,
  });

  const allUsersSubmitted = project
    ? Object.values(
        project?.userTimeframes as { [key: string]: number },
      ).filter((timeframe) => project && timeframe <= projectTf).length === 0
    : false;

  const tfSearchParam = searchParams.get("tf");
  useEffect(() => {
    if (tfSearchParam && standupTf !== +tfSearchParam) {
      setSearchParams({ tf: `${standupTf}` });
    }
  }, [setSearchParams, standupTf, tfSearchParam]);
  useEffect(() => {
    if (!tfSearchParam) return;
    setStandupTf(+tfSearchParam);
  }, []);
  const currentDate = useMemo(
    () => calculateDateBasedOnTimeframes({ daysToAdd: standupTf }),
    [calculateDateBasedOnTimeframes, standupTf],
  );

  return (
    <div className="bg-gray flex flex-col h-svh">
      <div className="text-center m-8">
        <div className="text-lg flex gap-2 items-center justify-center">
          <Button
            className="flex-grow-0"
            size="small"
            color="white"
            text="<"
            onClick={() => {
              setStandupTf(standupTf - 1);
            }}
          />
          {format(currentDate, "eeee d MMMM")}
          <Button
            className="flex-grow-0"
            size="small"
            color="white"
            text=">"
            onClick={() => {
              setStandupTf(standupTf + 1);
            }}
          />
        </div>
        <div className="text-xl font-bold">Daily Standup</div>
        <div>{clock}</div>
      </div>
      <div className="px-8 flex flex-row justify-evenly overflow-y-hidden gap-4 overflow-x-scroll">
        {userIds.map((userId, i) => {
          const isColumnSelected = userId === focusedUser;
          return (
            <div
              className="w-[300px] min-w-[200px] relative flex flex-col"
              key={`column-${i}`}
            >
              <div
                className={twMerge(
                  "absolute -inset-4 bg-primary duration-[2000ms] opacity-0 rounded-2xl",
                  isColumnSelected && "opacity-30",
                )}
              ></div>

              <div className="flex flex-row items-center justify-center bg-background text-center font-semibold mb-4 p-3 rounded-xl drop-shadow-lg group/title relative gap-2">
                <div>{projectUsers[userId]?.name}</div>
                {reportedStatsByUser &&
                  !!reportedStatsByUser[userId]?.timeSpent && (
                    <TimeSpent time={reportedStatsByUser[userId]?.timeSpent} />
                  )}
                <div className="absolute -inset-6 group">
                  <div className="top-6 left-6 relative block">
                    <div className="absolute -top-1 left-0 right-12">
                      <div className=" h-10 w-full bottom-0 absolute">
                        {!isColumnSelected && (
                          <Button
                            text="Focus"
                            className="relative duration-300 invisible group-hover:visible opacity-0 group-hover:opacity-100 -top-5 group-hover:top-0"
                            onClick={() => {
                              setFocusedUserTime(new Date());
                              setFocusedUser(userId);
                            }}
                          />
                        )}
                      </div>
                      <div className=" h-10 w-full bottom-10 absolute">
                        {isColumnSelected && (
                          <PartialProgressMeter
                            className={twMerge(
                              "absolute -left-20",
                              percentage <= 0 &&
                                " animate-[scale_1s_ease-in-out_infinite]",
                            )}
                            type="standup"
                            percentage={percentage}
                            text={percentage > 0 ? userClock : "Done"}
                          />
                        )}
                        <Button
                          text="Clear Focus"
                          className={twMerge(
                            "relative duration-300 invisible opacity-0 -top-5",
                            isColumnSelected && "visible opacity-100 top-0",
                          )}
                          onClick={() => {
                            setFocusedUser(undefined);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overflow-y-scroll">
                {dailyProgressByUser &&
                  dailyProgressByUser[userId].length > 0 && (
                    <TaskPlanDisplay
                      theme="last24"
                      title="Last 24 hours"
                      dailyProgress={dailyProgressByUser[userId]}
                      userId={userId}
                      key={`last-24-${i}`}
                      viewOnly
                    />
                  )}

                {plannedProgressByUser &&
                  plannedProgressByUser[userId].length > 0 && (
                    <TaskPlanDisplay
                      theme="next24"
                      title="Next 24 hours"
                      dailyProgress={plannedProgressByUser[userId]}
                      userId={userId}
                      key={`next-24-${i}`}
                      viewOnly
                    />
                  )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
