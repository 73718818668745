import type { ProjectsCacheData } from "@redux/slices/project-cache.slice";

import {
  GetAllUserNotificationsQuery,
  NotificationTypeEnum,
} from "modules/api/generated-api";

export function getUserIds(text: string) {
  const userIdPattern = /_user:([a-f0-9]{24})/g;
  let match;
  const userIds = new Array<string>();
  while ((match = userIdPattern.exec(text)) !== null) {
    userIds.push(match[1]);
  }
  return userIds;
}

export function getNotificationHeader(
  notification: GetAllUserNotificationsQuery["getAllUserNotifications"][0],
  projectUsers: ProjectsCacheData["users"],
) {
  const type =
    notification.type === NotificationTypeEnum.Comment
      ? "comment"
      : NotificationTypeEnum.ProgressReport
        ? "progress report"
        : "";
  return (
    <>
      <span className="italic text-sm">
        <b>{projectUsers[notification.fromUser._id].name}</b>
        <span>{" mentioned you in a "}</span>
        <b>{type}</b>
      </span>
    </>
  );
}
