import { findTask } from "../helpers";
import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  GetOneTaskQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class CommentRemovedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { commentRemoved } = subscriptionResponse;
    if (commentRemoved) {
      const { task } = findTask({
        columnId: commentRemoved.task.column!._id,
        draft,
        taskId: commentRemoved.task._id,
      });
      if (task.comments) {
        task.comments = task.comments.filter(
          ({ _id }) => _id !== commentRemoved._id,
        );
      }
    }
  }
  handleTaskUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneTaskQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }): void {
    const { commentRemoved } = subscriptionResponse;
    if (commentRemoved) {
      if (draft.task.comments) {
        draft.task.comments = draft.task.comments.filter(
          ({ _id }) => _id !== commentRemoved._id,
        );
      }
    }
  }
  getTaskId(subscriptionResponse: ProjectSubscriptionResponse): string {
    return subscriptionResponse.commentRemoved!.task._id;
  }
  forSubscription: keyof ProjectSubscriptionResponse = "commentRemoved";
}
