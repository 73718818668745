import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class TaskCreatedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { taskCreated } = subscriptionResponse;
    if (taskCreated) {
      draft.project.columns
        .find(({ _id }) => taskCreated!.column?._id === _id)
        ?.tasks.push(taskCreated);
    }
  }
  forSubscription: keyof ProjectSubscriptionResponse = "taskCreated";
}
