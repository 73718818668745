import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: AppData = {
  flags: { characterAtUsageCount: 0 },
  isPopupOpen: false,
};

export const appSlice = createSlice({
  initialState,
  name: "app",
  reducers: {
    setFlag(state, action: PayloadAction<Partial<AppData["flags"]>>) {
      state.flags = { ...state.flags, ...action.payload };
    },
    setPopupOpen(state, action: PayloadAction<boolean>) {
      state.isPopupOpen = action.payload;
    },
  },
});

interface AppData {
  flags: {
    characterAtUsageCount: number;
    deviceId?: string;
    lastAskedEnableNotifications?: Date;
    pushToken?: string;
    pushTokenUpdated?: boolean;
  };
  isPopupOpen: boolean;
}
