import { useAppDispatch } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { ProgressAddition } from "./ProgressAddition.component";
import { StandupTask } from "./StandupTask.component";

import { Button } from "components/Button";
import { PartialProgressMeter } from "components/PartialProgressMeter";
import type { useDailyTasks } from "modules/daily-progress/hooks/useDailyTasks";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export type Props = {
  dailyProgress: ReturnType<typeof useDailyTasks>["dailyProgressByUser"][0];
  enableAddDailyProgress?: boolean;
  enableConfirmTaskDone?: boolean;
  enableHoverSelect?: boolean;
  onAddStateChange?: (adding: boolean) => void;
  onEditStateChange?: (editing: boolean) => void;
  progressPercentage?: number;
  theme: "last24" | "next24" | "sprint";
  title: string;
  userId: string;
  viewOnly?: boolean;
};

export function TaskPlanDisplay({
  enableConfirmTaskDone = false,
  enableHoverSelect = true,
  enableAddDailyProgress,
  onAddStateChange,
  onEditStateChange,
  progressPercentage,
  dailyProgress,
  theme,
  title,
  userId,
  viewOnly,
}: Props) {
  const dispatch = useAppDispatch();
  const handleMouseEnter = (taskId: string) => {
    if (!enableHoverSelect) return;
    dispatch(projectCacheSlice.actions.setUserSelectedTask(taskId));
  };
  const handleMouseLeave = () => {
    if (!enableHoverSelect) return;
    dispatch(projectCacheSlice.actions.setUserSelectedTask(undefined));
  };
  const [addingProgress, setAddingProgress] = useState(false);
  useEffect(() => {
    if (onAddStateChange) {
      onAddStateChange(addingProgress);
    }
  }, [addingProgress, onAddStateChange]);

  return (
    <div>
      <div className="relative text-center mb-2 gap-2 flex flex-row items-center justify-center">
        <span className=" font-semibold">{title}</span>
        {progressPercentage != null && (
          <PartialProgressMeter
            pathClassName={twMerge(
              (theme === "last24" || theme === "sprint") && "stroke-error",
              theme === "next24" && "stroke-primary",
            )}
            percentage={progressPercentage}
          />
        )}
      </div>
      {dailyProgress.map((task) => (
        <StandupTask
          key={`task_${task._id}`}
          addingProgress={addingProgress}
          task={task}
          enableConfirmTaskDone={enableConfirmTaskDone}
          userId={userId}
          viewOnly={viewOnly}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          theme={theme}
          onEditStateChange={onEditStateChange}
        />
      ))}
      {dailyProgress.length > 0 && <div className="pb-4" />}
      {enableAddDailyProgress && (
        <>
          {!addingProgress && (
            <div className="flex flex-col mb-4 items-center justify-center gap-1">
              {dailyProgress.length === 0 && (
                <div className="text-sm">Add your first daily progress</div>
              )}
              <Button
                color="secondary"
                className=" self-center text-center flex-grow-0 px-2 py-1 leading-none"
                text="+"
                onClick={() => {
                  setAddingProgress(true);
                }}
              />
            </div>
          )}

          {addingProgress && (
            <ProgressAddition
              theme={theme}
              setAddingProgress={setAddingProgress}
            />
          )}
        </>
      )}
    </div>
  );
}
