import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { ColumnCreatedHandler } from "./handlers/column-created-handler";
import { ColumnRemovedHandler } from "./handlers/column-removed-handler";
import { CommentCreatedHandler } from "./handlers/comment-created-handler";
import { CommentRemovedHandler } from "./handlers/comment-removed-handler";
import { DailyProgressCreatedHandler } from "./handlers/daily-progress-created-handler";
import { DailyProgressRemovedHandler } from "./handlers/daily-progress-removed-handler";
import { DailyProgressUpdatedHandler } from "./handlers/daily-progress-updated-handler";
import { ProjectColumnsUpdatedHandler } from "./handlers/project-columns-updated-handler";
import { ProjectUpdatedHandler } from "./handlers/project-updated-handler";
import { TaskCreatedHandler } from "./handlers/task-created-handler";
import { TaskMovedHandler } from "./handlers/task-moved-handler";
import { TaskUpdatedHandler } from "./handlers/task-updated-handler";
import { ProjectUpdateHandler } from "./project-update-handler.interface";

import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { api, ProjectSubscriptionResponse } from "modules/api/generated-api";

const updateHandlers: ProjectUpdateHandler[] = [
  new ColumnCreatedHandler(),
  new ColumnRemovedHandler(),
  new CommentCreatedHandler(),
  new CommentRemovedHandler(),
  new ProjectColumnsUpdatedHandler(),
  new ProjectUpdatedHandler(),
  new DailyProgressCreatedHandler(),
  new DailyProgressRemovedHandler(),
  new DailyProgressUpdatedHandler(),
  new TaskCreatedHandler(),
  new TaskMovedHandler(),
  new TaskUpdatedHandler(),
];

export function projectSubscriptionUpdateCaches({
  currentUser,
  dispatch,
  projectId,
  subscriptionResponse,
}: {
  currentUser: string;
  dispatch: ThunkDispatch<any, any, UnknownAction>;
  projectId: string;
  subscriptionResponse: ProjectSubscriptionResponse;
}) {
  const patchesCalled: PatchCollection[] = [];
  updateHandlers.forEach((handler) => {
    if (subscriptionResponse[handler.forSubscription]) {
      if (handler.handleProjectUpdate) {
        patchesCalled.push(
          dispatch(
            api.util.updateQueryData("GetOneProject", { projectId }, (draft) =>
              handler.handleProjectUpdate!({ draft, subscriptionResponse }),
            ),
          ),
        );
      }
      if (handler.handleTaskUpdate) {
        const taskId = handler.getTaskId!(subscriptionResponse);
        if (taskId) {
          patchesCalled.push(
            dispatch(
              api.util.updateQueryData("GetOneTask", { id: taskId }, (draft) =>
                handler.handleTaskUpdate!({ draft, subscriptionResponse }),
              ),
            ),
          );
        }
      }
    }
  });

  const { userAction, userId } = subscriptionResponse;
  if (userAction) {
    if (currentUser !== userId) {
      dispatch(
        projectCacheSlice.actions.setUserAction({ userAction, userId: userId }),
      );
    }
  }
  return patchesCalled;
}
