import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useInView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";

export function PartialProgressMeter({
  type = "time",
  percentage,
  text,
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
  percentage: number;
  text?: string;
  type?: "time" | "standup";
}) {
  const { ref, inView } = useInView();
  const _percentage = inView ? percentage * 100 : 0;
  return (
    // TODO: use tailwind varients
    <div
      ref={ref}
      className={twMerge(
        "inline-flex",
        type === "time" ? "size-6" : "size-20",
        className,
      )}
    >
      {type === "time" && (
        <CircularProgressbar
          value={_percentage}
          strokeWidth={50}
          className=""
          classes={{
            background: "",
            path: twMerge("stroke-primary", pathClassName),
            root: "",
            text: "",
            trail: "stroke-gray",
          }}
          styles={buildStyles({
            pathTransitionDuration: 1.5,
            strokeLinecap: "butt",
          })}
        />
      )}
      {type === "standup" && (
        <CircularProgressbar
          value={_percentage}
          text={`${text || _percentage}`}
          strokeWidth={15}
          className="fill-primary"
          classes={{
            background: "fill-primary bg-primary",
            path: twMerge("stroke-primary", pathClassName),
            root: "bg-gray rounded-full",
            text: "text-center text-ink fill-ink",
            trail: "stroke-background",
          }}
          styles={{
            ...buildStyles({
              pathTransitionDuration: 1.5,
            }),
            text: {
              alignmentBaseline: "middle",
              textAnchor: "middle",
            },
          }}
        />
      )}
    </div>
  );
}
