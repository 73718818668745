import { TimePicker, TimePickerProps } from "./TimePicker.component";

import { FieldError } from "react-hook-form";

interface FormInputProps extends TimePickerProps {
  error?: FieldError;
  id: string;
  label?: string;
  register: any;
  type?: string;
}

export function FormTimePicker(props: FormInputProps) {
  const { id, className, label, register, error, ...otherProps } = props;

  return (
    <div className={className}>
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-ink">
        {label}
      </label>
      <TimePicker {...otherProps} id={id} register={register} />
      {error?.message && (
        <p className="mt-1 text-sm text-red-600">{error.message}</p>
      )}
    </div>
  );
}
