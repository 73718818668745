import { IconButton } from "components/Button";
import { Timeframe } from "components/Timeframe";
import { TimeSpent } from "components/TimeSpent";
import { formatDistanceToNow } from "date-fns";
import {
  DailyProgress,
  useMeQuery,
  useRemoveDailyProgressMutation,
} from "modules/api/generated-api";
import { useSlateConverter } from "modules/slate/hooks/useSlateConverter";
import { AiOutlineDelete } from "react-icons/ai";
import { twMerge } from "tailwind-merge";

export function TaskActivityDailyProgress({
  dailyProgress,
}: {
  dailyProgress: DailyProgress;
}) {
  const { data: userData } = useMeQuery();
  const { toHtml } = useSlateConverter();
  const serialized = toHtml(dailyProgress.content);
  const [removeDailyProgress] = useRemoveDailyProgressMutation();
  const currentUser = userData?.me;
  if (!currentUser) return <div>Loading...</div>;

  return (
    <>
      <div className="bg-background rounded-xl p-2 px-4 flex-1">
        <div className="flex self-start font-semibold items-center gap-1 mb-2">
          <Timeframe
            className={twMerge(
              "text-[12px] h-5",
              currentUser._id !== dailyProgress.user._id && "opacity-60 hidden",
            )}
            timeframe={dailyProgress.timeframe}
          />
          <TimeSpent
            className={twMerge(
              "h-5 text-[12px]",
              !dailyProgress.isDone && "bg-primary",
              currentUser._id !== dailyProgress.user._id && "opacity-60",
            )}
            time={dailyProgress.timeSpent}
          />
          {dailyProgress.user.name}
        </div>
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: serialized,
            }}
          ></div>
          <div className=" text-gray float-right">
            {formatDistanceToNow(dailyProgress.ts, {
              addSuffix: true,
            })}
          </div>
        </div>
      </div>
      <div className=" hidden group-hover:inline-flex text-white mx-2 gap-1">
        <IconButton
          color="error"
          onClick={() => {
            removeDailyProgress({
              id: dailyProgress._id,
            });
          }}
          icon={<AiOutlineDelete />}
        />
      </div>
    </>
  );
}
