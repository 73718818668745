import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { FormTextInput } from "components/TextInput/FormTextInput";
import useAuth from "hooks/useAuth";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_PATH, ROOT_PATH } from "routes/paths";
import * as yup from "yup";

interface SignupFormInputs {
  confirmPassword: string;
  email: string;
  name: string;
  password: string;
}

const schema = yup.object().shape({
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password")], "Passwords do not match"),
  email: yup.string().email().required(),
  name: yup.string().required(),
  password: yup.string().min(6).required(),
});

export const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFormInputs>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { signup } = useAuth();
  const onSubmit = async ({ name, email, password }: SignupFormInputs) => {
    await signup({
      email,
      name,
      password,
    });
    navigate(ROOT_PATH);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-dim text-ink">
      <div className="w-full max-w-md p-8 space-y-6 bg-background rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center">Sign up</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <FormTextInput
            id="name"
            label="Name"
            type="name"
            error={errors.name}
            register={register}
            className="mb-4"
          />
          <FormTextInput
            id="email"
            label="Email"
            type="email"
            error={errors.email}
            register={register}
            className="mb-4"
          />
          <FormTextInput
            id="password"
            label="Password"
            type="password"
            error={errors.password}
            register={register}
            className="mb-4"
          />
          <FormTextInput
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            error={errors.confirmPassword}
            register={register}
            className="mb-8"
          />
          <Button type="submit" text="Sign up" />
          <p className="mt-4 text-sm text-center text-ink">
            Already have an account?{" "}
            <Link
              to={LOGIN_PATH}
              className="text-primary hover:text-primary-hover"
            >
              Login
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};
