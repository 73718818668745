import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme";
import { NavbarProvider } from "modules/navigation/hooks/useNavbar";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "routes";
import { twMerge } from "tailwind-merge";

function App() {
  const preferredColorScheme = usePrefersColorScheme();
  return (
    <NavbarProvider>
      <div
        className={twMerge(
          "flex flex-col h-full w-full text-ink",
          preferredColorScheme,
        )}
      >
        <RouterProvider router={router} />
      </div>
    </NavbarProvider>
  );
}

export default App;
