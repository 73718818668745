import { NotificationPopover } from "./NotificationPopover.component";

import { IconButton } from "components/Button";
import { useGetAllUserNotificationsQuery } from "modules/api/generated-api";
import { useMemo, useState } from "react";
import { MdNotifications } from "react-icons/md";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  position?: "left" | "right";
};

export function NotificationBell({ position = "left", className }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useGetAllUserNotificationsQuery();
  const numberOfNotifications = useMemo(() => {
    return (
      data?.getAllUserNotifications.filter(
        (notification) => !notification.isRead,
      ).length || 0
    );
  }, [data?.getAllUserNotifications]);

  return (
    <div className={twMerge("relative", className)}>
      <IconButton
        icon={<MdNotifications className="size-6" />}
        className="rounded-full bg-primary"
        onClick={() => {
          setIsOpen((value) => !value);
        }}
      />
      {numberOfNotifications > 0 && (
        <div
          className={twMerge(
            "absolute -bottom-1  rounded-full p-1 bg-error text-white size-5 flex items-center justify-center text-sm pointer-events-none",
            position === "left" ? "-left-1" : "-right-1",
          )}
        >
          {numberOfNotifications}
        </div>
      )}
      <NotificationPopover
        isOpen={isOpen}
        isAllRead={numberOfNotifications === 0}
        onClose={() => {
          setIsOpen(false);
        }}
        className={className}
      />
    </div>
  );
}
