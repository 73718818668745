import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProjectSubscriptionResponse,
  Task,
  User,
} from "modules/api/generated-api";

export const initialState: ProjectsCacheData = {
  selectedTasks: {},
  selectedTasksUser: {},
  taskColProject: {},
  tasksArray: [],
  userSubmittingProgress: false,
  users: {},
  vars: {},
};

export const projectCacheSlice = createSlice({
  initialState,
  name: "project-cache",
  reducers: {
    cleanupActions(state) {
      Object.entries(state.selectedTasks).forEach(([key, { ts, uid }]) => {
        if (+ts + 10000 < Date.now()) {
          delete state.selectedTasksUser[uid];
          delete state.selectedTasks[key];
        }
      });
    },
    setTaskColumnProject(
      state,
      action: PayloadAction<ProjectsCacheData["taskColProject"]>,
    ) {
      state.taskColProject = Object.assign(
        state.taskColProject,
        action.payload,
      );
    },
    setTasksArray(
      state,
      action: PayloadAction<ProjectsCacheData["tasksArray"]>,
    ) {
      state.tasksArray = action.payload;
    },
    setUserAction(state, action: PayloadAction<ProjectSubscriptionResponse>) {
      const userAlreadySelectedTaskId =
        state.selectedTasksUser[action.payload.userId!];
      if (userAlreadySelectedTaskId) {
        delete state.selectedTasks[userAlreadySelectedTaskId];
      }
      state.selectedTasksUser = {
        [action.payload.userId!]: action.payload.userAction?.selectedTask!,
      };
      state.selectedTasks = {
        ...state.selectedTasks,
        [action.payload.userAction?.selectedTask!]: {
          ts: new Date(),
          uid: action.payload.userId!,
        },
      };
    },
    setUserSelectedTask(state, action: PayloadAction<string | undefined>) {
      state.userSelectedTask = action.payload;
    },
    setUserSubmittingProgress(state, action: PayloadAction<boolean>) {
      state.userSubmittingProgress = action.payload;
    },
    setUsers(state, action: PayloadAction<ProjectsCacheData["users"]>) {
      state.users = action.payload;
    },
    setVar(state, action: PayloadAction<Partial<ProjectsCacheData["vars"]>>) {
      state.vars = { ...state.vars, ...action.payload };
    },
  },
});

export interface ProjectsCacheData {
  selectedTasks: {
    [taskId: string]: { ts: Date; uid: string };
  };
  selectedTasksUser: {
    [uid: string]: string;
  };
  taskColProject: {
    [taskId: string]: { column: string; project: string };
  };
  tasksArray: Task[];
  userSelectedTask?: string;
  userSubmittingProgress: boolean;
  users: {
    [userId: string]: User;
  };
  vars: {
    addingLabel?: string;
    filterCardsByLabel?: string[];
    filterCardsByUser?: string;
    scrollPositionXKanban?: number;
  };
}
