import { findTask } from "../helpers";
import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  GetOneTaskQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class CommentCreatedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { commentCreated } = subscriptionResponse;
    if (commentCreated) {
      const { task } = findTask({
        columnId: commentCreated.task.column!._id,
        draft,
        taskId: commentCreated.task._id,
      });
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { task: discardedTask, ...reduxComment } = commentCreated;
      task.comments?.push(reduxComment);
    }
  }
  handleTaskUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneTaskQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }): void {
    const { commentCreated } = subscriptionResponse;
    if (commentCreated) {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { task: discardedTask, ...reduxComment } = commentCreated;
      draft.task.comments?.push(reduxComment);
    }
  }
  getTaskId(subscriptionResponse: ProjectSubscriptionResponse): string {
    return subscriptionResponse.commentCreated!.task._id;
  }
  forSubscription: keyof ProjectSubscriptionResponse = "commentCreated";
}
