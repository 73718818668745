import { IconButton } from "components/Button";
import { formatDistanceToNow } from "date-fns";
import { Comment, useRemoveCommentMutation } from "modules/api/generated-api";
import { useSlateConverter } from "modules/slate/hooks/useSlateConverter";
import { AiOutlineDelete } from "react-icons/ai";

export function TaskActivityComment({ comment }: { comment: Comment }) {
  const [removeComment] = useRemoveCommentMutation();
  const { toHtml } = useSlateConverter();
  const serialized = toHtml(comment.content);

  return (
    <>
      <div className="bg-background rounded-xl p-2 px-4 flex-1">
        <div className="self-start font-semibold mb-2">{comment.user.name}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: serialized,
          }}
        ></div>
        <div className=" text-gray float-right">
          {formatDistanceToNow(comment.ts, {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className=" hidden group-hover:inline-flex text-white mx-2 gap-1">
        <IconButton
          color="error"
          onClick={() => {
            removeComment({
              id: comment._id,
            });
          }}
          icon={<AiOutlineDelete />}
        />
      </div>
    </>
  );
}
