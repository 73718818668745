import { appSlice } from "@redux/slices/app.slice";
import { store } from "@redux/store";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};

const supportsServiceWorker = "serviceWorker" in navigator;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = supportsServiceWorker
  ? getMessaging(app)
  : ({} as any);

export async function initPushNotifications({
  onClick,
}: {
  onClick: (notification: Notification) => void;
}) {
  const initListeners = async () => {
    try {
      const pushToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });

      onMessage(messaging, (payload) => {
        if (Notification.permission === "granted") {
          if (payload.notification?.title) {
            const notification = new Notification(payload.notification.title, {
              body: payload.notification?.body,
              data: payload.data,
            });
            notification.onclick = () => {
              onClick(notification);
            };
          }
        }
      });

      store.dispatch(appSlice.actions.setFlag({ pushToken }));
    } catch (e) {
      console.log(e);
    }
  };
  if (Notification.permission !== "granted") return;
  const reg = await navigator.serviceWorker.register(
    "/firebase-messaging-sw.js",
  );
  // Added for clean init on first load
  if (reg.installing) {
    const sw = reg.installing || reg.waiting;
    if (sw) {
      sw.onstatechange = async () => {
        if (sw.state === "activated") {
          await initListeners();
          setTimeout(() => {
            initListeners();
          }, 100);
        }
      };
    }
    return;
  }
  await initListeners();
}

export { getToken, onMessage };
