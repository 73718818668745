import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

export function Assignee({
  name,
  className,
}: {
  className?: string;
  name?: string;
}) {
  const displayedName = useMemo(
    () =>
      (name ?? "")
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase(),
    [name],
  );
  return (
    <div
      className={twMerge(
        "flex h-5 w-5 rounded-full bg-primary text-white font-semibold text-xs text-center items-center justify-center shadow-lg",
        className,
      )}
    >
      {displayedName}
    </div>
  );
}
