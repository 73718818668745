import React from "react";
import ReactSelect, { GroupBase } from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";

export function Select<T, IsMulti extends boolean, Group extends GroupBase<T>>({
  ...props
}: CreatableProps<T, IsMulti, Group>) {
  if (props.onCreateOption) {
    return (
      <CreatableSelect
        {...props}
        // to solve create new
        {...(props.getOptionLabel
          ? {
              getOptionLabel: (option: T) =>
                props.getOptionLabel?.(option) || (option as any).label,
            }
          : {})}
      ></CreatableSelect>
    );
  }
  return <ReactSelect {...props}></ReactSelect>;
}
