import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { FormTextInput } from "components/TextInput/FormTextInput";
import { FormTimePicker } from "components/TimePicker/FormTimePicker.component";
import { setHours, setMinutes, startOfToday } from "date-fns";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  CreateProjectMutation,
  useCreateProjectMutation,
} from "modules/api/generated-api";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface CreateProjectFormInputs {
  code: string;
  name: string;
  time: string;
}

export function CreateProjectForm({
  onCreated,
}: {
  onCreated: (createdProject: CreateProjectMutation) => void;
}) {
  const [createProject] = useCreateProjectMutation();

  const schema = yup
    .object({
      code: yup.string().required(),
      name: yup.string().required(),
      time: yup.string().required(),
    })
    .required();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateProjectFormInputs>({
    defaultValues: {
      time: "09:00",
    },
    resolver: yupResolver(schema),
  });

  const name = watch("name");

  const onSubmit = async ({ code, name, time }: CreateProjectFormInputs) => {
    const [hours, minutes] = time.split(":");
    const response = await apiToastPromise(
      createProject({
        input: {
          code: code!,
          name: name!,
          standupStart: setMinutes(
            setHours(startOfToday(), Number(hours)),
            Number(minutes),
          ),
        },
      }),
      {
        error: "Error creating project",
        loading: "Creating project...",
        success: `Created project "${name}"`,
      },
    );
    if (!response?.error) {
      onCreated(response?.data!);
    }
  };

  const setCode = useCallback(
    (code: string) => {
      setValue("code", code.replaceAll(" ", "").substring(0, 3).toUpperCase());
    },
    [setValue],
  );

  useEffect(() => {
    if (!name) return;
    setCode(name);
  }, [name, setCode]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
      <FormTextInput
        id="name"
        label="Project Name"
        autoFocus
        placeholder="Project name"
        error={errors.name}
        register={register}
      />
      <FormTextInput
        id="code"
        label="Project code"
        placeholder="Project name"
        error={errors.code}
        register={register}
      />

      <FormTimePicker
        id="time"
        label="Daily Standup Time"
        error={errors.time}
        register={register}
      />

      <Button text="Create" type="submit" className="mt-8" />
    </form>
  );
}
