import { useAppSelector } from "@redux/hooks";

import { DNDSprintControl } from "./2-Table";

import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { Assignee } from "components/Assignee";
import { useCurrentProject } from "hooks/useCurrentProject";
import { SprintSuggestionTask } from "modules/api/generated-api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function Cell({
  control,
  task,
  index,
}: {
  control: React.MutableRefObject<DNDSprintControl>;
  index: number;
  task: SprintSuggestionTask;
}) {
  const navigate = useNavigate();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { projectUsers, project, projectLabels } = useCurrentProject();
  const { selectedTasks, userSelectedTask } = useAppSelector(
    (state) => state.projectsCache,
  );
  const [showUp, setShowUp] = useState(0);
  useEffect(() => {
    if (control.current.stopped) {
      setShowUp(2);
      return;
    }
    control.current.delayIndex++;
    setTimeout(() => {
      setShowUp(1);
    }, control.current.delayIndex * 200);
    setTimeout(
      () => {
        setShowUp(2);
      },
      control.current.delayIndex * 200 + 1000,
    );
  }, []);

  return (
    <Draggable key={task.id} draggableId={task.id} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Droppable droppableId={task.id} type="on-task">
          {(
            droppableProvided: DroppableProvided,
            droppableSnapshot: DroppableStateSnapshot,
          ) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={twMerge(
                "hidden px-4 py-2 mb-2 select-none rounded-xl relative group transition",
                snapshot.isDragging
                  ? " bg-background-selected"
                  : "bg-background",
                droppableSnapshot.isDraggingOver ? "bg-blue-400" : "",
                showUp > 0 && "block",
                showUp < 2 && "animate-[showUp_0.5s_ease-in-out]",
              )}
              onClick={() => {
                // CHECK IF NEEDED
                // navigate(
                //   TASK_PATH.replace(":projectId", project?._id!).replace(
                //     ":taskId",
                //     task.id,
                //   ),
                // );
              }}
              onContextMenu={(e) => e.preventDefault()}
            >
              <div
                className={twMerge(
                  "hidden border rounded-xl absolute inset-0",
                  userSelectedTask === task.id && "flex",
                )}
              ></div>
              <div
                className={twMerge(
                  "flex bg-transparent rounded-xl absolute inset-0 duration-500 opacity-0",
                  selectedTasks[task.id] &&
                    selectedTasks[task.id]?.uid !== currentUser! &&
                    "bg-black/30 dark:bg-white/30 opacity-100",
                )}
              >
                {selectedTasks[task.id] &&
                  projectUsers[selectedTasks[task.id].uid] && (
                    <Assignee
                      name={projectUsers[selectedTasks[task.id].uid].name}
                      className="-left-2 self-center absolute"
                    />
                  )}
              </div>
              {/* TODO: check if needed */}
              {/* <div className="flex gap-1">
                <div className=" text-xs flex-1 text-ink-dim">
                  {project?.code}-{task.counterId}
                </div>
              </div> */}
              <div className="text-sm font-semibold">{task.title}</div>
              <div className="text-xs">{task.description}</div>
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer"
              />
            </div>
          )}
        </Droppable>
      )}
    </Draggable>
  );
}
