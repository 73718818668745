import { useAppSelector } from "@redux/hooks";

import { ProgressInputPopover } from "./ProgressInputPopover.component";
import { Props } from "./TaskPlanDisplay.component";

import { PartialProgressMeter } from "components/PartialProgressMeter";
import { SmartTextInput } from "components/SmartTextInput";
import { TimeSpent } from "components/TimeSpent";
import { useCurrentProject } from "hooks/useCurrentProject";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  Task,
  useCreateDailyProgressMutation,
} from "modules/api/generated-api";
import { hoursInDay } from "modules/sprint-plan/constants";
import { useCallback, useState } from "react";
import Select from "react-select";
import { Descendant } from "slate";
import { twMerge } from "tailwind-merge";

export function ProgressAddition({
  theme,
  setAddingProgress,
}: {
  setAddingProgress: React.Dispatch<React.SetStateAction<boolean>>;
  theme: Props["theme"];
}) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { project, tasks } = useCurrentProject();
  const [editorData, setEditorData] = useState<Descendant[]>();
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [timeSpent, setTimeSpent] = useState(1);
  const [showTutorial, setShowTutorial] = useState(false);
  const [createDailyProgress] = useCreateDailyProgressMutation();
  const { characterAtUsageCount } = useAppSelector((state) => state.app.flags);

  const onCreateTask = useCallback(
    async (content: Descendant[]) => {
      let timeframeNumber = project?.userTimeframes[currentUser!];
      if (theme === "next24") {
        timeframeNumber++;
      }
      const success = await apiToastPromise(
        createDailyProgress({
          input: {
            content,
            project: project?._id!,
            task: selectedTask?._id,
            timeSpent,
            timeframe: timeframeNumber,
          },
        }),
        {
          error: "Failed to create daily progress",
          loading: "Creating daily progress...",
          success: "Daily progress created successfully",
        },
      );
      if (!success) return;
      setEditorData([{ children: [{ text: "" }], type: "paragraph" }]);
      setAddingProgress(false);
    },
    [
      createDailyProgress,
      currentUser,
      project?._id,
      project?.userTimeframes,
      selectedTask?._id,
      setAddingProgress,
      theme,
      timeSpent,
    ],
  );

  return (
    <div
      className={twMerge(
        "bg-background mb-4 p-3 rounded-xl group/title relative",
      )}
    >
      <div className="absolute bg-background inset-0 rounded-xl drop-shadow-lg"></div>

      <div className="mb-1 flex relative">
        <div className="flex-1" />
        <div className="flex gap-1">
          <PartialProgressMeter
            pathClassName={twMerge(
              (theme === "last24" || theme === "sprint") && "stroke-error",
              theme === "next24" && "stroke-primary",
            )}
            percentage={timeSpent / hoursInDay}
          />
          <TimeSpent
            className={twMerge(
              (theme === "last24" || theme === "sprint") && "bg-error",
              theme === "next24" && "bg-primary",
            )}
            time={timeSpent}
          />
        </div>
      </div>
      <Select
        className="mb-2"
        autoFocus
        classNames={{ control: () => "border !border-black" }}
        options={tasks.map((t) => ({
          label: `${project?.code}-${t.counterId} ${t.title}`,
          value: t._id,
          ...t,
        }))}
        placeholder="Select task..."
        onChange={(v) => {
          setSelectedTask(v as Task);
        }}
      />
      <div className="relative">
        <SmartTextInput
          editorData={editorData}
          flags={{
            countAtCharacterUsage: true,
          }}
          onChange={(v) => {
            setEditorData(v);
            if (
              v?.[0]?.children?.[0].text &&
              v?.[0]?.children?.[0]?.text.length > 0
            ) {
              setShowTutorial(false);
            }
          }}
          onFocus={() => {
            if (
              characterAtUsageCount === undefined ||
              characterAtUsageCount < 5
            ) {
              setShowTutorial(true);
            }
          }}
          onBlur={() => {
            setShowTutorial(false);
          }}
          placeholder="Describe progress ..."
          callbacks={{
            onAddEstimate: setTimeSpent,
            onSubmit: onCreateTask,
          }}
        />
        <ProgressInputPopover isOpen={showTutorial} />
      </div>
    </div>
  );
}
