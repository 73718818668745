import { useAppDispatch } from "@redux/hooks";
import { projectSlice } from "@redux/slices/project.slice";

import { Sidebar } from "./Sidebar";

import { ProgressCart } from "app/kanban/ProgressCart.component";
import { DNDDashboardContainer } from "components/DNDDashboard";
import { useCurrentProject } from "hooks/useCurrentProject";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

export const DashboardLayout = () => {
  const { projectId } = useParams();
  const { project } = useCurrentProject();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (project?._id !== projectId) {
      dispatch(projectSlice.actions.setCurrentProject(projectId!));
    }
  }, [dispatch, project?._id, projectId]);

  return (
    <div className="flex w-screen h-full bg-gray">
      <Sidebar />
      <div className="mt-14 md:mt-0 w-screen flex overflow-hidden relative">
        <DNDDashboardContainer>
          <div className="flex flex-1 overflow-hidden">
            <Outlet />
          </div>
          <ProgressCart />
        </DNDDashboardContainer>
      </div>
    </div>
  );
};
