import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme";
import * as Popover from "@radix-ui/react-popover";
import { twMerge } from "tailwind-merge";

export function HintPopover({
  isOpen,
  message,
}: {
  isOpen: boolean;
  message: string;
}) {
  const preferredColorScheme = usePrefersColorScheme();

  return (
    <Popover.Root open={isOpen}>
      <div className="absolute inset-0 flex justify-center pointer-events-none -my-4">
        <Popover.Trigger className=""></Popover.Trigger>
      </div>
      <Popover.Portal>
        <Popover.Content
          onOpenAutoFocus={(e) => e.preventDefault()}
          className={twMerge(
            "bg-background text-ink rounded shadow-lg p-4 max-w-[250px] float-start",
            preferredColorScheme,
          )}
        >
          <Popover.Arrow className="fill-background" />
          <div>{message}</div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
