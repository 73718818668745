import { twMerge } from "tailwind-merge";

export function SprintTimeSpent({
  className,
  time,
}: {
  className?: string;
  time: {
    days: number;
    hours: number;
  };
}) {
  return (
    <div
      className={twMerge(
        "h-5 px-2 rounded-lg bg-orange-400 text-white text-center flex items-center justify-center text-xs",
        className,
      )}
    >
      {time.days ? `${time.days}D ` : ""}
      {time.hours}H
    </div>
  );
}
