import { Format } from "./slate-extension";

import isHotkey from "is-hotkey";
import { useCallback } from "react";
import { Descendant, Editor, Range, Text, Transforms } from "slate";

export function useRichText({
  editor,
  clearOnSubmit,
  editorData,
  onSubmit,
}: {
  clearOnSubmit?: boolean;
  editor: Editor;
  editorData?: Descendant[];
  onSubmit?: (val: Descendant[]) => void;
}) {
  const handleFormatting = useCallback(() => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const start = Range.start(selection);
      const anchor = start.offset;
      const textBefore = Editor.string(editor, {
        anchor: { ...start, offset: Math.max(0, anchor - 3) },
        focus: start,
      });
      if (!editor.getMarks()?.code && textBefore === "```") {
        Transforms.delete(editor, {
          at: {
            anchor: { ...start, offset: anchor - 3 },
            focus: start,
          },
        });
        editor.insertNode({
          children: [{ code: true, text: "" }],
          type: "paragraph",
        });
      }
      if (editor.getMarks()?.code && textBefore.slice(1) === "\n\n") {
        Editor.removeMark(editor, "code");
      }
    }
  }, [editor]);

  const onKeyDown = useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      const { currentTarget } = event;
      switch (event.key) {
        case "Escape":
          setTimeout(() => {
            currentTarget.blur();
          });
          break;
        case "Backspace":
          if (editor.getMarks()?.code) {
            const node = editor.node(editor.selection!)[0];
            if (Text.isText(node)) {
              if (node.text === "") {
                editor.removeMark("code");
                event.preventDefault();
              }
            }
          }
          break;
        case "Enter":
          if (event.shiftKey || !onSubmit) {
            if (editor.getMarks()?.code) {
              Transforms.insertText(editor, "\n", {
                at: editor.selection?.focus,
              });
              event.preventDefault();
            }
          } else {
            if (event.defaultPrevented) break;
            event.preventDefault();
            if (editorData) {
              onSubmit(editorData);
              if (clearOnSubmit) {
                Transforms.delete(editor, {
                  at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                  },
                });
              }
            }
          }
          break;
      }
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event as any)) {
          event.preventDefault();
          const mark = (HOTKEYS as any)[hotkey];
          toggleMark(editor, mark);
        }
      }
    },
    [clearOnSubmit, editor, editorData, onSubmit],
  );

  return { handleFormatting, onKeyDown };
}

const HOTKEYS: { [name: string]: Format } = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
};

function isMarkActive(editor: Editor, format: Format) {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
}

function toggleMark(editor: Editor, format: Format) {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}
