import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import { useCurrentProject } from "hooks/useCurrentProject";
import {
  useMoveColumnMutation,
  useMoveTaskMutation,
} from "modules/api/generated-api";
import React from "react";

export default function Container({ children }: { children: any }) {
  const { project } = useCurrentProject();
  const [moveTask] = useMoveTaskMutation();
  const [moveColumn] = useMoveColumnMutation();

  const onDragEnd = async ({ source, destination, type }: DropResult) => {
    // Dropped outside the list
    if (!destination) {
      return;
    }
    if (type === "task") {
      const task = project!.columns
        .find(({ _id }) => _id === source.droppableId)
        ?.tasks.find((_, index) => source.index === index);
      if (!task) throw new Error("Task not found");
      await moveTask({
        input: {
          column: destination.droppableId,
          id: task?._id,
          position: destination.index,
        },
      });
    } else if (type === "column") {
      const column = project?.columns.find(
        (_, index) => index === source.index,
      );
      if (!column) throw new Error("Column not found");
      await moveColumn({
        input: {
          id: column?._id,
          position: destination.index,
          projectId: project?._id,
        },
      });
    }
  };

  return (
    <DragDropContext
      onDragStart={() => {
        if (window.navigator.vibrate) {
          window.navigator.vibrate(100);
        }
      }}
      onDragEnd={onDragEnd}
    >
      {children}
    </DragDropContext>
  );
}
