import { useAppDispatch } from "@redux/hooks";
import { appSlice } from "@redux/slices/app.slice";

import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { useFirebaseInit } from "modules/firebase/hooks/useFirebaseInit";

interface EnableNotificationFormInputs {
  enable: boolean;
}

export function EnableNotificationModal() {
  const dispatch = useAppDispatch();
  const { init: firebaseInit } = useFirebaseInit();

  const onClose = async ({ enable }: EnableNotificationFormInputs) => {
    dispatch(
      appSlice.actions.setFlag({ lastAskedEnableNotifications: new Date() }),
    );
    if (enable) {
      await Notification.requestPermission();
      firebaseInit();
    }
  };
  return (
    <Modal onClose={() => onClose({ enable: false })}>
      <div className="flex flex-col gap-4">
        <div className="text-center text-lg">Enable notifications</div>
        <div>
          We would like to send you notifications. Would you like to enable
          them?
        </div>
        <div className="flex gap-4">
          <Button
            color="white"
            text="No"
            onClick={() => onClose({ enable: false })}
          />
          <Button text="Yes" onClick={() => onClose({ enable: true })} />
        </div>
      </div>
    </Modal>
  );
}
