import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class ColumnRemovedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { columnRemoved } = subscriptionResponse;
    if (columnRemoved) {
      draft.project.columns = draft.project.columns.filter(
        ({ _id }) => _id !== columnRemoved,
      );
    }
  }
  forSubscription: keyof ProjectSubscriptionResponse = "columnRemoved";
}
