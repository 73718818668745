import toast from "react-hot-toast";

type TPArgs<T> = Parameters<typeof toast.promise<T>>;

export function apiToastPromise<T>(
  promise: Promise<T>,
  msgs: TPArgs<T>[1],
  opts?: TPArgs<T>[2],
) {
  try {
    return (
      toast.promise(
        promise.then((response: any) => {
          if (response.error) {
            throw response.error;
          }
          return response;
        }),
        msgs,
        opts,
      ) as Promise<T>
    ).catch((e) => {});
  } catch (err) {}
}
