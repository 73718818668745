import { findTask } from "../helpers";
import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  GetOneTaskQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class TaskUpdatedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { taskUpdated, userId } = subscriptionResponse;
    if (taskUpdated) {
      const { task, columnIndex } = findTask({
        columnId: taskUpdated.column!._id,
        draft,
        taskId: taskUpdated._id,
      });
      Object.assign(task, taskUpdated, { updateByUser: userId });
      if (taskUpdated.isArchived) {
        draft.project.columns[columnIndex].tasks = draft.project.columns[
          columnIndex
        ].tasks.filter(({ _id }) => _id !== taskUpdated._id);
      }
    }
  }
  handleTaskUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneTaskQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }): void {
    const { taskUpdated, userId } = subscriptionResponse;
    if (taskUpdated) {
      draft.task = Object.assign(draft.task, taskUpdated, {
        updateByUser: userId,
      });
    }
  }
  getTaskId(subscriptionResponse: ProjectSubscriptionResponse): string {
    return subscriptionResponse.taskUpdated!._id;
  }
  forSubscription: keyof ProjectSubscriptionResponse = "taskUpdated";
}
