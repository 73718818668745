import CalendarTask, { CalendarTaskI } from "./CalendarTask";

import { addMonths, differenceInDays, format, startOfMonth } from "date-fns";
import React from "react";

interface CalendarConfig {
  mode: "year" | "quarter" | "month";
}

interface CalendarProps {
  config: CalendarConfig;
  tasks: CalendarTaskI[];
}

function displayMonth(date: Date) {
  const monthAbbreviation = format(date, "MMM");
  return `${monthAbbreviation}${date.getMonth() === 0 ? ` ${date.getFullYear()}` : ""}`;
}

function getMonthsRange(monthsRange: number) {
  const currentDate = new Date();
  const currentMonth = startOfMonth(currentDate);

  const months: Date[] = [];
  for (let i = -monthsRange; i <= monthsRange; i++) {
    const date = addMonths(currentMonth, i);
    months.push(date);
  }
  return {
    currentMonth,
    months,
  };
}

const configToIncrement: { [mode in CalendarConfig["mode"]]: number } = {
  month: 8,
  quarter: 4,
  year: 2,
};

export function Calendar({ tasks, config }: CalendarProps) {
  const { months, currentMonth } = getMonthsRange(18);
  const incrementPerDay = configToIncrement[config.mode];
  return (
    <div className="flex flex-col">
      <div className="relative">
        {months.map((m, i) => {
          return (
            <div
              key={`month-${i}`}
              className="absolute left-1/2 text-xs overflow-visible whitespace-nowrap"
              style={{
                marginLeft: `${differenceInDays(m, currentMonth) * incrementPerDay}px`,
              }}
            >
              {displayMonth(m)}
            </div>
          );
        })}
        <div
          className="absolute border-l h-screen left-1/2 mt-4 border-background"
          style={{
            marginLeft: `${differenceInDays(new Date(), currentMonth) * incrementPerDay}px`,
          }}
        ></div>
        {tasks.map((task, i) => (
          <CalendarTask
            task={task}
            key={`task-${i}`}
            currentMonth={currentMonth}
            incrementPerDay={incrementPerDay}
            index={i}
          />
        ))}
      </div>
    </div>
  );
}
