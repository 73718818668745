// Used to add white/black text to color
export function lightOrDark(color: string): string {
  let r: number, g: number, b: number;

  if (!color) return "light";
  // Convert HEX to RGB
  color = color.replace("#", "");
  if (color.length === 3) {
    color = color
      .split("")
      .map((char) => char + char)
      .join("");
  }
  r = parseInt(color.slice(0, 2), 16);
  g = parseInt(color.slice(2, 4), 16);
  b = parseInt(color.slice(4, 6), 16);

  // Calculate the HSP value
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Determine if the color is light or dark
  return hsp > 150 ? "light" : "dark";
}
