import { useAppDispatch } from "@redux/hooks";
import { appSlice } from "@redux/slices/app.slice";

import useTagSearch from "./tag-search/useTagSearch";
import { FormatButton } from "./FormatButton";
import {
  CustomElement,
  CustomElementProps,
  CustomText,
} from "./slate-extension";
import { TagSearchMention } from "./TagSearchMention";
import { useRichText } from "./useRichText";

import { isEqual } from "lodash";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Transforms,
} from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import { twMerge } from "tailwind-merge";
export const Portal = ({ children }: { children?: ReactNode }) => {
  return typeof document === "object"
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

export type SmartTextInputProps = {
  callbacks?: Parameters<typeof useTagSearch>["0"]["callbacks"] & {
    onSubmit?: Parameters<typeof useRichText>["0"]["onSubmit"];
  };
  className?: string;
  clearOnSubmit?: boolean;
  editorData?: Descendant[];
  editorDataForce?: Descendant[];
  flags?: {
    countAtCharacterUsage?: boolean;
  };
  onBlur?: () => void;
  onChange?: (val: CustomElement[]) => void;
  onFocus?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  placeholder?: string;
  showFormattingBar?: boolean;
};

export const SmartTextInput = ({
  className,
  editorData,
  editorDataForce,
  placeholder,
  onChange,
  onFocus,
  onKeyDown,
  onBlur,
  clearOnSubmit,
  callbacks = {},
  flags = {},
  showFormattingBar,
}: SmartTextInputProps) => {
  const ref = useRef<HTMLDivElement | null>();
  const dispatch = useAppDispatch();
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const renderPlaceholder = useCallback(
    () => (
      <span
        contentEditable={false}
        data-slate-placeholder
        className=" absolute pointer-events-none select-none text-ink-dim"
      >
        {placeholder}
      </span>
    ),
    [],
  );
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    [],
  );
  const [marks, setMarks] = useState<Omit<CustomText, "text">>({} as any);
  const [forcedUpdate, setForcedUpdate] = useState(false);
  useEffect(() => {
    if (!editorDataForce) return;
    editor.children = editorDataForce;
    setForcedUpdate(true);
  }, [editor, editorDataForce]);

  const { handleFormatting, onKeyDown: onKeyDownRichText } = useRichText({
    clearOnSubmit,
    editor,
    editorData,
    onSubmit: callbacks.onSubmit,
  });
  const {
    index,
    handleSearch,
    results,
    resultsWithCategories,
    handleChoiceClick,
    target,
    onKeyDown: onKeyDownTagSearch,
  } = useTagSearch({
    callbacks,
    clearOnSubmit,
    editor,
    editorData,
    flags,
    ref,
  });

  return (
    <div className={className}>
      <Slate
        editor={editor}
        initialValue={editorData || initialValue}
        onChange={(val) => {
          if (forcedUpdate) {
            setForcedUpdate(false);
            return;
          }
          setMarks(Editor.marks(editor)!);
          if (!(editorData && isEqual(editorData!, val)) && onChange) {
            onChange(val);
          }
          handleSearch();
          handleFormatting();
        }}
      >
        {showFormattingBar && (
          <div className="bg-background p-2 flex gap-2 rounded-t border border-b-0">
            <FormatButton editor={editor} format="bold" marks={marks} />
            <FormatButton editor={editor} format="italic" marks={marks} />
            <FormatButton editor={editor} format="underline" marks={marks} />
            <FormatButton editor={editor} format="code" marks={marks} />
          </div>
        )}
        <Editable
          className={twMerge(
            "bg-background w-full px-2 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring focus:border-blue-300",
            showFormattingBar && "rounded-t-none min-h-40",
          )}
          onFocus={() => {
            dispatch(appSlice.actions.setPopupOpen(true));
            onFocus && onFocus();
          }}
          onBlur={() => {
            dispatch(appSlice.actions.setPopupOpen(false));
            onBlur && onBlur();
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          renderPlaceholder={renderPlaceholder}
          onPaste={(event: React.ClipboardEvent<HTMLDivElement>) => {
            // makes sure pasted text doesnt overflow into multiple lines
            event.preventDefault();
            const text = event.clipboardData.getData("text/plain");
            Transforms.insertText(editor, text, {
              at: editor.selection?.focus,
            });
          }}
          onKeyDown={(...args) => {
            onKeyDownTagSearch(...args);
            onKeyDownRichText(...args);
            if (onKeyDown) {
              onKeyDown(...args);
            }
          }}
          placeholder="Enter some text..."
        />
        {target && results.length > 0 && (
          <Portal>
            <div
              ref={ref as any}
              className="bg-white rounded-md shadow-sm absolute -left-[-9999px] -top-[-9999px] p-1 z-[1]"
              data-cy="mentions-portal"
            >
              {resultsWithCategories.map(
                (resultWithCategory, resultWithCategoryIndex) => (
                  <div
                    key={`${resultWithCategory.value}-${resultWithCategory.type}-${resultWithCategoryIndex}`}
                    onClick={() => {
                      handleChoiceClick(resultWithCategory);
                    }}
                    className={twMerge(
                      "rounded p-1 bg-transparent text-sm px-3",
                      resultWithCategory.index === index && "bg-gray",
                      resultWithCategory.type === "separator" &&
                        "font-bold text-xs px-1",
                    )}
                  >
                    {resultWithCategory.value}
                  </div>
                ),
              )}
            </div>
          </Portal>
        )}
      </Slate>
    </div>
  );
};

const withMentions: <T extends BaseEditor>(
  editor: T,
  clipboardFormatKey?: string,
) => T = (editor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "mention" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "mention" ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === "mention" || markableVoid(element);
  };

  return editor;
};

// Borrow Leaf renderer from the Rich Text example.
// In a real project you would get this via `withRichText(editor)` or similar.
const Leaf = ({ attributes, children, leaf }: CustomElementProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code className="block bg-gray p-4 rounded-lg">{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const Element = (props: CustomElementProps) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "mention":
      return <TagSearchMention {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const initialValue: Descendant[] = [
  {
    children: [
      {
        text: "",
      },
    ],
    type: "paragraph",
  },
];
