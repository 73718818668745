import { Calendar } from "components/Calendar/Calendar";

export function Roadmap() {
  return (
    <div className="bg-gray flex-1">
      Roadmap
      <Calendar
        tasks={[
          {
            end: new Date(2024, 0, 24),
            id: 1,
            name: "Find the first",
            start: new Date(2024, 0, 10),
          },
          {
            end: new Date(2024, 1, 24),
            id: 2,
            name: "Find the first",
            start: new Date(2024, 0, 31),
          },
        ]}
        config={{ mode: "year" }}
      />
    </div>
  );
}
