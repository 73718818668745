import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: ActivityData = {};

export const activitySlice = createSlice({
  initialState,
  name: "activity",
  reducers: {
    addTaskActivity(state, action: PayloadAction<Omit<Activity, "ts">>) {
      const { taskId } = action.payload;
      if (!state[taskId]) state[taskId] = [];
      state[taskId].push({ ...action.payload, ts: new Date() });
    },
  },
});

export type ActivityIds =
  | "content-update"
  | "title-update"
  | "assignee-update"
  | "other";

export interface Activity {
  id: ActivityIds;
  taskId: string;
  ts: Date;
  userId: string;
}

interface ActivityData {
  [taskId: string]: Activity[];
}
