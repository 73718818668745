import {
  LOGIN_PATH,
  ONBOARDING_PATH,
  PROJECT_PATH,
  PROJECT_PATH_CONTAINER,
  ROADMAP_PATH,
  ROOT_PATH,
  SIGNUP_PATH,
  SPRINT_PATH,
  SPRINT_SUGGESTION_PATH,
  STANDUP_PATH,
  TASK_PATH,
} from "./paths";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";

import App from "app/App";
import { DashboardLayout } from "app/dashboard/Dashboard.layout";
import { Kanban } from "app/kanban/Kanban.page";
import { LoggedInLayout } from "app/LoggedIn.layout";
import { Login } from "app/login/Login.page";
import { Onboarding } from "app/onboarding/Onboarding.page";
import { Roadmap } from "app/roadmap/Roadmap.page";
import { Signup } from "app/signup/Signup.page";
import { Sprint } from "app/sprint/Sprint.page";
import { SprintSuggestionPage } from "app/sprint-suggestion/SprintSuggestion.page";
import { Standup } from "app/standup/Standup.page";
import { TaskPage } from "app/task/Task.page";
import { getSubdomain } from "modules/navigation/functions";
import { createBrowserRouter, Navigate } from "react-router-dom";

const isNoBs = getSubdomain() === "nobs";

export const router = createBrowserRouter(
  isNoBs
    ? [
        {
          children: [
            {
              element: <SprintSuggestionPage />,
              path: "*",
            },
          ],
          element: <App />,
          path: "*",
        },
      ]
    : [
        {
          children: [
            {
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          element: <Onboarding />,
                          loader: () => ({ title: "Onboarding" }),
                          path: ONBOARDING_PATH,
                        },
                        {
                          element: <Standup />,
                          loader: () => ({ title: "Standup" }),
                          path: STANDUP_PATH,
                        },
                        {
                          element: <Sprint />,
                          loader: () => ({ title: "Sprint" }),
                          path: SPRINT_PATH,
                        },
                        {
                          children: [
                            {
                              element: <TaskPage />,
                              loader: () => ({ hasBack: true }),
                              path: TASK_PATH,
                            },
                            {
                              element: <Kanban />,
                              path: PROJECT_PATH,
                            },
                            {
                              element: <Roadmap />,
                              loader: () => ({ title: "Roadmap" }),
                              path: ROADMAP_PATH,
                            },
                          ],
                          element: <DashboardLayout />,
                          path: PROJECT_PATH_CONTAINER,
                        },
                        {
                          element: <SprintSuggestionPage />,
                          loader: () => ({ title: "Sprint Suggestion" }),
                          path: SPRINT_SUGGESTION_PATH,
                        },
                      ],
                      element: <LoggedInLayout />,
                      path: ROOT_PATH,
                    },
                  ],
                },
              ],
              element: <PrivateRoutes />,
            },
            {
              children: [
                {
                  element: <Login />,
                  path: LOGIN_PATH,
                },
                {
                  element: <Signup />,
                  path: SIGNUP_PATH,
                },
              ],
              element: <PublicRoutes />,
            },
          ],
          element: <App />,
          path: ROOT_PATH,
        },
        {
          element: <Navigate to={ROOT_PATH} replace />,
          path: "*",
        },
      ],
);
