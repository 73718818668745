import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  GetOneTaskQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class DailyProgressRemovedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { dailyProgressRemoved } = subscriptionResponse;
    if (dailyProgressRemoved) {
      // @TODO: remove is not needed
      // if (dailyProgressRemoved.task) {
      //   const { task } = findTask({
      //     columnId: dailyProgressRemoved.task.column!._id,
      //     draft,
      //     taskId: dailyProgressRemoved.task._id,
      //   });
      //   if (task.dailyProgress) {
      //     task.dailyProgress = task.dailyProgress.filter(
      //       ({ _id }) => _id !== dailyProgressRemoved._id,
      //     );
      //   }
      // }
      const { currentSprint } = draft.project;
      if (currentSprint) {
        currentSprint.dailyProgress = currentSprint.dailyProgress.filter(
          ({ _id }) => _id !== dailyProgressRemoved._id,
        );
      }
    }
  }
  handleTaskUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneTaskQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }): void {
    const { dailyProgressRemoved } = subscriptionResponse;
    if (dailyProgressRemoved) {
      if (draft.task.dailyProgress) {
        draft.task.dailyProgress = draft.task.dailyProgress.filter(
          ({ _id }) => _id !== dailyProgressRemoved._id,
        );
      }
    }
  }
  getTaskId(subscriptionResponse: ProjectSubscriptionResponse) {
    return subscriptionResponse.dailyProgressRemoved!.task?._id;
  }
  forSubscription: keyof ProjectSubscriptionResponse = "dailyProgressRemoved";
}
