import SomethingWentWrong from "../../assets/lottie/setup-project.json";

import { CreateProjectForm } from "./CreateProjectForm";

import { Button } from "components/Button";
import useAuth from "hooks/useAuth";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PROJECT_PATH } from "routes/paths";

export function Onboarding() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const lottieRef = React.useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (!lottieRef.current) return;
    (lottieRef.current as any).setSpeed(0.2);
  }, []);

  return (
    <div className="flex h-full w-full bg-gray">
      <div className="md:mx-10 md:my-20 md:rounded bg-background flex-1 drop-shadow-2xl grid grid-cols-12">
        <div className="flex flex-col col-span-12 md:col-span-8">
          <div className="flex p-2">
            <div className="flex-1" />
            <Button text="Logout" onClick={logout} color="transparent" />
          </div>
          <div className="mx-20 my-10 flex flex-col gap-2 self-center flex-1 justify-center mb-40">
            <div className="text-xl mb-4">Create your first project</div>
            <CreateProjectForm
              onCreated={(createdProject) => {
                navigate(
                  PROJECT_PATH.replace(
                    ":projectId",
                    createdProject.createProject._id,
                  ),
                );
              }}
            />
          </div>
        </div>
        <div className="hidden md:flex flex-col items-center justify-center col-span-4 bg-gray rounded-r mr-1 my-1 overflow-hidden">
          <Lottie
            lottieRef={lottieRef}
            animationData={SomethingWentWrong}
            className="size-[400px]"
          />
        </div>
      </div>
    </div>
  );
}
