import { useAppSelector } from "@redux/hooks";

import { EnableNotificationModal } from "./dashboard/EnableNotificationsModal";

import { differenceInDays } from "date-fns";
import { useCurrentProject } from "hooks/useCurrentProject";
import { useProjectCache } from "hooks/useProjectCache";
import { useGetAllUserProjectsQuery } from "modules/api/generated-api";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ONBOARDING_PATH, PROJECT_PATH } from "routes/paths";

export const LoggedInLayout = () => {
  const { lastAskedEnableNotifications } = useAppSelector(
    (state) => state.app.flags,
  );
  const shouldShowEnableNotifications = useMemo(
    () =>
      lastAskedEnableNotifications === undefined ||
      (lastAskedEnableNotifications &&
        differenceInDays(new Date(), lastAskedEnableNotifications)) > 1,
    [lastAskedEnableNotifications],
  );
  useProjectCache();
  const location = useLocation();
  const navigate = useNavigate();
  const { project } = useCurrentProject();
  const { data } = useGetAllUserProjectsQuery();
  useEffect(() => {
    if (location.pathname === "/") {
      if (project) {
        navigate(PROJECT_PATH.replace(":projectId", project?._id));
      } else if (data) {
        if (data.getAllUserProjects.elements.length > 0) {
          navigate(
            PROJECT_PATH.replace(
              ":projectId",
              data.getAllUserProjects.elements[0]._id,
            ),
          );
        } else {
          navigate(ONBOARDING_PATH);
        }
      }
    }
  }, [data, location.pathname, navigate, project]);
  return (
    <>
      <Outlet />
      {shouldShowEnableNotifications &&
        Notification.permission === "default" && <EnableNotificationModal />}
    </>
  );
};
