import { SprintPlanDisplay } from "./SprintPlanDisplay.component";

import "react-circular-progressbar/dist/styles.css";

import { TaskPlanDisplay } from "app/standup/TaskPlanDisplay.component";
import { Button } from "components/Button";
import { Select } from "components/Dropdown/Select";
import { PartialProgressMeter } from "components/PartialProgressMeter";
import { SprintTimeSpent } from "components/TimeSpent/SprintTimeSpent";
import { useCurrentProject } from "hooks/useCurrentProject";
import { calculateDays } from "modules/daily-progress/functions";
import { useDailyTasks } from "modules/daily-progress/hooks/useDailyTasks";
import { useClock } from "modules/standup/hooks/useClock";
import { useStandupTimeframe } from "modules/standup/hooks/useStandupTimeframe";
import { useState } from "react";
import { SingleValue } from "react-select";
import { twMerge } from "tailwind-merge";

const sprintParts = [
  { label: "Sprint Retrospective", value: "retrospective" },
  { label: "Sprint Worklog", value: "worklog" },
  { label: "Sprint Planning", value: "planning" },
] as const;
type SprintPartsType = (typeof sprintParts)[number];

export function Sprint() {
  const [focusedUser, setFocusedUser] = useState<string>();
  const [focusedUserTime, setFocusedUserTime] = useState<Date>(new Date());
  const [startedSince] = useState(new Date());
  const { project, projectUsers } = useCurrentProject();
  const { projectTf } = useStandupTimeframe();
  const {
    dailyProgressByUser,
    reportedStatsByUser,
    tasks: taskProgress,
  } = useDailyTasks({
    filterNonDone: true,
  });
  const [selectedSprintPart, setSelectedSprintPart] = useState<SprintPartsType>(
    sprintParts[0],
  );
  const userIds = dailyProgressByUser
    ? Object.keys(dailyProgressByUser).filter(
        (u) => u !== "undefined" && dailyProgressByUser[u].length > 0,
      )
    : [];
  const { clock } = useClock({ startedSince });
  const { clock: userClock, percentage } = useClock({
    countdownSeconds: project && project.standupMinsPerUser * 60,
    startedSince: focusedUserTime,
  });

  const handleSprintPartChange = (selection: SingleValue<SprintPartsType>) => {
    setSelectedSprintPart(selection as SprintPartsType);
  };

  const allUsersSubmitted = project
    ? Object.values(
        project?.userTimeframes as { [key: string]: number },
      ).filter((timeframe) => project && timeframe <= projectTf).length === 0
    : false;

  return (
    <div className="bg-gray flex flex-col h-svh">
      <div className="text-center m-8 flex flex-col items-center">
        <Select
          value={selectedSprintPart}
          onChange={handleSprintPartChange}
          isMulti={false}
          options={sprintParts}
          classNames={{
            option: () => "!text-black ",
          }}
        />
        {/* @TODO: maybe add it back later when call is incorporated */}
        {/* <div>{clock}</div> */}
      </div>
      <div
        className={twMerge(
          ["retrospective", "planning"].includes(selectedSprintPart.value)
            ? "overflow-y-scroll"
            : "overflow-y-hidden",
        )}
      >
        {selectedSprintPart.value === "retrospective" && (
          <div className="px-8 mb-4">
            <SprintPlanDisplay
              taskProgress={taskProgress}
              mode="retrospective"
            />
          </div>
        )}

        {selectedSprintPart.value === "worklog" && (
          <div className="px-8 h-full flex flex-row justify-evenly gap-4 overflow-y-hidden">
            {userIds.map((userId, i) => {
              const isColumnSelected = userId === focusedUser;
              const sprintPlan = calculateDays(
                reportedStatsByUser && !!reportedStatsByUser[userId]?.timeSpent
                  ? reportedStatsByUser[userId]?.timeSpent
                  : 0,
              );
              return (
                <div
                  className="w-[300px] min-w-[200px] relative flex flex-col"
                  key={`column-${i}`}
                >
                  {/* User header */}
                  <div
                    className={twMerge(
                      "absolute -inset-4 bg-primary duration-[2000ms] opacity-0 rounded-2xl",
                      isColumnSelected && "opacity-30",
                    )}
                  ></div>

                  <div className="flex flex-row items-center justify-center bg-background text-center font-semibold mb-4 p-3 rounded-xl drop-shadow-lg group/title relative gap-2">
                    <div>{projectUsers[userId]?.name}</div>

                    <div className="flex gap-1">
                      <SprintTimeSpent time={sprintPlan} />
                    </div>

                    <div className="absolute -inset-6 group">
                      <div className="top-6 left-6 relative block">
                        <div className="absolute -top-1 left-0 right-12">
                          <div className=" h-10 w-full bottom-0 absolute">
                            {!isColumnSelected && (
                              <Button
                                text="Focus"
                                className="relative duration-300 invisible group-hover:visible opacity-0 group-hover:opacity-100 -top-5 group-hover:top-0"
                                onClick={() => {
                                  setFocusedUserTime(new Date());
                                  setFocusedUser(userId);
                                }}
                              />
                            )}
                          </div>
                          <div className=" h-10 w-full bottom-10 absolute">
                            {isColumnSelected && (
                              <PartialProgressMeter
                                className={twMerge(
                                  "absolute -left-20",
                                  percentage <= 0 &&
                                    "animate-[scale_1s_ease-in-out_infinite]",
                                )}
                                type="standup"
                                percentage={percentage}
                                text={percentage > 0 ? userClock : "Done"}
                              />
                            )}
                            <Button
                              text="Clear Focus"
                              className={twMerge(
                                "relative duration-300 invisible opacity-0 -top-5",
                                isColumnSelected && "visible opacity-100 top-0",
                              )}
                              onClick={() => {
                                setFocusedUser(undefined);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  Progress */}
                  <div className="overflow-y-scroll">
                    {dailyProgressByUser &&
                      dailyProgressByUser[userId].length > 0 && (
                        <TaskPlanDisplay
                          theme="sprint"
                          title="Current Sprint"
                          dailyProgress={dailyProgressByUser[userId]}
                          userId={userId}
                          key={`last-24-${i}`}
                          viewOnly
                        />
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {selectedSprintPart.value === "planning" && (
          <div className="px-8 mb-4">
            <SprintPlanDisplay mode="planning" />
          </div>
        )}
      </div>
    </div>
  );
}
