import { useAppSelector } from "@redux/hooks";

import {
  GetOneProjectQuery,
  useGetOneProjectQuery,
} from "modules/api/generated-api";
import { useMemo } from "react";

export function useCurrentProject({
  filterTasksByUser,
  filterTasksByLabel,
  filterColumns,
}: {
  filterColumns?: boolean;
  filterTasksByLabel?: string[];
  filterTasksByUser?: string;
} = {}) {
  const { current } = useAppSelector((state) => state.projects);
  const { users, tasksArray } = useAppSelector((state) => state.projectsCache);
  const { data, isLoading } = useGetOneProjectQuery({ projectId: current! });
  const project = useMemo(() => {
    const project = data?.project;
    type InferredTask = GetOneProjectQuery["project"]["columns"][0]["tasks"][0];
    const filterByUserQuery = (task: InferredTask) =>
      filterTasksByUser ? task.assignee?._id === filterTasksByUser : true;
    const filterByLabelQuery = (task: InferredTask) =>
      filterTasksByLabel
        ? task.labels.some((cardLabel) =>
            filterTasksByLabel.includes(cardLabel._id),
          )
        : true;
    if ((filterTasksByUser || filterTasksByLabel) && project?.columns) {
      if (filterColumns) {
        return {
          ...project,
          columns: project.columns.filter((col) => {
            return (
              col.tasks.filter(
                (task) => filterByUserQuery(task) && filterByLabelQuery(task),
              ).length > 0
            );
          }),
        };
      } else {
        return {
          ...project,
          columns: project.columns.map((col) => {
            return {
              ...col,
              tasks: col.tasks.filter(
                (task) => filterByUserQuery(task) && filterByLabelQuery(task),
              ),
            };
          }),
        };
      }
    }
    return project;
  }, [data?.project, filterColumns, filterTasksByLabel, filterTasksByUser]);
  const projectLabels = useMemo(() => {
    if (!project?.labels) return {};
    const returnedLabels: {
      [id: string]: GetOneProjectQuery["project"]["labels"][0];
    } = {};
    project.labels.forEach((label) => {
      returnedLabels[label._id] = label;
    });
    return returnedLabels;
  }, [project?.labels]);

  return {
    isLoading,
    project,
    projectLabels,
    projectUsers: users,
    tasks: tasksArray,
  };
}
