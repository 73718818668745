import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { Assignee } from "components/Assignee";
import { Label } from "components/Label";
import { useCurrentProject } from "hooks/useCurrentProject";
import { useSubMutateCurrentProject } from "hooks/useSubMutateCurrentProject";
import { Task, UserSelectedTaskDocument } from "modules/api/generated-api";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TASK_PATH } from "routes/paths";
import { twMerge } from "tailwind-merge";

export default function Cell({ task, index }: { index: number; task: Task }) {
  const navigate = useNavigate();
  const timeoutId = useRef<NodeJS.Timeout>();
  const { mutation } = useSubMutateCurrentProject({
    gqlMutation: UserSelectedTaskDocument,
    variables: { id: task._id },
  });
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { projectUsers, project, projectLabels } = useCurrentProject();
  const { selectedTasks, userSelectedTask } = useAppSelector(
    (state) => state.projectsCache,
  );
  const handleMouseEnter = () => {
    dispatch(projectCacheSlice.actions.setUserSelectedTask(task._id));
    const id = setTimeout(() => {
      mutation();
    }, 100);
    timeoutId.current = id;
  };
  const handleMouseLeave = () => {
    dispatch(projectCacheSlice.actions.setUserSelectedTask(undefined));
    clearTimeout(timeoutId.current);
  };

  return (
    <Draggable key={task._id} draggableId={task._id} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Droppable droppableId={task._id} type="on-task">
          {(
            droppableProvided: DroppableProvided,
            droppableSnapshot: DroppableStateSnapshot,
          ) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={twMerge(
                "px-4 py-2 mb-2 select-none rounded-xl relative group",
                snapshot.isDragging
                  ? " bg-background-selected"
                  : "bg-background",
                droppableSnapshot.isDraggingOver ? "bg-blue-400" : "",
              )}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => {
                navigate(
                  TASK_PATH.replace(":projectId", project?._id!).replace(
                    ":taskId",
                    task._id,
                  ),
                );
              }}
              onContextMenu={(e) => e.preventDefault()}
            >
              <div
                className={twMerge(
                  "hidden border rounded-xl absolute inset-0",
                  userSelectedTask === task._id && "flex",
                )}
              ></div>
              <div
                className={twMerge(
                  "flex bg-transparent rounded-xl absolute inset-0 duration-500 opacity-0",
                  selectedTasks[task._id] &&
                    selectedTasks[task._id]?.uid !== currentUser! &&
                    "bg-black/30 dark:bg-white/30 opacity-100",
                )}
              >
                {selectedTasks[task._id] &&
                  projectUsers[selectedTasks[task._id].uid] && (
                    <Assignee
                      name={projectUsers[selectedTasks[task._id].uid].name}
                      className="-left-2 self-center absolute"
                    />
                  )}
              </div>
              <div className="flex gap-1">
                <div className=" text-xs flex-1 text-ink-dim">
                  {project?.code}-{task.counterId}
                </div>
                <Assignee name={task.assignee?.name} />
              </div>
              <div className="text-sm">{task.title}</div>
              <div className="flex justify-end pt-1 gap-0.5 flex-wrap">
                {task.labels.map((labelId) => {
                  const label = projectLabels[labelId._id];
                  return (
                    <Label
                      key={`label-${label._id}-${task._id}`}
                      mode="minimal"
                      title={label.title}
                      color={label.color}
                    />
                  );
                })}
              </div>
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer"
              />
            </div>
          )}
        </Droppable>
      )}
    </Draggable>
  );
}
