import { useAppSelector } from "@redux/hooks";

import Column from "./3-Column";

import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from "@hello-pangea/dnd";
import { AddColumnModal } from "app/kanban/AddColumnModal";
import { Button } from "components/Button";
import { useCurrentProject } from "hooks/useCurrentProject";
import { Column as ColumnType, Project } from "modules/api/generated-api";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

export default function Table() {
  const { vars } = useAppSelector((state) => state.projectsCache);
  const { project } = useCurrentProject({
    filterTasksByLabel: vars.filterCardsByLabel,
    filterTasksByUser: vars.filterCardsByUser,
  });

  const [openModal, setOpenModal] = useState(false);

  return (
    <Droppable
      droppableId={"columns-droppable"}
      type="column"
      direction="horizontal"
    >
      {(provided: DroppableProvided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={twMerge("flex flex-row h-full")}
        >
          {project?.columns.map((column, index) => {
            return (
              <Draggable
                key={column._id}
                draggableId={column._id}
                index={index}
              >
                {(
                  provided: DraggableProvided,
                  snapshot: DraggableStateSnapshot,
                ) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={twMerge("mr-4 select-none h-full")}
                  >
                    <Column
                      column={column as ColumnType}
                      project={project as Project}
                      provided={provided}
                      snapshot={snapshot}
                    />
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
          <div className="pt-8">
            <Button
              text="Add Column"
              onClick={() => {
                setOpenModal(true);
              }}
            />
          </div>
          {openModal && (
            <AddColumnModal
              onClose={() => {
                setOpenModal(false);
              }}
            />
          )}
        </div>
      )}
    </Droppable>
  );
}
