import { twMerge } from "tailwind-merge";

export function TimeSpent({
  className,
  time,
}: {
  className?: string;
  time: string | number;
}) {
  return (
    <div
      className={twMerge(
        "size-6 rounded-full bg-error text-white text-center flex items-center justify-center text-sm",
        className,
      )}
    >
      {time === 0.5 ? ".5" : time}
    </div>
  );
}
