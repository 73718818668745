import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: ProjectsData = {
  all: {},
  current: undefined,
};

const currentProject = (state: ProjectsData) => state.all[state.current!];

export const projectSlice = createSlice({
  initialState,
  name: "project",
  reducers: {
    // TODO: remove once backend replaces it
    removeDailyProgress(
      state,
      action: PayloadAction<Pick<DailyProgress, "ts"> & { taskId: TaskId }>,
    ) {
      const { taskId, ts } = action.payload;
      currentProject(state).tasks[taskId].dailyProgress = currentProject(
        state,
      ).tasks[taskId].dailyProgress.filter((act) => act.ts !== ts);
    },
    setCurrentProject(state, action: PayloadAction<string | undefined>) {
      state.current = action.payload;
    },
    // TODO: remove once backend replaces it
    updateTask(state, action: PayloadAction<Task>) {
      const { id } = action.payload;
      currentProject(state).tasks[id] = Object.assign(
        currentProject(state).tasks[id],
        action.payload,
      );
    },
  },
});

type TaskId = string;

interface DailyProgress {
  content: string;
  isDone?: boolean;
  timeSpent: number;
  timeframe: number;
  ts: Date;
  userId: string;
}
export interface Task {
  assignee?: string;
  column: string;
  content?: string;
  dailyProgress: DailyProgress[];
  id: TaskId;
  title: string;
}

export interface Column {
  id: string;
  taskIds: TaskId[];
  title: string;
}

interface BoardData {
  columnOrder: string[];
  columns: Record<string, Column>;
  lastColId: number;
  lastTaskId: number;
  tasks: Record<TaskId, Task>;
}

interface ProjectData {
  id: string;
  owner: string;
  standupMinsPerUser: number;
  timeframe: number;
  title: string;
}
interface ProjectsData {
  all: {
    [id: string]: ProjectData & BoardData;
  };
  current?: string;
}
