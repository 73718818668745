import { formatDistanceToNow } from "date-fns";
import { FileUpload } from "modules/api/generated-api";

export function TaskActivityUpload({ upload }: { upload: FileUpload }) {
  return (
    <>
      <b>{upload.user.name}</b>
      <div>
        uploaded{" "}
        <a className=" font-bold" href={upload.key}>
          {upload.name}
        </a>
        {" - "}
        {formatDistanceToNow(upload.ts, {
          addSuffix: true,
        })}
      </div>
      {/* <div className=" hidden group-hover:inline-flex text-white mx-2 gap-1">
        <IconButton
          onClick={() => {
            removeComment({
              id: comment._id,
            });
          }}
          icon={<AiOutlineDelete />}
        />
      </div> */}
    </>
  );
}
