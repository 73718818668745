import { useAppDispatch } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { useCurrentProject } from "./useCurrentProject";

import { Task, User } from "modules/api/generated-api";
import { useEffect } from "react";

export function useProjectCache() {
  const dispatch = useAppDispatch();
  const { project } = useCurrentProject();

  useEffect(() => {}, []);

  useEffect(() => {
    if (!project) return;
    const tasks = project.columns.reduce<Task[]>((previous, current) => {
      previous.push(...(current.tasks as Task[]));
      return previous;
    }, []);
    dispatch(projectCacheSlice.actions.setTasksArray(tasks));
  }, [project?.columns]);

  useEffect(() => {
    if (!project) return;
    const usersToCache: { [userId: string]: User } = {
      [project.owner._id]: project.owner,
    };
    project.members.forEach((member) => {
      usersToCache[member._id] = member;
    });
    dispatch(projectCacheSlice.actions.setUsers(usersToCache));
  }, [project?.columns]);
}
