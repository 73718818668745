import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { Button } from "components/Button";
import { DNDDashboardTable } from "components/DNDDashboard";
import { format } from "date-fns";
import { useCurrentProject } from "hooks/useCurrentProject";
import { useSaveScrollPosition } from "hooks/useSaveScrollPosition";
import { useNavbar } from "modules/navigation/hooks/useNavbar";
import { useStandupTimeframe } from "modules/standup/hooks/useStandupTimeframe";
import { useStandupTimeframeFunctions } from "modules/standup/hooks/useStandupTimeframeFunctions";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const Kanban = () => {
  const { projectId } = useParams();
  const { project } = useCurrentProject();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setNavbarConfig } = useNavbar();
  const { tfRemaining } = useStandupTimeframe();
  const { currentUserDay: currentDay } = useStandupTimeframeFunctions();
  const { scrollRef } = useSaveScrollPosition();
  const { userSubmittingProgress } = useAppSelector(
    (state) => state.projectsCache,
  );

  useEffect(() => {
    setNavbarConfig({ title: project?.name });
  }, [project?.name, setNavbarConfig]);

  if (!project) return <div>Project doesn't exist</div>;
  return (
    <div ref={scrollRef} className="h-full w-full flex overflow-x-scroll">
      <div className="px-8 flex-1">
        <DNDDashboardTable />
      </div>
      <div className="absolute flex flex-col gap-4 bottom-0 right-0 m-10">
        {/* <Button onClick={() => {}} text="Go to Weekly" /> */}
        {!userSubmittingProgress && tfRemaining >= 0 && (
          <div className="flex flex-col items-center">
            {tfRemaining > 0 && (
              <div className="text-error animate-bounce">
                {tfRemaining} standups late
              </div>
            )}
            <Button
              className=" whitespace-pre"
              onClick={() => {
                dispatch(
                  projectCacheSlice.actions.setUserSubmittingProgress(true),
                );
              }}
              text={`Prepare Standup${tfRemaining > 0 ? `\n(${format(currentDay, "eee d")})` : ""}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};
