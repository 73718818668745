import { removeTypenames } from "../helpers";
import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class ProjectUpdatedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { projectUpdated } = subscriptionResponse;
    if (projectUpdated) {
      Object.assign(draft.project, removeTypenames(projectUpdated));
    }
  }
  forSubscription: keyof ProjectSubscriptionResponse = "projectUpdated";
}
