import { DropResult } from "@hello-pangea/dnd";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import {
  DNDSprintSuggestionContainer,
  DNDSprintSuggestionTable,
} from "components/DNDSprintSuggestion";
import { DNDSprintControl } from "components/DNDSprintSuggestion/2-Table";
import { FormTextInput } from "components/TextInput/FormTextInput";
import { cloneDeep } from "lodash";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  SprintSuggestion,
  useSuggestSprintMutation,
} from "modules/api/generated-api";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export function SprintSuggestionPage() {
  const [suggestSprint] = useSuggestSprintMutation();

  const [description, setDescription] = useState<string>();
  const control = useRef<DNDSprintControl>({ delayIndex: 1, stopped: false });
  const [suggestion, setSuggestion] = useState<SprintSuggestion>();
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup
    .object({
      description: yup.string().required(),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ description }: any) => {
    if (!description) return;
    (document.activeElement as any)?.blur();
    const res = await apiToastPromise(
      suggestSprint({ input: { description } }),
      {
        error: "Error suggesting sprint",
        loading: "Suggesting sprint",
        success: "Suggestion received",
      },
    );
    if (!res?.data?.suggestSprint) return;
    control.current.delayIndex = 1;
    control.current.stopped = false;
    setSuggestion(cloneDeep(res?.data?.suggestSprint));
  };

  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination, type } = dropResult;
    if (destination?.index == null || !suggestion) return;
    control.current.stopped = true;
    if (type === "task") {
      const [sourceReleaseIndex, destinationReleaseIndex] = [
        source.droppableId,
        destination?.droppableId,
      ].map((id) =>
        suggestion.releases.findIndex((release) => release.id == id),
      );
      setSuggestion((value) => {
        if (!value) return;
        const [removed] = value.releases[sourceReleaseIndex].tasks.splice(
          source.index,
          1,
        );
        value.releases[destinationReleaseIndex].tasks.splice(
          destination.index,
          0,
          removed,
        );
        return value;
      });
    } else if (type === "column") {
      setSuggestion((value) => {
        if (!value) return;
        const [removed] = value.releases.splice(source.index, 1);
        value.releases.splice(destination.index, 0, removed);
        return value;
      });
    }
  };

  return (
    <div className="h-full w-full flex flex-col bg-black">
      {!suggestion && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex items-center justify-center flex-1"
        >
          <div className="flex flex-col items-center gap-2 m-4 w-full">
            <FormTextInput
              autoFocus
              id="description"
              className="min-w-full md:min-w-[400px]"
              placeholder="Enter anything you'd like to accomplish"
              type="description"
              error={errors.description}
              register={register}
            />
            <Button text="Suggest" type="submit" />
          </div>
        </form>
      )}

      {suggestion && (
        <div className="flex flex-col items-start sm:items-center justify-center flex-1 overflow-x-scroll p-4">
          <div>
            <DNDSprintSuggestionContainer
              callbacks={{
                onDragEnd,
              }}
            >
              <DNDSprintSuggestionTable
                control={control}
                suggestion={suggestion}
              />
            </DNDSprintSuggestionContainer>
          </div>
        </div>
      )}
    </div>
  );
}
