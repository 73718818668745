import Cell from "./4-Cell";

import {
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { AddTaskModal } from "app/kanban/AddTaskModal";
import { Button } from "components/Button";
import {
  Column as ColumnType,
  Project,
  useMeQuery,
  useRemoveColumnMutation,
} from "modules/api/generated-api";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { twMerge } from "tailwind-merge";

export default function Column({
  column,
  project,
  provided,
  snapshot,
}: {
  column: ColumnType;
  project: Project;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
}) {
  const [openModal, setOpenModal] = useState(false);
  const { data: userData } = useMeQuery();
  const [removeColumn] = useRemoveColumnMutation();
  if (!userData) return <></>;
  return (
    <div className="w-screen sm:w-[300px] px-8 -mx-8 pt-4 sm:pt-8 h-full flex flex-col pb-4 overflow-clip">
      <div
        className={twMerge(
          "text-center font-semibold mb-4 p-3 rounded-xl drop-shadow-lg group/title relative",
          snapshot?.isDragging ? " bg-background-selected" : "bg-background",
        )}
        {...provided?.dragHandleProps}
      >
        {column.title}
        <div className="hidden group-hover/title:flex absolute right-0 top-0 bottom-0 items-center">
          <div
            onClick={() => {
              const confirmed =
                column.tasks.length > 0
                  ? window.confirm(
                      "Tasks assigned to this column will be moved to the last, are you sure you'd like to delete?",
                    )
                  : true;
              if (confirmed) {
                removeColumn({ removeColumnId: column._id });
              }
            }}
            className="w-6 h-6 mr-3 bg-error rounded-lg text-white flex items-center justify-center"
          >
            <AiOutlineDelete />
          </div>
        </div>
      </div>
      <Droppable droppableId={column._id} direction="vertical" type="task">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={twMerge(
              snapshot.isDraggingOver ? "bg-blue-400" : "relative",
              "p-4 -m-4 pr-1 -mr-3 min-h-[400px] h-full overflow-y-scroll overflow-x-hidden always-show-scrollbar",
            )}
          >
            {column.tasks.map((task, index) => {
              return <Cell task={task} index={index} key={`cell-${index}`} />;
            })}
            {provided.placeholder}
            <div>
              <Button
                text="Add Task"
                onClick={() => {
                  setOpenModal(true);
                }}
              />
            </div>
            {openModal && (
              <AddTaskModal
                colId={column._id}
                onClose={() => {
                  setOpenModal(false);
                }}
              />
            )}
          </div>
        )}
      </Droppable>
    </div>
  );
}
