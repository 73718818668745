import { DNDSprintControl, DNDTableCallbacks } from "./2-Table";
import Cell from "./4-Cell";

import {
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { Button } from "components/Button";
import { SprintSuggestionRelease } from "modules/api/generated-api";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export default function Column({
  callbacks,
  control,
  release,
  provided,
  snapshot,
}: {
  callbacks: DNDTableCallbacks;
  control: React.MutableRefObject<DNDSprintControl>;
  provided?: DraggableProvided;
  release: SprintSuggestionRelease;
  snapshot?: DraggableStateSnapshot;
}) {
  const [showUp, setShowUp] = useState(0);
  useEffect(() => {
    control.current.delayIndex++;
    setTimeout(() => {
      setShowUp(1);
    }, control.current.delayIndex * 200);
    setTimeout(
      () => {
        setShowUp(2);
      },
      control.current.delayIndex * 200 + 1000,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-screen sm:w-[300px] px-8 -mx-8 pt-4 sm:pt-8 h-full flex flex-col pb-4 overflow-clip">
      <div
        className={twMerge(
          "hidden text-center font-semibold mb-4 p-3 drop-shadow-lg group/title relative bg-primary text-white h-[72px] items-center justify-center center",
          showUp > 0 && "flex",
          showUp < 2 && "animate-[showUp_0.5s_ease-in-out]",
        )}
        {...provided?.dragHandleProps}
      >
        {release.title}
      </div>
      <Droppable droppableId={release.id} direction="vertical" type="task">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={twMerge(
              snapshot.isDraggingOver ? "bg-blue-400" : "relative",
              "p-4 -m-4 pr-1 -mr-3 min-h-[400px] h-full overflow-y-scroll overflow-x-hidden always-show-scrollbar",
            )}
          >
            {showUp > 0 &&
              release.tasks.map((task, index) => {
                return (
                  <Cell
                    task={task}
                    index={index}
                    key={`cell-${index}`}
                    control={control}
                  />
                );
              })}
            {provided.placeholder}
            {callbacks.onAddTask && (
              <div>
                <Button
                  text="Add Task"
                  onClick={() => {
                    callbacks.onAddTask!(release.id);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </Droppable>
    </div>
  );
}
