import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { TaskPlanDisplay } from "app/standup/TaskPlanDisplay.component";
import { Button } from "components/Button";
import { format } from "date-fns";
import { useCurrentProject } from "hooks/useCurrentProject";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import { useIncrementProjectUserTimeframeMutation } from "modules/api/generated-api";
import { useDailyTasks } from "modules/daily-progress/hooks/useDailyTasks";
import { hoursInDay } from "modules/sprint-plan/constants";
import { useStandupTimeframeFunctions } from "modules/standup/hooks/useStandupTimeframeFunctions";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

export const ProgressCart = () => {
  const { project } = useCurrentProject();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const [incrementUserTimeframe] = useIncrementProjectUserTimeframeMutation();
  const [isEditingLast24Progress, setIsEditingLast24Progress] = useState(false);
  const [isEditingNext24Progress, setIsEditingNext24Progress] = useState(false);
  const [isAddingLast24Progress, setIsAddingLast24Progress] = useState(false);
  const [isAddingNext24Progress, setIsAddingNext24Progress] = useState(false);
  const {
    dailyProgressByUser,
    dailyProgressNotDoneByUser,
    plannedProgressByUser,
    reportedStatsByUser,
  } = useDailyTasks({
    filterNonDone: true,
    timeframe: project?.userTimeframes[currentUser!],
  });
  const { currentUserDay } = useStandupTimeframeFunctions();
  const { userSubmittingProgress } = useAppSelector(
    (state) => state.projectsCache,
  );

  if (!project) return <></>;
  return (
    <>
      <div
        className={twMerge(
          "absolute right-0 top-0 bottom-0 translate-x-[300px] flex flex-col min-w-[300px] w-[300px] bg-background p-4 drop-shadow-2xl overflow-y-scroll pb-10 duration-100",
          userSubmittingProgress && "translate-x-0",
        )}
      >
        <div className="text-center mb-4">
          <div className="text-sm">
            Sprint {project.currentSprint?.sprintNumber}
          </div>
          <div>{format(currentUserDay, "eeee d MMMM")}</div>
        </div>
        {dailyProgressNotDoneByUser &&
          dailyProgressNotDoneByUser[currentUser!].length > 0 && (
            <TaskPlanDisplay
              theme="last24"
              title="Planned but unconfirmed"
              dailyProgress={dailyProgressNotDoneByUser[currentUser!]}
              userId={currentUser!}
              enableConfirmTaskDone
            />
          )}
        <TaskPlanDisplay
          theme="last24"
          title="Last 24 hours"
          dailyProgress={dailyProgressByUser[currentUser!]}
          progressPercentage={
            reportedStatsByUser[currentUser!]
              ? reportedStatsByUser[currentUser!].timeSpent / hoursInDay
              : 0
          }
          userId={currentUser!}
          enableAddDailyProgress
          onAddStateChange={(adding) => {
            setIsAddingLast24Progress(adding);
          }}
          onEditStateChange={(editing) => {
            setIsEditingLast24Progress(editing);
          }}
        />
        <TaskPlanDisplay
          theme="next24"
          title="Next 24 hours"
          dailyProgress={plannedProgressByUser[currentUser!]}
          progressPercentage={
            reportedStatsByUser[currentUser!]
              ? reportedStatsByUser[currentUser!].timePlanned / hoursInDay
              : 0
          }
          userId={currentUser!}
          enableAddDailyProgress
          onAddStateChange={(adding) => {
            setIsAddingNext24Progress(adding);
          }}
          onEditStateChange={(editing) => {
            setIsEditingNext24Progress(editing);
          }}
        />
        <div className="w-full flex gap-4">
          <Button
            onClick={() => {
              dispatch(
                projectCacheSlice.actions.setUserSubmittingProgress(false),
              );
            }}
            color="white"
            text="Cancel"
            className="flex-1"
          />
          <Button
            onClick={async () => {
              const success = await apiToastPromise(
                incrementUserTimeframe({ projectId: project._id }),
                {
                  error: "Failed to submit progress",
                  loading: `Submitting progress for ${format(currentUserDay, "eeee d MMMM")} ...`,
                  success: `Progress for ${format(currentUserDay, "eeee d MMMM")} submitted successfully`,
                },
              );
              if (!success) return;
              dispatch(
                projectCacheSlice.actions.setUserSubmittingProgress(false),
              );
            }}
            disabled={
              isEditingLast24Progress ||
              isEditingNext24Progress ||
              isAddingLast24Progress ||
              isAddingNext24Progress
            }
            text="Submit"
            className="flex-1"
          />
        </div>
      </div>
    </>
  );
};
