import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class ProjectColumnsUpdatedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { projectColumnsUpdated } = subscriptionResponse;
    if (projectColumnsUpdated) {
      draft.project.columns = projectColumnsUpdated.columns.map(
        ({ _id }) =>
          draft.project.columns.find(({ _id: oldColId }) => oldColId === _id)!,
      );
    }
  }
  forSubscription: keyof ProjectSubscriptionResponse = "projectColumnsUpdated";
}
