import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { gql, useSubscription } from "@apollo/client";
import { projectSubscriptionUpdateCaches } from "modules/api/functions/project-subscription-update-caches";
import {
  SubscribeToProjectDocument,
  Subscription,
} from "modules/api/generated-api";
import { wsClient } from "modules/api/ws-api";
import { useEffect } from "react";

export function useSubscribeToCurrentProject() {
  const { current: currentProject } = useAppSelector((state) => state.projects);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { data } = useSubscription<Subscription>(
    gql(SubscribeToProjectDocument),
    {
      client: wsClient,
      variables: {
        subscribeToProjectId: currentProject,
      },
    },
  );

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(projectCacheSlice.actions.cleanupActions());
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!data) return;
    projectSubscriptionUpdateCaches({
      currentUser: currentUser!,
      dispatch,
      projectId: currentProject!,
      subscriptionResponse: data.subscribeToProject,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.subscribeToProject]);
}
