import { CustomElementProps } from "./slate-extension";

import { useFocused, useSelected } from "slate-react";
import { twMerge } from "tailwind-merge";

export function TagSearchMention({
  attributes,
  children,
  element,
}: Omit<CustomElementProps, "leaf">) {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character && element.character.replace(" ", "-")}`}
      className={twMerge(
        "bg-gray rounded-md shadow-md font-bold inline-block align-baseline text-sm text-ink mx-1 p-1",
        selected && focused && "bg-background",
        element.children?.[0].bold && "font-bold",
        element.children?.[0].italic && "italic",
      )}
    >
      @{element.character}
      {children}
    </span>
  );
}
