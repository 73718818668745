import {
  DropdownChoice,
  TagSearchChoiceType,
} from "components/SmartTextInput/slate-extension";

const User: TagSearchChoiceType = {
  getChoices: (choices) => {
    if (!choices) return [];
    return choices.allUsers.map(
      (choice) =>
        ({
          id: choice._id,
          type: "user",
          value: choice.name,
        }) as DropdownChoice,
    );
  },
};

export default User;
