import { useAppDispatch, useAppSelector } from "@redux/hooks";

import { ProgressRow } from "./ProgressRow.component";

import { Assignee } from "components/Assignee";
import { IconButton } from "components/Button";
import { PartialProgressMeter } from "components/PartialProgressMeter";
import { TimeSpent } from "components/TimeSpent";
import { SprintTimeSpent } from "components/TimeSpent/SprintTimeSpent";
import { useCurrentProject } from "hooks/useCurrentProject";
import { projectSubscriptionUpdateCaches } from "modules/api/functions/project-subscription-update-caches";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  DailyProgress,
  useMarkDailyProgressDoneMutation,
  useRemoveDailyProgressMutation,
  useUpdateDailyProgressMutation,
} from "modules/api/generated-api";
import { calculateDays } from "modules/daily-progress/functions";
import type { useDailyTasks } from "modules/daily-progress/hooks/useDailyTasks";
import { daysInSprint, hoursInDay } from "modules/sprint-plan/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { IoIosClose } from "react-icons/io";
import { MdOutlineEdit } from "react-icons/md";
import { MdOutlineDelete } from "react-icons/md";
import { Descendant } from "slate";
import { twMerge } from "tailwind-merge";

export type Props = {
  addingProgress: boolean;
  enableConfirmTaskDone?: boolean;
  handleMouseEnter: (taskId: string) => void;
  handleMouseLeave: () => void;
  onEditStateChange?: (editing: boolean) => void;
  progressPercentage?: number;
  task: ReturnType<typeof useDailyTasks>["dailyProgressByUser"][0][0];
  theme: "last24" | "next24" | "sprint";
  userId: string;
  viewOnly?: boolean;
};

export function StandupTask({
  addingProgress,
  enableConfirmTaskDone = false,
  handleMouseEnter,
  handleMouseLeave,
  onEditStateChange,
  theme,
  userId,
  viewOnly,
  task,
}: Props) {
  const { project, projectUsers } = useCurrentProject();
  const { userSubmittingProgress } = useAppSelector(
    (state) => state.projectsCache,
  );
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { taskColProject } = useAppSelector((state) => state.projectsCache);
  const [markDailyProgressDone] = useMarkDailyProgressDoneMutation();
  const [removeDailyProgress] = useRemoveDailyProgressMutation();
  const [updateDailyProgress] = useUpdateDailyProgressMutation();
  const [editedTask, setEditedTask] = useState<string>();
  const [updatedTaskNo, setUpdatedTaskNo] = useState<string>("");

  useEffect(() => {
    if (onEditStateChange) {
      onEditStateChange(addingProgress || editedTask != null);
    }
  }, [addingProgress, editedTask, onEditStateChange]);

  const updateTask = useCallback(async () => {
    setUpdatedTaskNo(task._id);
    await Promise.all(
      task.dailyProgress.map((dailyProgress) =>
        updateDailyProgress({
          input: {
            content: dailyProgress.content,
            id: dailyProgress._id,
            timeSpent: dailyProgress.timeSpent,
          },
        }),
      ),
    );
    setUpdatedTaskNo("");
  }, [task._id, task.dailyProgress, updateDailyProgress]);

  const updateCache = useCallback(
    ({
      editorData,
      dailyProgress,
      timeSpent,
    }: {
      dailyProgress: DailyProgress;
      editorData?: Descendant[];
      timeSpent?: number;
    }) => {
      if (!userSubmittingProgress) return;
      projectSubscriptionUpdateCaches({
        currentUser: currentUser!,
        dispatch,
        projectId: project!._id,
        subscriptionResponse: {
          dailyProgressUpdated: {
            ...dailyProgress,
            ...(editorData ? { content: editorData } : {}),
            ...(timeSpent ? { timeSpent } : {}),
          },
        },
      });
    },
    [currentUser, dispatch, project, userSubmittingProgress],
  );

  const cancelEditedTask = useCallback(() => {
    setEditedTask(undefined);
  }, []);

  const onUpdateTask = useCallback(async () => {
    const success = await apiToastPromise(updateTask(), {
      error: "Failed to update daily progress",
      loading: "Updating daily progress...",
      success: "Daily progress updated successfully",
    });
    if (success) {
      setEditedTask(undefined);
    }
  }, [updateTask]);

  const onDeleteTask = useCallback(async () => {
    const success = await apiToastPromise(
      Promise.all(
        task.dailyProgress.map((dailyProgress) =>
          removeDailyProgress({
            id: dailyProgress._id,
          }),
        ),
      ),
      {
        error: "Failed to delete daily progress",
        loading: "Deleting daily progress...",
        success: "Daily progress deleted",
      },
    );
    if (!success) return;
    setEditedTask(undefined);
  }, [removeDailyProgress, task.dailyProgress]);

  const onMarkTaskDone = useCallback(async () => {
    const success = await apiToastPromise(
      Promise.all(
        task.dailyProgress.map((dailyProgress) =>
          markDailyProgressDone({
            id: dailyProgress._id,
          }),
        ),
      ),
      {
        error: "Failed to mark daily progress as done",
        loading: "Marking daily progress as done...",
        success: "Daily progress marked as done",
      },
    );
    if (!success) return;
    setEditedTask(undefined);
  }, [markDailyProgressDone, task.dailyProgress]);

  const sprintPlan = useMemo(() => {
    return calculateDays(task.totalProgress);
  }, [task.totalProgress]);

  const isEditingTask = editedTask === task._id;
  const isSelectedTask = editedTask === task._id;
  return (
    <div
      key={`${userId}-task-${task._id}`}
      className={twMerge(
        "bg-background dark:border border-gray mb-4 p-3 rounded-xl drop-shadow-lg group relative pb-5",
        isSelectedTask && "border",
        isEditingTask && "border border-success",
      )}
      onMouseEnter={() => handleMouseEnter(task._id)}
      onMouseLeave={handleMouseLeave}
    >
      <div className="mb-1 flex">
        {!viewOnly && (
          <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center gap-1">
            {
              <IconButton
                color="transparent"
                className="size-7 px-0 py-0"
                onClick={() => {
                  onDeleteTask();
                }}
                icon={
                  <MdOutlineDelete
                    className={twMerge(
                      "fill-success bg-background rounded-full size-7 -mb-7 border-2 border-success p-0.5",
                    )}
                  />
                }
              />
            }
            {enableConfirmTaskDone && (
              <IconButton
                color="transparent"
                className="size-7 px-0 py-0"
                onClick={() => {
                  onMarkTaskDone();
                }}
                icon={
                  <FaCheck
                    className={twMerge(
                      "fill-success bg-background rounded-full size-7 -mb-7 border-2 border-success p-0.5",
                    )}
                  />
                }
              />
            )}
            {isEditingTask && (
              <IconButton
                color="transparent"
                className="size-7 px-0 py-0 ml-4"
                onClick={() => {
                  cancelEditedTask();
                }}
                icon={
                  <IoIosClose
                    className={twMerge(
                      "fill-success bg-background rounded-full size-7 -mb-7 border-2 border-success",
                    )}
                  />
                }
              />
            )}
            {isEditingTask && (
              <IconButton
                color="transparent"
                className="size-7 px-0 py-0"
                onClick={() => {
                  onUpdateTask();
                }}
                icon={
                  <FaCheck
                    className={twMerge(
                      "fill-success bg-background rounded-full size-7 -mb-7 border-2 border-success p-0.5",
                    )}
                  />
                }
              />
            )}
            {!isEditingTask && (
              <IconButton
                color="transparent"
                className="size-7 px-0 py-0"
                onClick={() => {
                  setEditedTask(task._id);
                }}
                icon={
                  <MdOutlineEdit
                    className={twMerge(
                      "fill-success bg-background rounded-full size-7 -mb-7 border-2 border-success p-0.5",
                    )}
                  />
                }
              />
            )}
          </div>
        )}
        <div className="text-xs flex-1 ">
          {task._id === "no-task"
            ? "Non ticketed"
            : `${project?.code}-${task.counterId}`}
        </div>
        <div className="flex gap-1">
          {task.assignee && task.assignee._id != userId && (
            <Assignee
              name={task.assignee ? projectUsers[task.assignee._id]?.name : ""}
            />
          )}
          <PartialProgressMeter
            pathClassName={twMerge(
              (theme === "last24" || theme === "sprint") && "stroke-error",
              theme === "next24" && "stroke-primary",
            )}
            percentage={
              theme === "sprint"
                ? task.totalProgress / (hoursInDay * daysInSprint)
                : task.totalProgress / hoursInDay
            }
          />
          {theme === "sprint" && <SprintTimeSpent time={sprintPlan} />}
          {theme !== "sprint" && (
            <TimeSpent
              className={twMerge(
                theme === "last24" && "bg-error",
                theme === "next24" && "bg-primary",
              )}
              time={task.totalProgress}
            />
          )}
        </div>
      </div>
      <div className="font-bold text-ink">{task.title}</div>
      {task.dailyProgress &&
        task.dailyProgress.map((dailyProgress) => (
          <ProgressRow
            dailyProgress={dailyProgress}
            task={task}
            theme={theme}
            isEditingTask={isEditingTask}
            key={`daily-progress-${dailyProgress._id}`}
            onDoneEditing={async () => {
              onUpdateTask();
            }}
            onCancelEditing={cancelEditedTask}
            onEdited={({ editorData, timeSpent }) => {
              if (!isEditingTask) return;
              updateCache({
                dailyProgress: {
                  ...dailyProgress,
                  task,
                },
                editorData,
                timeSpent,
              });
            }}
            updatedTaskNo={updatedTaskNo}
          />
        ))}
    </div>
  );
}
