import { authSlice } from "@redux/slices/auth.slice";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";
import { store } from "@redux/store";

import { api } from "modules/api/generated-api";

export const enhancedDataServiceApi = api.enhanceEndpoints({
  endpoints: {
    CreateProject: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(api.util.invalidateTags(["Projects"]));
      },
    },
    CreateTask: {
      async onQueryStarted({ input }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data.createTask._id) {
          dispatch(
            projectCacheSlice.actions.setTaskColumnProject({
              [data.createTask._id]: {
                column: input.column,
                project: store.getState().projects.current!,
              },
            }),
          );
        }
      },
    },
    GetAllUserProjects: {
      providesTags: ["Projects"],
    },
    GetOneProject: {
      async onQueryStarted(input, { dispatch, queryFulfilled }) {
        if (!input.projectId) {
          return;
        }
        const { data } = await queryFulfilled;
        const taskColumnProjects: Parameters<
          typeof projectCacheSlice.actions.setTaskColumnProject
        >[0] = {};
        data.project.columns.forEach((col) => {
          col.tasks.forEach((task) => {
            taskColumnProjects[task._id] = {
              column: col._id,
              project: data.project._id,
            };
          });
        });
        dispatch(
          projectCacheSlice.actions.setTaskColumnProject(taskColumnProjects),
        );
      },
      providesTags: ["Projects"],
    },
    GetOneTask: {
      providesTags: ["Projects"],
    },
    Login: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(authSlice.actions.setToken(data.login.authToken));
        dispatch(authSlice.actions.setCurrentUser(data.login.user._id));
      },
    },
    MoveColumn: {
      async onQueryStarted({ input }, { dispatch, queryFulfilled }) {
        const patchProject = dispatch(
          api.util.updateQueryData(
            "GetOneProject",
            { projectId: input.projectId! },
            (draft) => {
              const fromIndex = draft.project.columns.findIndex(
                ({ _id }) => _id === input.id,
              );
              const toIndex = input.position;
              const newArr = [...draft.project.columns];
              const [movedElement] = newArr.splice(fromIndex, 1);
              newArr.splice(toIndex, 0, movedElement);
              draft.project.columns = newArr;
            },
          ),
        );
        try {
          delete input.projectId;
          const { data } = await queryFulfilled;
          if (!data.moveColumn._id) {
            patchProject.undo();
          }
        } catch (e) {
          patchProject.undo();
        }
      },
    },
    MoveTask: {
      async onQueryStarted({ input }, { dispatch, queryFulfilled }) {
        const { column: fromColumn, project } =
          store.getState().projectsCache.taskColProject[input.id];
        const patchProject = dispatch(
          api.util.updateQueryData(
            "GetOneProject",
            { projectId: project! },
            (draft) => {
              const newColumns = [...draft.project.columns];
              const fromColumnIndex = newColumns.findIndex(
                ({ _id }) => _id === fromColumn,
              );
              const fromTaskIndex = newColumns[fromColumnIndex].tasks.findIndex(
                ({ _id }) => _id === input.id,
              );
              const toColumnIndex = newColumns.findIndex(
                ({ _id }) => _id === input.column,
              );
              const toTaskIndex = input.position;
              const [movedElement] = newColumns[fromColumnIndex].tasks.splice(
                fromTaskIndex,
                1,
              );

              newColumns[toColumnIndex].tasks.splice(
                toTaskIndex,
                0,
                movedElement,
              );
              draft.project.columns = newColumns;
            },
          ),
        );
        try {
          const { data } = await queryFulfilled;
          if (!data.moveTask._id) {
            patchProject.undo();
          } else {
            dispatch(
              projectCacheSlice.actions.setTaskColumnProject({
                [input.id]: {
                  column: input.column,
                  project,
                },
              }),
            );
          }
        } catch (e) {
          patchProject.undo();
        }
      },
    },
    UpdateTask: {
      async onQueryStarted({ input }, { dispatch, queryFulfilled }) {
        if (!input.column) return;

        const { column: fromColumn, project } =
          store.getState().projectsCache.taskColProject[input.id];
        const patchProject = dispatch(
          api.util.updateQueryData(
            "GetOneProject",
            { projectId: project! },
            (draft) => {
              const newColumns = [...draft.project.columns];
              const fromColumnIndex = newColumns.findIndex(
                ({ _id }) => _id === fromColumn,
              );
              const fromTaskIndex = newColumns[fromColumnIndex].tasks.findIndex(
                ({ _id }) => _id === input.id,
              );
              const toColumnIndex = newColumns.findIndex(
                ({ _id }) => _id === input.column,
              );
              const [movedElement] = newColumns[fromColumnIndex].tasks.splice(
                fromTaskIndex,
                1,
              );
              newColumns[toColumnIndex].tasks.unshift(movedElement);
              draft.project.columns = newColumns;
            },
          ),
        );
        try {
          const { data } = await queryFulfilled;
          if (!data.task) {
            patchProject.undo();
          } else {
            dispatch(
              projectCacheSlice.actions.setTaskColumnProject({
                [input.id]: {
                  column: input.column,
                  project,
                },
              }),
            );
          }
        } catch (err) {
          patchProject.undo();
        }
      },
    },
  },
});
