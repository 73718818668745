import { useAppDispatch } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { Button, IconButton } from "components/Button";
import { Select } from "components/Dropdown/Select";
import { useCurrentProject } from "hooks/useCurrentProject";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  Column,
  Label,
  Task,
  useArchiveTaskMutation,
  User,
  useUpdateTaskMutation,
} from "modules/api/generated-api";
import { useSelectLabelStyling } from "modules/design/hooks/useSelectLabelStyling";
import { useNavbar } from "modules/navigation/hooks/useNavbar";
import { useEffect, useState } from "react";
import { NonUndefined } from "react-hook-form";
import { VscSettings } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import { twMerge } from "tailwind-merge";

export function TaskDetails({ task }: { task: Task }) {
  const { projectUsers, project } = useCurrentProject();
  const [updateTask] = useUpdateTaskMutation();
  const [archiveTask] = useArchiveTaskMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setNavbarConfig } = useNavbar();
  const { classNames: labelClassNames, styles: labelStyles } =
    useSelectLabelStyling();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    setNavbarConfig({
      rightIcon: (
        <IconButton
          color="transparent"
          className="top-0 left-0"
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
          icon={<VscSettings className={twMerge("size-8 fill-white")} />}
        />
      ),
      title: `${project?.code}-${task.counterId}`,
    });
    return () => {
      setNavbarConfig({
        rightIcon: undefined,
      });
    };
  }, [isSidebarOpen, setNavbarConfig]);

  const handleUserChange = (selection: SingleValue<User>) => {
    if (selection == null) {
      updateTask({
        input: { assignee: null, id: task._id },
      });
    } else {
      updateTask({ input: { assignee: selection._id, id: task._id } });
    }
  };
  const handleColumnChange = (
    selection: SingleValue<NonUndefined<typeof project>["columns"]["0"]>,
  ) => {
    if (selection !== null) {
      updateTask({ input: { column: selection._id, id: task._id } });
    }
  };
  const handleLabelChange = (
    selection: MultiValue<NonUndefined<typeof project>["labels"][0]>,
  ) => {
    updateTask({
      input: { id: task._id, labels: selection.map((s) => s._id) },
    });
  };

  return (
    <div
      className={twMerge(
        "absolute h-full right-0 translate-x-60 md:translate-x-0 md:relative duration-100 min-w-60 w-60 bg-gray drop-shadow-xl text-xs",
        isSidebarOpen && "translate-x-0",
      )}
    >
      <div className="text-sm p-2 mb-4 border-b border-ink mx-4 text-center font-bold">
        Task Details
      </div>
      <div className="flex flex-col gap-2">
        {projectUsers && (
          <div className="px-4">
            <div>Assignee</div>
            <Select
              value={task.assignee}
              onChange={handleUserChange}
              getOptionLabel={(user) => user.name}
              getOptionValue={(user) => user._id}
              isMulti={false}
              options={Object.values(projectUsers)}
              isClearable
              classNames={{
                option: () => "!text-black ",
              }}
            />
          </div>
        )}
        <div className="px-4">
          <div>Column</div>
          <Select
            value={task.column}
            onChange={handleColumnChange}
            getOptionLabel={(column) => column.title}
            getOptionValue={(column) => column._id}
            isMulti={false}
            options={project?.columns as Column[]}
            isClearable={false}
            classNames={{
              option: () => "!text-black ",
            }}
          />
        </div>
        <div className="px-4">
          <div>Label</div>
          <Select
            value={task.labels}
            onChange={handleLabelChange}
            getOptionLabel={(label) => label.title}
            getOptionValue={(label) => label._id}
            isMulti={true}
            options={project?.labels as Label[]}
            isClearable={false}
            styles={labelStyles}
            classNames={labelClassNames}
            onCreateOption={(input) => {
              dispatch(
                projectCacheSlice.actions.setVar({ addingLabel: input }),
              );
            }}
          />
        </div>
        <div className="text-sm p-2 mb-4 border-b border-ink mx-4 text-center font-bold">
          Task Actions
        </div>
        <div className="flex flex-col px-4">
          <Button
            text="Archive"
            color="white"
            onClick={async () => {
              const success = await apiToastPromise(
                archiveTask({ id: task._id }),
                {
                  error: "Error archiving task",
                  loading: "Archiving task...",
                  success: "Task successfully archived",
                },
              );
              if (!success) return;
              navigate(-1);
            }}
          />
        </div>
      </div>
    </div>
  );
}
