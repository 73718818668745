import { useAppSelector } from "@redux/hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { FormTextInput } from "components/TextInput/FormTextInput";
import { useCreateColumnMutation } from "modules/api/generated-api";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface AddColumnFormInputs {
  title: string;
}

const schema = yup
  .object({
    title: yup.string().required("Title is required"),
  })
  .required();

export function AddColumnModal({ onClose }: { onClose: Function }) {
  const { current } = useAppSelector((state) => state.projects);
  const [createColumn] = useCreateColumnMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddColumnFormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async ({ title }: AddColumnFormInputs) => {
    createColumn({ input: { project: current!, title } });
    onClose();
  };
  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextInput
          id="title"
          label="Column Title"
          type="title"
          error={errors.title}
          register={register}
          className="mb-4"
        />

        <Button type="submit" text="Create" />
      </form>
    </Modal>
  );
}
