import SomethingWentWrong from "../assets/lottie/something-went-wrong.json";

import { useDeviceId } from "hooks/useDeviceId";
import { useSubscribeToCurrentProject } from "hooks/useSubscribeToCurrentProject";
import { useSubscribeToNotifications } from "hooks/useSubscribeToNotifications";
import Lottie from "lottie-react";
import { useCreateErrorMutation } from "modules/api/generated-api";
import { useFirebaseInit } from "modules/firebase/hooks/useFirebaseInit";
import React, { useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";

function App() {
  useDeviceId();
  useSubscribeToCurrentProject();
  useSubscribeToNotifications();
  const { init: firebaseInit } = useFirebaseInit();
  useEffect(() => {
    firebaseInit();
  }, []);
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <Toaster position="bottom-center" />
        <div id="dialog-root"></div>
        <Outlet />
      </ErrorBoundary>
    </>
  );
}

function ErrorScreen({ error, resetErrorBoundary }: FallbackProps) {
  const [createError] = useCreateErrorMutation();
  useEffect(() => {
    createError({
      input: { error: `${error}`, stack: `${(error as Error)?.stack}` },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center">
      <Lottie animationData={SomethingWentWrong} className="size-[400px]" />
      <div className="text-lg">Something went wrong</div>
    </div>
  );
}

export default App;
