import { useAppSelector } from "@redux/hooks";

import { isBusinessDay } from "../functions";

import { eachDayOfInterval, getHours, getMinutes } from "date-fns";
import { useCurrentProject } from "hooks/useCurrentProject";
import { useCallback, useMemo } from "react";

export function useStandupTimeframe() {
  const { project } = useCurrentProject();
  const { currentUser } = useAppSelector((state) => state.auth);

  const isTimeAhead = useCallback((time1: Date, time2: Date) => {
    const hours1 = getHours(time1);
    const minutes1 = getMinutes(time1);
    const hours2 = getHours(time2);
    const minutes2 = getMinutes(time2);

    // Compare hours first
    if (hours1 > hours2) {
      return true; // time1 is ahead
    } else if (hours1 < hours2) {
      return false; // time1 is before
    } else {
      // If hours are the same, compare minutes
      return minutes1 > minutes2 - 1; // true if time1 is ahead, false if before
    }
  }, []);

  const today = new Date();

  const projectTf = useMemo(() => {
    const businessDaysSinceStart = eachDayOfInterval({
      end: today,
      start: project?.standupStart,
    }).filter(isBusinessDay);

    return (
      businessDaysSinceStart.length -
      1 +
      (isBusinessDay(today)
        ? isTimeAhead(today, project?.standupStart)
          ? 1
          : 0
        : 0)
    );
  }, [eachDayOfInterval, today]);

  const tfRemaining = useMemo(() => {
    if (!project) return 0;
    return projectTf - project?.userTimeframes[currentUser!];
  }, [project]);

  return {
    projectTf,
    tfRemaining,
    userTf: project?.userTimeframes[currentUser!],
  };
}
