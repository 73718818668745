import { useAppSelector } from "@redux/hooks";

import { gql, useMutation } from "@apollo/client";
import { wsClient } from "modules/api/ws-api";

export function useSubMutateCurrentProject({
  gqlMutation,
  variables,
}: {
  gqlMutation: string;
  variables?: any;
}) {
  const { current: currentProject } = useAppSelector((state) => state.projects);
  const [mutation] = useMutation(gql(gqlMutation), {
    client: wsClient,
    variables: {
      projectId: currentProject,
      ...(variables ? variables : {}),
    },
  });

  return { mutation };
}
