import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  UseFormProps,
} from "react-hook-form";
import Select, { SingleValue } from "react-select";

type Props = {
  options: (any & { value: string })[];
  placeholder?: string;
};

export function FormSelect<
  FieldValuesTuple extends FieldValues = FieldValues,
  NameTuple extends FieldPath<FieldValuesTuple> = FieldPath<FieldValuesTuple>,
>({
  control,
  name,
  errors,
  placeholder = "",
  options,
}: Props &
  Required<
    Pick<ControllerProps<FieldValuesTuple, NameTuple>, "control" | "name">
  > &
  Pick<UseFormProps<FieldValuesTuple>, "errors">) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          return (
            <div>
              {errors?.[name] && (
                <div className="text-error mb-0 pb-0">
                  {errors?.[name]?.message as string}
                </div>
              )}
              <Select
                ref={ref}
                onChange={(newValue: SingleValue<any> | null) => {
                  onChange(newValue.value);
                }}
                onBlur={onBlur}
                openMenuOnFocus
                value={options.find((t) => t.value === value)}
                options={options}
                placeholder={placeholder}
                classNames={{ control: () => "border !border-black" }}
              />
            </div>
          );
        }}
      />
    </>
  );
}
