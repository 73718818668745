import { CustomText } from "./slate-extension";

import { FaBold, FaCode, FaItalic, FaUnderline } from "react-icons/fa";
import { Editor } from "slate";
import { twMerge } from "tailwind-merge";

type Props = {
  editor: Editor;
  format:
    | "bold"
    | "italic"
    | "underline"
    | "code"
    | "heading-one"
    | "heading-two"
    | "block-quote"
    | "numbered-list"
    | "bulleted-list"
    | "left"
    | "center"
    | "right"
    | "justify";
  marks: Omit<CustomText, "text">;
};

export function FormatButton({ format, marks, editor }: Props) {
  return (
    <button
      className="p-1"
      onClick={(event) => {
        editor.addMark(
          format,
          (marks as any)[format] ? !(marks as any)[format] : true,
        );
        event.preventDefault();
      }}
    >
      <Icon
        format={format}
        className={twMerge((marks as any)[format] ? "fill-black" : "fill-gray")}
      />
    </button>
  );
}

function Icon({
  format,
  className,
}: {
  className?: string;
  format: Props["format"];
}) {
  if (format === "bold") return <FaBold className={className} />;
  if (format === "italic") return <FaItalic className={className} />;
  if (format === "underline") return <FaUnderline className={className} />;
  if (format === "code") return <FaCode className={className} />;
  return <></>;
}
