import { store } from "@redux/store";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

const wsLink = new GraphQLWsLink(
  createClient({
    connectionParams: () => {
      return { Authorization: `Bearer ${store.getState().auth.token}` };
    },
    url: process.env.REACT_APP_WS_BASE ?? "/graphql",
  }),
);

export const wsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: wsLink,
});
