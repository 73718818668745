import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { FormSelect } from "components/Select";
import { availableSprintEstimates } from "components/SmartTextInput/tag-search/choice-types/sprint-estimate";
import { useCurrentProject } from "hooks/useCurrentProject";
import { isNumber } from "lodash";
import { calculateDays } from "modules/daily-progress/functions";
import { hoursInDay } from "modules/sprint-plan/constants";
import { useCallback, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type AddEffortModalInputs = AddEffortFormInputs & {
  updatingIndex?: number;
};

export type AddEffortModalOutputs = Partial<AddEffortModalInputs> & {
  deleting?: boolean;
};

export type AddEffortFormInputs = {
  estimate: number;
  task?: string;
  user: string;
};

const schema = yup
  .object({
    estimate: yup.number().required("Estimate is required"),
    task: yup.string().optional(),
    user: yup.string().required("User is required"),
  })
  .required();

export function AddEffortModal({
  defaultValues,
  onClose,
}: {
  defaultValues?: AddEffortModalInputs;
  onClose: (values: AddEffortModalOutputs) => void;
}) {
  const { project, tasks, projectUsers } = useCurrentProject();
  const { updatingIndex, ...defaultFormValues } = { ...defaultValues };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddEffortFormInputs>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
  });
  const formRef = useRef<HTMLFormElement>(null);

  // Fixes tab change inside modal
  const handleTabChange = useCallback((event: any) => {
    if (event.key !== "Tab") return;
    event.preventDefault();

    // Get all focusable elements within the modal
    const focusableElements: any = formRef.current?.querySelectorAll(
      'button, [href], input#react-select, select, textarea, [tabindex]:not([tabindex="-1"]):not(:disabled)',
    );
    const firstFocusableElement = focusableElements?.[0];
    const lastFocusableElement =
      focusableElements?.[focusableElements.length - 1];

    // If the shift key is pressed and the first element is focused, move focus to the last element
    if (event.shiftKey && document.activeElement === firstFocusableElement) {
      lastFocusableElement?.focus();
      return;
    }

    // If the shift key is not pressed and the last element is focused, move focus to the first element
    if (!event.shiftKey && document.activeElement === lastFocusableElement) {
      firstFocusableElement?.focus();
      return;
    }

    // Otherwise, move focus to the next element
    const direction = event.shiftKey ? -1 : 1;
    const index = Array.prototype.indexOf.call(
      focusableElements,
      document.activeElement,
    );
    const nextElement = focusableElements?.[index + direction];
    if (nextElement) {
      setTimeout(() => {
        nextElement?.focus();
      });
    }
  }, []);

  const onSubmit = async (addEffort: AddEffortFormInputs) => {
    onClose({
      ...addEffort,
      updatingIndex,
    });
  };

  const taskOptions = useMemo(
    () =>
      tasks.map((t) => ({
        label: `${project?.code}-${t.counterId} ${t.title}`,
        value: t._id,
        ...t,
      })),
    [project?.code, tasks],
  );
  const userOptions = useMemo(
    () =>
      Object.values(projectUsers).map((user) => ({
        label: user.name,
        value: user._id,
        ...user,
      })),
    [projectUsers],
  );
  const estimateOptions = useMemo(
    () =>
      availableSprintEstimates.map((e) => {
        const { days, hours } = calculateDays(Number(e) * hoursInDay);
        return {
          label: `${days !== 0 ? `${days}D` : ""}${hours !== 0 ? `${hours}H` : ""}`,
          value: days * hoursInDay + hours,
        };
      }),
    [],
  );

  const isEditing = isNumber(updatingIndex);

  return (
    <Modal onClose={onClose} disableAutoFocus={updatingIndex != null}>
      <form
        ref={formRef}
        onKeyDown={handleTabChange}
        onSubmit={handleSubmit(onSubmit)}
        className="md:w-[400px] flex flex-col gap-2 h-full"
      >
        <div className="text-center text-lg mb-2 mx-20">
          Add feature related effort
        </div>
        <div className="flex-1 flex flex-col justify-center gap-2">
          <FormSelect
            name="task"
            options={taskOptions}
            control={control}
            errors={errors}
            placeholder="Select a task"
          />
          <FormSelect
            name="user"
            options={userOptions}
            control={control}
            errors={errors}
            placeholder="Select a user"
          />
          <FormSelect
            name="estimate"
            options={estimateOptions}
            control={control}
            errors={errors}
            placeholder="Select an estimate"
          />
        </div>
        {isEditing && (
          <Button
            type="button"
            text="Delete"
            color="secondary"
            onClick={() => {
              onClose({ deleting: true, updatingIndex });
            }}
          />
        )}
        <Button type="submit" text={isEditing ? "Edit" : "Create"} />
      </form>
    </Modal>
  );
}
