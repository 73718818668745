import { RouteData } from "../types";

import { useMatches, useRouteLoaderData } from "react-router-dom";

const useRouteData = () => {
  const matches = useMatches();
  const currentRoute = matches[matches.length - 1];

  const currentRouteId = currentRoute ? currentRoute.id : null;
  const routeData = useRouteLoaderData(currentRouteId ?? "") ?? {};

  return routeData as RouteData;
};

export default useRouteData;
