import { NotificationUpdateHandler } from "../notification-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetAllUserNotificationsQuery,
  NotificationSubscriptionResponse,
} from "modules/api/generated-api";

export class NotificationUpdatedHandler implements NotificationUpdateHandler {
  handleNotificationUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetAllUserNotificationsQuery>;
    subscriptionResponse: NotificationSubscriptionResponse;
  }) {
    const { notificationUpdated } = subscriptionResponse;
    if (notificationUpdated) {
      const index = draft.getAllUserNotifications.findIndex(
        ({ _id }) => _id === notificationUpdated._id,
      );
      if (index != -1) {
        draft.getAllUserNotifications[index] = {
          ...draft.getAllUserNotifications[index],
          ...notificationUpdated,
        };
      }
    }
  }
  forSubscription: keyof NotificationSubscriptionResponse =
    "notificationUpdated";
}
