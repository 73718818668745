import { lightOrDark } from "../functions";

import { Label } from "modules/api/generated-api";
import { useMemo } from "react";
import { OptionProps } from "react-select";
import { twMerge } from "tailwind-merge";

type Props = OptionProps<Label, true>;

export function useSelectLabelStyling() {
  const classNames = useMemo(() => {
    return {
      multiValue: ({ data }: Props) =>
        twMerge(lightOrDark(data.color) === "dark" && `!text-white`),
      multiValueLabel: ({ data }: Props) =>
        twMerge(lightOrDark(data.color) === "dark" && `!text-white`),
      option: ({ data, isFocused, isSelected }: Props) =>
        twMerge(
          "!text-black",
          twMerge(
            (isFocused || isSelected) &&
              lightOrDark(data.color) === "dark" &&
              `!text-white`,
          ),
        ),
    };
  }, []) as any;
  const styles = useMemo(() => {
    return {
      multiValue: (base: any, { data }: Props) => ({
        ...base,
        background: data.color,
      }),
      option: (base: any, { isFocused, isSelected, data }: Props) => ({
        ...base,
        background: isFocused || isSelected ? data.color : base.background,
      }),
    };
  }, []) as any;

  return { classNames, styles };
}
