import { useCurrentProject } from "../../../hooks/useCurrentProject";

import { CustomElement } from "components/SmartTextInput/slate-extension";
import { escape } from "lodash";
import { useCallback } from "react";
import { Text } from "slate";

export function useSlateConverter() {
  const { projectUsers } = useCurrentProject();

  const serialize = useCallback(
    (node: any) => {
      if (Text.isText(node)) {
        let string = escape(node.text);
        if (node.bold) {
          string = `<strong>${string}</strong>`;
        }
        const regex =
          /((https?:\/\/|ftp:\/\/|www\.)\S+\.[^()\n ]+((?:\([^)]*\))|[^.,;:?!"'\n\)\]<* ])+)/;
        const matches = regex.exec(string);
        if (matches && matches[0]) {
          return string.replace(
            matches[0],
            `<a class="font-bold text-primary" href="${matches[0]}">${matches[0].split("/").join("/<wbr>")}</a>`,
          );
        }
        return string.split("/").join("/<wbr>");
      }
      const children = node.children?.map((n: any) => serialize(n)).join("");
      switch (node.type as CustomElement["type"]) {
        case "paragraph":
          if (node.children[0].code) {
            return `<span class="block bg-gray p-4 rounded-lg">${children}</span>`;
          }
          return `<span>${children}</span>`;
        case "mention":
          const userId = node.children[0].text.replace("_user:", "").trim();
          return `<b href="">${projectUsers[userId]?.name} </b>`;
        default:
          return children;
      }
    },
    [projectUsers],
  );

  const toHtml = useCallback(
    (content: any[]) => {
      return content.map((content: any) => serialize(content)).join("\n");
    },
    [serialize],
  );

  return {
    toHtml,
  };
}
