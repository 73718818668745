import { initPushNotifications } from "../functions";

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export function useFirebaseInit() {
  const navigate = useNavigate();
  const init = useCallback(() => {
    initPushNotifications({
      onClick: (notification) => {
        notification.close();
        window.focus();
        if (notification.data.url) {
          navigate(notification.data.url);
        }
      },
    });
  }, [navigate]);
  return { init };
}
