import { isNumber } from "lodash";
import { Task } from "modules/api/generated-api";
import { hoursInDay } from "modules/sprint-plan/constants";

export function getTotal(dailyProgress: Task["dailyProgress"]) {
  let total = 0;
  dailyProgress!.forEach((pa) => {
    total += pa.timeSpent;
  });
  return total;
}

export function getTaskDailyProgressForTimeframe(
  task: Task,
  {
    range,
    user,
    timeframe,
  }: { range?: number; timeframe?: number; user?: string } = {},
) {
  if (!task.dailyProgress) return [];
  let returnedProgress = task.dailyProgress!;
  if (user) {
    returnedProgress = returnedProgress.filter(
      (dailyProgress) => dailyProgress.user._id === user,
    );
  }
  if (isNumber(timeframe) && range === undefined) {
    returnedProgress = returnedProgress.filter(
      (dailyProgress) => dailyProgress.timeframe === timeframe,
    );
  } else if (isNumber(timeframe) && range !== undefined) {
    returnedProgress = returnedProgress.filter(
      (dailyProgress) =>
        dailyProgress.timeframe <= timeframe &&
        dailyProgress.timeframe >= timeframe - range,
    );
  }
  return returnedProgress;
}

export function getTaskPlannedProgressForTimeframe(
  task: Task,
  { user, timeframe }: { timeframe?: number; user?: string } = {},
) {
  if (!task.dailyProgress) return [];
  let returnedProgress = task.dailyProgress.filter(
    (dailyProgress) => dailyProgress.isDone === false,
  );
  if (user) {
    returnedProgress = returnedProgress.filter(
      (dailyProgress) => dailyProgress.user._id === user,
    );
  }
  if (isNumber(timeframe)) {
    returnedProgress = returnedProgress.filter(
      (dailyProgress) =>
        dailyProgress.timeframe === timeframe + 1 &&
        dailyProgress.isDone === false,
    );
  }
  return returnedProgress;
}

export function calculateDays(progress: number) {
  const days = Math.floor(progress / hoursInDay);
  const hours = progress - days * hoursInDay;
  return {
    days,
    hours,
  };
}
