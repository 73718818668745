import {
  DropdownChoice,
  TagSearchChoiceType,
} from "components/SmartTextInput/slate-extension";

const Timeframe: TagSearchChoiceType = {
  getChoices: () =>
    ["Last 24", "Next 24"].map(
      (choice) =>
        ({
          type: "timeframe",
          value: choice,
        }) as DropdownChoice,
    ),
};

export default Timeframe;
