import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { FormTextInput } from "components/TextInput/FormTextInput";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import { useCreateTaskMutation } from "modules/api/generated-api";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface AddTaskFormInputs {
  title: string;
}

const schema = yup
  .object({
    title: yup.string().required("Title is required"),
  })
  .required();

export function AddTaskModal({
  colId,
  onClose,
}: {
  colId: string;
  onClose: Function;
}) {
  const [createTask] = useCreateTaskMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddTaskFormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async ({ title }: AddTaskFormInputs) => {
    const success = await apiToastPromise(
      createTask({ input: { column: colId, content: [], title } }),
      {
        error: "Error creating task",
        loading: "Creating task...",
        success: "Task successfully created",
      },
    );
    if (!success) return;
    onClose();
  };
  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextInput
          id="title"
          label="Task Title"
          type="title"
          error={errors.title}
          register={register}
          className="mb-4"
        />

        <Button type="submit" text="Create" />
      </form>
    </Modal>
  );
}
