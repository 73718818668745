import { Props } from "./TaskPlanDisplay.component";

import { PartialProgressMeter } from "components/PartialProgressMeter";
import { SmartTextInput } from "components/SmartTextInput";
import { format } from "date-fns";
import { useSlateConverter } from "modules/slate/hooks/useSlateConverter";
import { useStandupTimeframeFunctions } from "modules/standup/hooks/useStandupTimeframeFunctions";
import { useEffect, useMemo, useState } from "react";
import { Descendant } from "slate";
import { twMerge } from "tailwind-merge";

export function ProgressRow({
  isEditingTask,
  onCancelEditing,
  onDoneEditing,
  onEdited,
  dailyProgress,
  task,
  theme,
  updatedTaskNo,
}: {
  dailyProgress: Props["dailyProgress"][0]["dailyProgress"][0];
  isEditingTask: boolean;
  onCancelEditing: () => void;
  onDoneEditing: () => void;
  onEdited: ({
    timeSpent,
    editorData,
  }: {
    editorData: Descendant[];
    timeSpent: number;
  }) => void;
  task: Props["dailyProgress"][0];
  theme: Props["theme"];
  updatedTaskNo: string; // for forced updates
}) {
  const [editorData, setEditorData] = useState<Descendant[]>(
    dailyProgress.content,
  );
  const [timeSpent, setTimeSpent] = useState(dailyProgress.timeSpent);
  const [initialData, setInitialData] = useState({ editorData, timeSpent });
  const { toHtml } = useSlateConverter();
  const { calculateDateBasedOnTimeframes } = useStandupTimeframeFunctions();

  useEffect(() => {
    setEditorData(initialData.editorData);
    setTimeSpent(initialData.timeSpent);
  }, [initialData, isEditingTask]);
  useEffect(() => {
    if (isEditingTask) {
      const newEditorData = dailyProgress.content;
      if (JSON.stringify(newEditorData) !== JSON.stringify(editorData)) {
        setEditorData(newEditorData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingTask, dailyProgress.content, initialData]);

  useEffect(() => {
    onEdited({ editorData, timeSpent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSpent, editorData, isEditingTask]);

  useEffect(() => {
    if (updatedTaskNo === task._id) {
      setInitialData({ editorData, timeSpent });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedTaskNo]);
  const isEmptyText =
    dailyProgress.content[0].children.length === 1 &&
    dailyProgress.content[0].children[0].text === "";
  const progressDate = useMemo(
    () =>
      calculateDateBasedOnTimeframes({
        daysToAdd: dailyProgress.timeframe,
      }),
    [calculateDateBasedOnTimeframes, dailyProgress.timeframe],
  );
  return (
    <div className="flex-row">
      {isEditingTask && (
        <SmartTextInput
          editorData={editorData}
          onKeyDown={(event) => {
            if (!event.isDefaultPrevented() && event.key === "Escape") {
              onCancelEditing();
            }
          }}
          onChange={setEditorData}
          placeholder="Describe progress ..."
          callbacks={{
            onAddEstimate: setTimeSpent,
            onSubmit: async () => {
              onDoneEditing();
            },
          }}
        />
      )}
      {task.dailyProgress && task.dailyProgress.length > 1 && (
        <PartialProgressMeter
          className="h-3 w-3 mr-1"
          pathClassName={twMerge(
            (theme === "last24" || theme === "sprint") && "stroke-error",
            theme === "next24" && "stroke-primary",
          )}
          percentage={dailyProgress.timeSpent / task.totalProgress}
        />
      )}
      {!isEditingTask && (
        <>
          {theme === "sprint" && (
            <span className="font-semibold text-sm">
              {format(progressDate, "eee d")}
              {isEmptyText ? "" : ": "}
            </span>
          )}
          <span
            className="break-words"
            dangerouslySetInnerHTML={{ __html: toHtml(dailyProgress.content) }}
          />
        </>
      )}
    </div>
  );
}
