import {
  DropdownChoice,
  TagSearchChoiceType,
} from "components/SmartTextInput/slate-extension";

export const availableSprintEstimates = ["0.5", "1", "2", "3", "4", "5"];

const SprintEstimate: TagSearchChoiceType = {
  getChoices: () =>
    availableSprintEstimates.map(
      (choice) =>
        ({
          type: "sprint estimate",
          value: choice,
        }) as DropdownChoice,
    ),
};

export default SprintEstimate;
