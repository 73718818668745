import { api } from "./base-api";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  Boolean: { input: boolean; output: boolean };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  Float: { input: number; output: number };
  ID: { input: string; output: string };
  Int: { input: number; output: number };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
  String: { input: string; output: string };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type Column = {
  __typename?: "Column";
  _id: Scalars["String"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  project: Project;
  tasks: Array<Task>;
  title: Scalars["String"]["output"];
};

export type Comment = {
  __typename?: "Comment";
  _id: Scalars["String"]["output"];
  content: Array<Scalars["JSONObject"]["output"]>;
  isDeleted: Scalars["Boolean"]["output"];
  task: Task;
  ts: Scalars["DateTime"]["output"];
  user: User;
};

export type CreateColumnInput = {
  project: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateCommentInput = {
  content: Array<Scalars["JSONObject"]["input"]>;
  task: Scalars["ID"]["input"];
};

export type CreateDailyProgressInput = {
  content: Array<Scalars["JSONObject"]["input"]>;
  project: Scalars["ID"]["input"];
  task?: InputMaybe<Scalars["ID"]["input"]>;
  timeSpent: Scalars["Float"]["input"];
  timeframe: Scalars["Float"]["input"];
};

export type CreateErrorInput = {
  error: Scalars["String"]["input"];
  stack: Scalars["String"]["input"];
};

export type CreateLabelInput = {
  color: Scalars["String"]["input"];
  project: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateProjectInput = {
  code: Scalars["String"]["input"];
  members?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  name: Scalars["String"]["input"];
  standupStart: Scalars["DateTime"]["input"];
};

export type CreateSprintPlanEffortInput = {
  estimate: Scalars["Float"]["input"];
  task?: InputMaybe<Scalars["ID"]["input"]>;
  user: Scalars["ID"]["input"];
};

export type CreateSprintPlanInput = {
  items: Array<CreateSprintPlanItemInput>;
  sprint: Scalars["ID"]["input"];
  status: SprintPlanStatusEnum;
};

export type CreateSprintPlanItemInput = {
  complete?: InputMaybe<Scalars["Boolean"]["input"]>;
  effort: Array<CreateSprintPlanEffortInput>;
  featureName: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  noSprints?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CreateTaskInput = {
  assignee?: InputMaybe<Scalars["ID"]["input"]>;
  column: Scalars["ID"]["input"];
  content: Array<Scalars["JSONObject"]["input"]>;
  labels?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  title: Scalars["String"]["input"];
  uploads?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type CreateUserInput = {
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type DailyProgress = {
  __typename?: "DailyProgress";
  _id: Scalars["String"]["output"];
  content: Array<Scalars["JSONObject"]["output"]>;
  isDeleted: Scalars["Boolean"]["output"];
  isDone: Scalars["Boolean"]["output"];
  project: Project;
  task?: Maybe<Task>;
  timeSpent: Scalars["Float"]["output"];
  timeframe: Scalars["Float"]["output"];
  ts: Scalars["DateTime"]["output"];
  user: User;
};

export type Effort = {
  __typename?: "Effort";
  estimate: Scalars["Float"]["output"];
  task: Scalars["ID"]["output"];
  user: Scalars["ID"]["output"];
};

export type EndCurrentSprintInput = {
  project: Scalars["ID"]["input"];
};

export type FileUpload = {
  __typename?: "FileUpload";
  _id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  ts: Scalars["DateTime"]["output"];
  user: User;
};

export type Label = {
  __typename?: "Label";
  _id: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  project: Project;
  title: Scalars["String"]["output"];
  ts: Scalars["DateTime"]["output"];
};

export type LoginUserInput = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type LoginUserResponse = {
  __typename?: "LoginUserResponse";
  authToken: Scalars["String"]["output"];
  user: User;
};

export type LogoutUserInput = {
  device: Scalars["String"]["input"];
};

export type MoveColumnInput = {
  id: Scalars["ID"]["input"];
  position: Scalars["Float"]["input"];
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MoveTaskInput = {
  column: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  position: Scalars["Float"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  archiveTask: Task;
  createColumn: Column;
  createComment: Comment;
  createDailyProgress: DailyProgress;
  createError: Scalars["Boolean"]["output"];
  createLabel: Label;
  createProject: Project;
  createSprintPlan: SprintPlan;
  createTask: Task;
  endCurrentSprint: Sprint;
  incrementProjectTimeframe: Project;
  incrementProjectUserTimeframe: Project;
  login: LoginUserResponse;
  logout: User;
  markAllAsRead: Scalars["Boolean"]["output"];
  markDailyProgressDone: DailyProgress;
  moveColumn: Project;
  moveTask: Task;
  removeColumn: Column;
  removeComment: Comment;
  removeDailyProgress: DailyProgress;
  removeLabel: Label;
  removeProject: Project;
  removeTask: Task;
  removeUser: User;
  signup: User;
  suggestSprint: SprintSuggestion;
  triggerMigration: Scalars["JSONObject"]["output"];
  updateColumn: Column;
  updateComment: Comment;
  updateDailyProgress: DailyProgress;
  updateLabel: Label;
  updateMe: User;
  updateNotification: Notification;
  updateProject: Project;
  updateSprintPlan: SprintPlan;
  updateTask: Task;
  updateUser: User;
  uploadFile: FileUpload;
  userSelectedTask: Scalars["String"]["output"];
};

export type MutationArchiveTaskArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateColumnArgs = {
  createColumnInput: CreateColumnInput;
};

export type MutationCreateCommentArgs = {
  createCommentInput: CreateCommentInput;
};

export type MutationCreateDailyProgressArgs = {
  createDailyProgressInput: CreateDailyProgressInput;
};

export type MutationCreateErrorArgs = {
  createErrorInput: CreateErrorInput;
};

export type MutationCreateLabelArgs = {
  createLabelInput: CreateLabelInput;
};

export type MutationCreateProjectArgs = {
  createProjectInput: CreateProjectInput;
};

export type MutationCreateSprintPlanArgs = {
  createSprintPlanInput: CreateSprintPlanInput;
};

export type MutationCreateTaskArgs = {
  createTaskInput: CreateTaskInput;
};

export type MutationEndCurrentSprintArgs = {
  endCurrentSprintInput: EndCurrentSprintInput;
};

export type MutationIncrementProjectTimeframeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationIncrementProjectUserTimeframeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationLoginArgs = {
  loginUserInput: LoginUserInput;
};

export type MutationLogoutArgs = {
  logoutUserInput: LogoutUserInput;
};

export type MutationMarkDailyProgressDoneArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationMoveColumnArgs = {
  input: MoveColumnInput;
};

export type MutationMoveTaskArgs = {
  input: MoveTaskInput;
};

export type MutationRemoveColumnArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveCommentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveDailyProgressArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveLabelArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveProjectArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationRemoveTaskArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveUserArgs = {
  id: Scalars["String"]["input"];
};

export type MutationSignupArgs = {
  signupInput: CreateUserInput;
};

export type MutationSuggestSprintArgs = {
  suggestSprintInput: SuggestSprintInput;
};

export type MutationTriggerMigrationArgs = {
  triggerMigrationInput: TriggerMigrationInput;
};

export type MutationUpdateColumnArgs = {
  updateColumnInput: UpdateColumnInput;
};

export type MutationUpdateCommentArgs = {
  updateCommentInput: UpdateCommentInput;
};

export type MutationUpdateDailyProgressArgs = {
  updateDailyProgressInput: UpdateDailyProgressInput;
};

export type MutationUpdateLabelArgs = {
  updateLabelInput: UpdateLabelInput;
};

export type MutationUpdateMeArgs = {
  updateUserInput: UpdateMeInput;
};

export type MutationUpdateNotificationArgs = {
  updateNotificationInput: UpdateNotificationInput;
};

export type MutationUpdateProjectArgs = {
  updateProjectInput: UpdateProjectInput;
};

export type MutationUpdateSprintPlanArgs = {
  updateSprintPlanInput: UpdateSprintPlanInput;
};

export type MutationUpdateTaskArgs = {
  updateTaskInput: UpdateTaskInput;
};

export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};

export type MutationUploadFileArgs = {
  uploadFileInput: Scalars["Upload"]["input"];
};

export type MutationUserSelectedTaskArgs = {
  id: Scalars["ID"]["input"];
  projectId: Scalars["ID"]["input"];
};

export type Notification = {
  __typename?: "Notification";
  _id: Scalars["String"]["output"];
  fromUser: User;
  isRead: Scalars["Boolean"]["output"];
  message: Scalars["String"]["output"];
  toUser: User;
  ts: Scalars["DateTime"]["output"];
  type: NotificationTypeEnum;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type NotificationSubscriptionResponse = {
  __typename?: "NotificationSubscriptionResponse";
  allRead?: Maybe<Scalars["Boolean"]["output"]>;
  notificationCreated?: Maybe<Notification>;
  notificationUpdated?: Maybe<Notification>;
};

/** Notification types */
export enum NotificationTypeEnum {
  Comment = "comment",
  ProgressReport = "progressReport",
}

export type Project = {
  __typename?: "Project";
  _id: Scalars["String"]["output"];
  /** The code preceeding each ticket in the interface, e.g. SMA in "SMA-001" */
  code: Scalars["String"]["output"];
  columns: Array<Column>;
  currentSprint?: Maybe<Sprint>;
  labels: Array<Label>;
  lastTaskId: Scalars["Int"]["output"];
  members: Array<User>;
  name: Scalars["String"]["output"];
  nextSprint?: Maybe<Sprint>;
  owner: User;
  standupMinsPerUser: Scalars["Int"]["output"];
  standupStart: Scalars["DateTime"]["output"];
  timeframe: Scalars["Int"]["output"];
  userTimeframes: Scalars["JSONObject"]["output"];
};

export type ProjectPaginatedResponse = {
  __typename?: "ProjectPaginatedResponse";
  count: Scalars["Float"]["output"];
  elements: Array<Project>;
};

export type ProjectSubscriptionResponse = {
  __typename?: "ProjectSubscriptionResponse";
  columnCreated?: Maybe<Column>;
  columnRemoved?: Maybe<Scalars["ID"]["output"]>;
  commentCreated?: Maybe<Comment>;
  commentRemoved?: Maybe<Comment>;
  dailyProgressCreated?: Maybe<DailyProgress>;
  dailyProgressRemoved?: Maybe<DailyProgress>;
  dailyProgressUpdated?: Maybe<DailyProgress>;
  projectColumnsUpdated?: Maybe<Project>;
  projectUpdated?: Maybe<Project>;
  sprintPlanCreated?: Maybe<SprintPlan>;
  sprintPlanRemoved?: Maybe<SprintPlan>;
  sprintPlanUpdated?: Maybe<SprintPlan>;
  taskCreated?: Maybe<Task>;
  taskMoved?: Maybe<TaskMoved>;
  taskUpdated?: Maybe<Task>;
  userAction?: Maybe<UserActionResponse>;
  userId?: Maybe<Scalars["ID"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  comment: Comment;
  dailyProgress: DailyProgress;
  getAllUserNotifications: Array<Notification>;
  getAllUserProjects: ProjectPaginatedResponse;
  getOneProject: Project;
  getOneTask: Task;
  label: Label;
  me: User;
  sprintPlan: SprintPlan;
  /** This will be like getting the user profile by his id */
  user: User;
};

export type QueryCommentArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDailyProgressArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryGetAllUserProjectsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["ID"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetOneProjectArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetOneTaskArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryLabelArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySprintPlanArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryUserArgs = {
  id: Scalars["String"]["input"];
};

export type Sprint = {
  __typename?: "Sprint";
  _id: Scalars["String"]["output"];
  dailyProgress: Array<DailyProgress>;
  previousSprint?: Maybe<Sprint>;
  project: Project;
  sprintNumber: Scalars["Int"]["output"];
  sprintPlan?: Maybe<SprintPlan>;
};

export type SprintPlan = {
  __typename?: "SprintPlan";
  _id: Scalars["String"]["output"];
  items: Array<SprintPlanItem>;
  project: Project;
  sprint: Sprint;
  status: SprintPlanStatusEnum;
  ts: Scalars["DateTime"]["output"];
};

export type SprintPlanItem = {
  __typename?: "SprintPlanItem";
  complete?: Maybe<Scalars["Boolean"]["output"]>;
  effort: Array<Effort>;
  featureName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  noSprints?: Maybe<Scalars["Float"]["output"]>;
};

/** Available Sprint Plan Statuses */
export enum SprintPlanStatusEnum {
  Draft = "draft",
  Submitted = "submitted",
}

export type SprintSuggestion = {
  __typename?: "SprintSuggestion";
  _id: Scalars["String"]["output"];
  releases: Array<SprintSuggestionRelease>;
};

export type SprintSuggestionRelease = {
  __typename?: "SprintSuggestionRelease";
  id: Scalars["String"]["output"];
  tasks: Array<SprintSuggestionTask>;
  title: Scalars["String"]["output"];
};

export type SprintSuggestionTask = {
  __typename?: "SprintSuggestionTask";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type Subscription = {
  __typename?: "Subscription";
  subscribeToNotifications: NotificationSubscriptionResponse;
  subscribeToProject: ProjectSubscriptionResponse;
};

export type SubscriptionSubscribeToProjectArgs = {
  id: Scalars["ID"]["input"];
};

export type SuggestSprintInput = {
  description: Scalars["String"]["input"];
};

export type Task = {
  __typename?: "Task";
  _id: Scalars["String"]["output"];
  assignee?: Maybe<User>;
  column?: Maybe<Column>;
  comments?: Maybe<Array<Comment>>;
  content: Array<Scalars["JSONObject"]["output"]>;
  counterId: Scalars["Int"]["output"];
  dailyProgress?: Maybe<Array<DailyProgress>>;
  isArchived: Scalars["Boolean"]["output"];
  labels: Array<Label>;
  project?: Maybe<User>;
  title: Scalars["String"]["output"];
  uploads?: Maybe<Array<FileUpload>>;
};

export type TaskMoved = {
  __typename?: "TaskMoved";
  position: Scalars["Float"]["output"];
  task: Task;
  taskMovedFrom: Scalars["ID"]["output"];
};

export type TriggerMigrationInput = {
  name: Scalars["String"]["input"];
};

export type UpdateColumnInput = {
  id: Scalars["ID"]["input"];
  project?: InputMaybe<Scalars["ID"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCommentInput = {
  content?: InputMaybe<Array<Scalars["JSONObject"]["input"]>>;
  id: Scalars["ID"]["input"];
  task?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateDailyProgressInput = {
  content?: InputMaybe<Array<Scalars["JSONObject"]["input"]>>;
  id: Scalars["ID"]["input"];
  timeSpent?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UpdateLabelInput = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateMeInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type UpdateNotificationInput = {
  id: Scalars["ID"]["input"];
  isRead: Scalars["Boolean"]["input"];
};

export type UpdateProjectInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  members?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  standupStart?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UpdateSprintPlanInput = {
  id: Scalars["ID"]["input"];
  items?: InputMaybe<Array<CreateSprintPlanItemInput>>;
  status?: InputMaybe<SprintPlanStatusEnum>;
};

export type UpdateTaskInput = {
  assignee?: InputMaybe<Scalars["ID"]["input"]>;
  column?: InputMaybe<Scalars["ID"]["input"]>;
  content?: InputMaybe<Array<Scalars["JSONObject"]["input"]>>;
  id: Scalars["ID"]["input"];
  labels?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  uploads?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type UpdateUserInput = {
  _id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type User = {
  __typename?: "User";
  _id: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type UserActionResponse = {
  __typename?: "UserActionResponse";
  selectedTask: Scalars["ID"]["output"];
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  signup: { __typename?: "User"; email: string };
};

export type LoginMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login: {
    __typename?: "LoginUserResponse";
    authToken: string;
    user: { __typename?: "User"; _id: string; email: string };
  };
};

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: "Mutation";
  createComment: { __typename?: "Comment"; _id: string; content: Array<any> };
};

export type RemoveCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveCommentMutation = {
  __typename?: "Mutation";
  removeComment: { __typename?: "Comment"; _id: string };
};

export type CreateLabelMutationVariables = Exact<{
  input: CreateLabelInput;
}>;

export type CreateLabelMutation = {
  __typename?: "Mutation";
  createLabel: { __typename?: "Label"; _id: string };
};

export type RemoveLabelMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveLabelMutation = {
  __typename?: "Mutation";
  removeLabel: { __typename?: "Label"; isDeleted: boolean };
};

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: "Mutation";
  createProject: {
    __typename?: "Project";
    _id: string;
    code: string;
    name: string;
    owner: { __typename?: "User"; email: string };
  };
};

export type GetAllUserProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUserProjectsQuery = {
  __typename?: "Query";
  getAllUserProjects: {
    __typename?: "ProjectPaginatedResponse";
    count: number;
    elements: Array<{
      __typename?: "Project";
      _id: string;
      code: string;
      members: Array<{ __typename?: "User"; email: string }>;
      name: string;
      owner: { __typename?: "User"; email: string };
    }>;
  };
};

export type GetOneProjectQueryVariables = Exact<{
  projectId: Scalars["ID"]["input"];
}>;

export type GetOneProjectQuery = {
  __typename?: "Query";
  project: {
    __typename?: "Project";
    _id: string;
    code: string;
    columns: Array<{
      __typename?: "Column";
      _id: string;
      tasks: Array<{
        __typename?: "Task";
        _id: string;
        assignee?: { __typename?: "User"; _id: string; name: string } | null;
        comments?: Array<{
          __typename?: "Comment";
          _id: string;
          content: Array<any>;
          ts: any;
          user: { __typename?: "User"; _id: string; name: string };
        }> | null;
        counterId: number;
        dailyProgress?: Array<{
          __typename?: "DailyProgress";
          _id: string;
        }> | null;
        labels: Array<{ __typename?: "Label"; _id: string }>;
        title: string;
        uploads?: Array<{
          __typename?: "FileUpload";
          _id: string;
          key: string;
          name: string;
          ts: any;
          user: { __typename?: "User"; _id: string; name: string };
        }> | null;
      }>;
      title: string;
    }>;
    currentSprint?: {
      __typename?: "Sprint";
      _id: string;
      dailyProgress: Array<{
        __typename?: "DailyProgress";
        _id: string;
        content: Array<any>;
        isDone: boolean;
        task?: { __typename?: "Task"; _id: string } | null;
        timeSpent: number;
        timeframe: number;
        ts: any;
        user: { __typename?: "User"; _id: string; name: string };
      }>;
      sprintNumber: number;
      sprintPlan?: {
        __typename?: "SprintPlan";
        _id: string;
        items: Array<{
          __typename?: "SprintPlanItem";
          complete?: boolean | null;
          effort: Array<{
            __typename?: "Effort";
            estimate: number;
            task: string;
            user: string;
          }>;
          featureName: string;
          id: string;
          noSprints?: number | null;
        }>;
        status: SprintPlanStatusEnum;
      } | null;
    } | null;
    labels: Array<{
      __typename?: "Label";
      _id: string;
      color: string;
      title: string;
    }>;
    members: Array<{
      __typename?: "User";
      _id: string;
      email: string;
      name: string;
    }>;
    name: string;
    nextSprint?: {
      __typename?: "Sprint";
      _id: string;
      dailyProgress: Array<{
        __typename?: "DailyProgress";
        _id: string;
        content: Array<any>;
        isDone: boolean;
        task?: { __typename?: "Task"; _id: string } | null;
        timeSpent: number;
        timeframe: number;
        ts: any;
        user: { __typename?: "User"; _id: string; name: string };
      }>;
      sprintNumber: number;
      sprintPlan?: {
        __typename?: "SprintPlan";
        _id: string;
        items: Array<{
          __typename?: "SprintPlanItem";
          complete?: boolean | null;
          effort: Array<{
            __typename?: "Effort";
            estimate: number;
            task: string;
            user: string;
          }>;
          featureName: string;
          id: string;
          noSprints?: number | null;
        }>;
        status: SprintPlanStatusEnum;
      } | null;
    } | null;
    owner: { __typename?: "User"; _id: string; email: string; name: string };
    standupMinsPerUser: number;
    standupStart: any;
    timeframe: number;
    userTimeframes: any;
  };
};

export type IncrementProjectTimeframeMutationVariables = Exact<{
  projectId: Scalars["ID"]["input"];
}>;

export type IncrementProjectTimeframeMutation = {
  __typename?: "Mutation";
  incrementProjectTimeframe: {
    __typename?: "Project";
    _id: string;
    code: string;
    name: string;
    timeframe: number;
    userTimeframes: any;
  };
};

export type IncrementProjectUserTimeframeMutationVariables = Exact<{
  projectId: Scalars["ID"]["input"];
}>;

export type IncrementProjectUserTimeframeMutation = {
  __typename?: "Mutation";
  incrementProjectUserTimeframe: {
    __typename?: "Project";
    _id: string;
    code: string;
    name: string;
    timeframe: number;
    userTimeframes: any;
  };
};

export type UserSelectedTaskMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  projectId: Scalars["ID"]["input"];
}>;

export type UserSelectedTaskMutation = {
  __typename?: "Mutation";
  userSelectedTask: string;
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars["Upload"]["input"];
}>;

export type UploadFileMutation = {
  __typename?: "Mutation";
  uploadFile: { __typename?: "FileUpload"; _id: string; name: string };
};

export type SubscribeToNotificationsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SubscribeToNotificationsSubscription = {
  __typename?: "Subscription";
  subscribeToNotifications: {
    __typename?: "NotificationSubscriptionResponse";
    allRead?: boolean | null;
    notificationCreated?: {
      __typename?: "Notification";
      _id: string;
      fromUser: { __typename?: "User"; _id: string };
      isRead: boolean;
      message: string;
      ts: any;
      type: NotificationTypeEnum;
      url?: string | null;
    } | null;
    notificationUpdated?: {
      __typename?: "Notification";
      _id: string;
      fromUser: { __typename?: "User"; _id: string };
      isRead: boolean;
      message: string;
      ts: any;
      type: NotificationTypeEnum;
      url?: string | null;
    } | null;
  };
};

export type SubscribeToProjectSubscriptionVariables = Exact<{
  subscribeToProjectId: Scalars["ID"]["input"];
}>;

export type SubscribeToProjectSubscription = {
  __typename?: "Subscription";
  subscribeToProject: {
    __typename?: "ProjectSubscriptionResponse";
    columnCreated?: {
      __typename?: "Column";
      _id: string;
      title: string;
    } | null;
    columnRemoved?: string | null;
    commentCreated?: {
      __typename?: "Comment";
      _id: string;
      content: Array<any>;
      task: {
        __typename?: "Task";
        _id: string;
        column?: { __typename?: "Column"; _id: string } | null;
      };
      ts: any;
      user: { __typename?: "User"; _id: string; name: string };
    } | null;
    commentRemoved?: {
      __typename?: "Comment";
      _id: string;
      task: {
        __typename?: "Task";
        _id: string;
        column?: { __typename?: "Column"; _id: string } | null;
      };
    } | null;
    dailyProgressCreated?: {
      __typename?: "DailyProgress";
      _id: string;
      content: Array<any>;
      isDone: boolean;
      task?: {
        __typename?: "Task";
        _id: string;
        column?: { __typename?: "Column"; _id: string } | null;
        title: string;
      } | null;
      timeSpent: number;
      timeframe: number;
      ts: any;
      user: { __typename?: "User"; _id: string; name: string };
    } | null;
    dailyProgressRemoved?: {
      __typename?: "DailyProgress";
      _id: string;
      task?: {
        __typename?: "Task";
        _id: string;
        column?: { __typename?: "Column"; _id: string } | null;
      } | null;
    } | null;
    dailyProgressUpdated?: {
      __typename?: "DailyProgress";
      _id: string;
      content: Array<any>;
      isDone: boolean;
      task?: {
        __typename?: "Task";
        _id: string;
        column?: { __typename?: "Column"; _id: string } | null;
        title: string;
      } | null;
      timeSpent: number;
      timeframe: number;
      ts: any;
      user: { __typename?: "User"; _id: string; name: string };
    } | null;
    projectColumnsUpdated?: {
      __typename?: "Project";
      columns: Array<{ __typename?: "Column"; _id: string }>;
    } | null;
    projectUpdated?: {
      __typename?: "Project";
      currentSprint?: {
        __typename?: "Sprint";
        _id: string;
        dailyProgress: Array<{
          __typename?: "DailyProgress";
          _id: string;
          content: Array<any>;
          isDone: boolean;
          task?: { __typename?: "Task"; _id: string } | null;
          timeSpent: number;
          timeframe: number;
          ts: any;
          user: { __typename?: "User"; _id: string; name: string };
        }>;
        sprintNumber: number;
        sprintPlan?: {
          __typename?: "SprintPlan";
          _id: string;
          items: Array<{
            __typename?: "SprintPlanItem";
            complete?: boolean | null;
            effort: Array<{
              __typename?: "Effort";
              estimate: number;
              task: string;
              user: string;
            }>;
            featureName: string;
            id: string;
            noSprints?: number | null;
          }>;
          status: SprintPlanStatusEnum;
        } | null;
      } | null;
      labels: Array<{
        __typename?: "Label";
        _id: string;
        color: string;
        title: string;
      }>;
      nextSprint?: {
        __typename?: "Sprint";
        _id: string;
        dailyProgress: Array<{
          __typename?: "DailyProgress";
          _id: string;
          content: Array<any>;
          isDone: boolean;
          task?: { __typename?: "Task"; _id: string } | null;
          timeSpent: number;
          timeframe: number;
          ts: any;
          user: { __typename?: "User"; _id: string; name: string };
        }>;
        sprintNumber: number;
        sprintPlan?: {
          __typename?: "SprintPlan";
          _id: string;
          items: Array<{
            __typename?: "SprintPlanItem";
            complete?: boolean | null;
            effort: Array<{
              __typename?: "Effort";
              estimate: number;
              task: string;
              user: string;
            }>;
            featureName: string;
            id: string;
            noSprints?: number | null;
          }>;
          status: SprintPlanStatusEnum;
        } | null;
      } | null;
      timeframe: number;
      userTimeframes: any;
    } | null;
    taskCreated?: {
      __typename?: "Task";
      _id: string;
      column?: { __typename?: "Column"; _id: string; title: string } | null;
      content: Array<any>;
      counterId: number;
      dailyProgress?: Array<{
        __typename?: "DailyProgress";
        _id: string;
      }> | null;
      labels: Array<{
        __typename?: "Label";
        _id: string;
        color: string;
        title: string;
      }>;
      title: string;
    } | null;
    taskMoved?: {
      __typename?: "TaskMoved";
      position: number;
      task: {
        __typename?: "Task";
        _id: string;
        column?: { __typename?: "Column"; _id: string } | null;
      };
      taskMovedFrom: string;
    } | null;
    taskUpdated?: {
      __typename?: "Task";
      _id: string;
      assignee?: { __typename?: "User"; _id: string; name: string } | null;
      column?: { __typename?: "Column"; _id: string; title: string } | null;
      content: Array<any>;
      counterId: number;
      isArchived: boolean;
      labels: Array<{
        __typename?: "Label";
        _id: string;
        color: string;
        title: string;
      }>;
      title: string;
      uploads?: Array<{
        __typename?: "FileUpload";
        _id: string;
        key: string;
        name: string;
        ts: any;
        user: { __typename?: "User"; _id: string; name: string };
      }> | null;
    } | null;
    userAction?: {
      __typename?: "UserActionResponse";
      selectedTask: string;
    } | null;
    userId?: string | null;
  };
};

export type CreateColumnMutationVariables = Exact<{
  input: CreateColumnInput;
}>;

export type CreateColumnMutation = {
  __typename?: "Mutation";
  createColumn: { __typename?: "Column"; _id: string; title: string };
};

export type RemoveColumnMutationVariables = Exact<{
  removeColumnId: Scalars["ID"]["input"];
}>;

export type RemoveColumnMutation = {
  __typename?: "Mutation";
  removeColumn: { __typename?: "Column"; _id: string };
};

export type MoveColumnMutationVariables = Exact<{
  input: MoveColumnInput;
}>;

export type MoveColumnMutation = {
  __typename?: "Mutation";
  moveColumn: { __typename?: "Project"; _id: string };
};

export type ArchiveTaskMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveTaskMutation = {
  __typename?: "Mutation";
  archiveTask: { __typename?: "Task"; isArchived: boolean };
};

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: "Mutation";
  createTask: {
    __typename?: "Task";
    _id: string;
    counterId: number;
    title: string;
  };
};

export type RemoveTaskMutationVariables = Exact<{
  removeTaskId: Scalars["ID"]["input"];
}>;

export type RemoveTaskMutation = {
  __typename?: "Mutation";
  removeTask: { __typename?: "Task"; _id: string };
};

export type MoveTaskMutationVariables = Exact<{
  input: MoveTaskInput;
}>;

export type MoveTaskMutation = {
  __typename?: "Mutation";
  moveTask: { __typename?: "Task"; _id: string };
};

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type UpdateTaskMutation = {
  __typename?: "Mutation";
  task: { __typename?: "Task"; title: string };
};

export type GetOneTaskQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetOneTaskQuery = {
  __typename?: "Query";
  task: {
    __typename?: "Task";
    _id: string;
    assignee?: {
      __typename?: "User";
      _id: string;
      email: string;
      name: string;
    } | null;
    column?: { __typename?: "Column"; _id: string; title: string } | null;
    comments?: Array<{
      __typename?: "Comment";
      _id: string;
      content: Array<any>;
      ts: any;
      user: { __typename?: "User"; _id: string; name: string };
    }> | null;
    content: Array<any>;
    counterId: number;
    dailyProgress?: Array<{
      __typename?: "DailyProgress";
      _id: string;
      content: Array<any>;
      isDone: boolean;
      timeSpent: number;
      timeframe: number;
      ts: any;
      user: { __typename?: "User"; _id: string; name: string };
    }> | null;
    labels: Array<{
      __typename?: "Label";
      _id: string;
      color: string;
      title: string;
    }>;
    title: string;
    uploads?: Array<{
      __typename?: "FileUpload";
      _id: string;
      key: string;
      name: string;
      ts: any;
      user: { __typename?: "User"; _id: string; name: string };
    }> | null;
  };
};

export type CreateDailyProgressMutationVariables = Exact<{
  input: CreateDailyProgressInput;
}>;

export type CreateDailyProgressMutation = {
  __typename?: "Mutation";
  createDailyProgress: {
    __typename?: "DailyProgress";
    _id: string;
    content: Array<any>;
    ts: any;
  };
};

export type RemoveDailyProgressMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveDailyProgressMutation = {
  __typename?: "Mutation";
  removeDailyProgress: { __typename?: "DailyProgress"; _id: string };
};

export type MarkDailyProgressDoneMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type MarkDailyProgressDoneMutation = {
  __typename?: "Mutation";
  markDailyProgressDone: { __typename?: "DailyProgress"; _id: string };
};

export type UpdateDailyProgressMutationVariables = Exact<{
  input: UpdateDailyProgressInput;
}>;

export type UpdateDailyProgressMutation = {
  __typename?: "Mutation";
  updateDailyProgress: { __typename?: "DailyProgress"; _id: string };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me: { __typename?: "User"; _id: string; email: string; name: string };
};

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type UpdateMeMutation = {
  __typename?: "Mutation";
  updateMe: { __typename?: "User"; _id: string };
};

export type LogoutMutationVariables = Exact<{
  input: LogoutUserInput;
}>;

export type LogoutMutation = {
  __typename?: "Mutation";
  logout: { __typename?: "User"; _id: string; email: string; name: string };
};

export type EndCurrentSprintMutationVariables = Exact<{
  input: EndCurrentSprintInput;
}>;

export type EndCurrentSprintMutation = {
  __typename?: "Mutation";
  endCurrentSprint: {
    __typename?: "Sprint";
    _id: string;
    previousSprint?: { __typename?: "Sprint"; _id: string } | null;
    sprintNumber: number;
  };
};

export type CreateSprintPlanMutationVariables = Exact<{
  input: CreateSprintPlanInput;
}>;

export type CreateSprintPlanMutation = {
  __typename?: "Mutation";
  createSprintPlan: { __typename?: "SprintPlan"; _id: string };
};

export type UpdateSprintPlanMutationVariables = Exact<{
  input: UpdateSprintPlanInput;
}>;

export type UpdateSprintPlanMutation = {
  __typename?: "Mutation";
  updateSprintPlan: { __typename?: "SprintPlan"; _id: string };
};

export type GetAllUserNotificationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllUserNotificationsQuery = {
  __typename?: "Query";
  getAllUserNotifications: Array<{
    __typename?: "Notification";
    _id: string;
    fromUser: { __typename?: "User"; _id: string };
    isRead: boolean;
    message: string;
    ts: any;
    type: NotificationTypeEnum;
    url?: string | null;
  }>;
};

export type UpdateNotificationMutationVariables = Exact<{
  input: UpdateNotificationInput;
}>;

export type UpdateNotificationMutation = {
  __typename?: "Mutation";
  updateNotification: { __typename?: "Notification"; _id: string };
};

export type MarkAllAsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllAsReadMutation = {
  __typename?: "Mutation";
  markAllAsRead: boolean;
};

export type CreateErrorMutationVariables = Exact<{
  input: CreateErrorInput;
}>;

export type CreateErrorMutation = {
  __typename?: "Mutation";
  createError: boolean;
};

export type SuggestSprintMutationVariables = Exact<{
  input: SuggestSprintInput;
}>;

export type SuggestSprintMutation = {
  __typename?: "Mutation";
  suggestSprint: {
    __typename?: "SprintSuggestion";
    _id: string;
    releases: Array<{
      __typename?: "SprintSuggestionRelease";
      id: string;
      tasks: Array<{
        __typename?: "SprintSuggestionTask";
        description: string;
        id: string;
        title: string;
      }>;
      title: string;
    }>;
  };
};

export const CreateUserDocument = `
    mutation CreateUser($input: CreateUserInput!) {
  signup(signupInput: $input) {
    email
  }
}
    `;
export const LoginDocument = `
    mutation Login($input: LoginUserInput!) {
  login(loginUserInput: $input) {
    user {
      _id
      email
    }
    authToken
  }
}
    `;
export const CreateCommentDocument = `
    mutation CreateComment($input: CreateCommentInput!) {
  createComment(createCommentInput: $input) {
    content
    _id
  }
}
    `;
export const RemoveCommentDocument = `
    mutation RemoveComment($id: ID!) {
  removeComment(id: $id) {
    _id
  }
}
    `;
export const CreateLabelDocument = `
    mutation CreateLabel($input: CreateLabelInput!) {
  createLabel(createLabelInput: $input) {
    _id
  }
}
    `;
export const RemoveLabelDocument = `
    mutation RemoveLabel($id: ID!) {
  removeLabel(id: $id) {
    isDeleted
  }
}
    `;
export const CreateProjectDocument = `
    mutation CreateProject($input: CreateProjectInput!) {
  createProject(createProjectInput: $input) {
    _id
    name
    code
    owner {
      email
    }
  }
}
    `;
export const GetAllUserProjectsDocument = `
    query GetAllUserProjects {
  getAllUserProjects {
    elements {
      _id
      code
      name
      members {
        email
      }
      owner {
        email
      }
    }
    count
  }
}
    `;
export const GetOneProjectDocument = `
    query GetOneProject($projectId: ID!) {
  project: getOneProject(id: $projectId) {
    _id
    name
    code
    timeframe
    userTimeframes
    standupStart
    standupMinsPerUser
    owner {
      _id
      name
      email
    }
    members {
      _id
      name
      email
    }
    currentSprint {
      _id
      dailyProgress {
        _id
        content
        isDone
        timeSpent
        timeframe
        ts
        task {
          _id
        }
        user {
          _id
          name
        }
      }
      sprintNumber
      sprintPlan {
        _id
        status
        items {
          effort {
            estimate
            task
            user
          }
          featureName
          id
          complete
          noSprints
        }
      }
    }
    nextSprint {
      _id
      dailyProgress {
        _id
        content
        isDone
        timeSpent
        timeframe
        ts
        task {
          _id
        }
        user {
          _id
          name
        }
      }
      sprintNumber
      sprintPlan {
        _id
        status
        items {
          effort {
            estimate
            task
            user
          }
          featureName
          id
          complete
          noSprints
        }
      }
    }
    columns {
      _id
      tasks {
        title
        _id
        counterId
        assignee {
          _id
          name
        }
        dailyProgress {
          _id
        }
        comments {
          _id
          content
          ts
          user {
            _id
            name
          }
        }
        uploads {
          _id
          name
          key
          ts
          user {
            _id
            name
          }
        }
        labels {
          _id
        }
      }
      title
    }
    labels {
      _id
      title
      color
    }
  }
}
    `;
export const IncrementProjectTimeframeDocument = `
    mutation IncrementProjectTimeframe($projectId: ID!) {
  incrementProjectTimeframe(id: $projectId) {
    _id
    timeframe
    userTimeframes
    code
    name
  }
}
    `;
export const IncrementProjectUserTimeframeDocument = `
    mutation IncrementProjectUserTimeframe($projectId: ID!) {
  incrementProjectUserTimeframe(id: $projectId) {
    _id
    timeframe
    userTimeframes
    code
    name
  }
}
    `;
export const UserSelectedTaskDocument = `
    mutation UserSelectedTask($id: ID!, $projectId: ID!) {
  userSelectedTask(id: $id, projectId: $projectId)
}
    `;
export const UploadFileDocument = `
    mutation UploadFile($file: Upload!) {
  uploadFile(uploadFileInput: $file) {
    _id
    name
  }
}
    `;
export const SubscribeToNotificationsDocument = `
    subscription SubscribeToNotifications {
  subscribeToNotifications {
    notificationCreated {
      _id
      message
      isRead
      ts
      fromUser {
        _id
      }
      type
      url
    }
    notificationUpdated {
      _id
      message
      isRead
      ts
      fromUser {
        _id
      }
      type
      url
    }
    allRead
  }
}
    `;
export const SubscribeToProjectDocument = `
    subscription SubscribeToProject($subscribeToProjectId: ID!) {
  subscribeToProject(id: $subscribeToProjectId) {
    columnCreated {
      _id
      title
    }
    columnRemoved
    projectColumnsUpdated {
      columns {
        _id
      }
    }
    projectUpdated {
      timeframe
      userTimeframes
      labels {
        _id
        title
        color
      }
      currentSprint {
        _id
        dailyProgress {
          _id
          content
          isDone
          timeSpent
          timeframe
          ts
          task {
            _id
          }
          user {
            _id
            name
          }
        }
        sprintNumber
        sprintPlan {
          _id
          status
          items {
            effort {
              estimate
              task
              user
            }
            featureName
            id
            complete
            noSprints
          }
        }
      }
      nextSprint {
        _id
        dailyProgress {
          _id
          content
          isDone
          timeSpent
          timeframe
          ts
          task {
            _id
          }
          user {
            _id
            name
          }
        }
        sprintNumber
        sprintPlan {
          _id
          status
          items {
            effort {
              estimate
              task
              user
            }
            featureName
            id
            complete
            noSprints
          }
        }
      }
    }
    dailyProgressCreated {
      _id
      content
      isDone
      timeSpent
      timeframe
      task {
        _id
        title
        column {
          _id
        }
      }
      ts
      user {
        _id
        name
      }
    }
    dailyProgressUpdated {
      _id
      content
      isDone
      timeSpent
      timeframe
      task {
        _id
        title
        column {
          _id
        }
      }
      ts
      user {
        _id
        name
      }
    }
    dailyProgressRemoved {
      _id
      task {
        _id
        column {
          _id
        }
      }
    }
    commentCreated {
      _id
      content
      ts
      task {
        _id
        column {
          _id
        }
      }
      user {
        _id
        name
      }
    }
    commentRemoved {
      _id
      task {
        _id
        column {
          _id
        }
      }
    }
    taskCreated {
      _id
      content
      title
      counterId
      dailyProgress {
        _id
      }
      column {
        _id
        title
      }
      labels {
        _id
        title
        color
      }
    }
    taskMoved {
      task {
        _id
        column {
          _id
        }
      }
      taskMovedFrom
      position
    }
    taskUpdated {
      _id
      assignee {
        _id
        name
      }
      content
      title
      counterId
      column {
        _id
        title
      }
      uploads {
        _id
        name
        key
        ts
        user {
          _id
          name
        }
      }
      labels {
        _id
        title
        color
      }
      isArchived
    }
    userId
    userAction {
      selectedTask
    }
  }
}
    `;
export const CreateColumnDocument = `
    mutation CreateColumn($input: CreateColumnInput!) {
  createColumn(createColumnInput: $input) {
    title
    _id
  }
}
    `;
export const RemoveColumnDocument = `
    mutation RemoveColumn($removeColumnId: ID!) {
  removeColumn(id: $removeColumnId) {
    _id
  }
}
    `;
export const MoveColumnDocument = `
    mutation MoveColumn($input: MoveColumnInput!) {
  moveColumn(input: $input) {
    _id
  }
}
    `;
export const ArchiveTaskDocument = `
    mutation ArchiveTask($id: ID!) {
  archiveTask(id: $id) {
    isArchived
  }
}
    `;
export const CreateTaskDocument = `
    mutation CreateTask($input: CreateTaskInput!) {
  createTask(createTaskInput: $input) {
    counterId
    title
    _id
  }
}
    `;
export const RemoveTaskDocument = `
    mutation RemoveTask($removeTaskId: ID!) {
  removeTask(id: $removeTaskId) {
    _id
  }
}
    `;
export const MoveTaskDocument = `
    mutation MoveTask($input: MoveTaskInput!) {
  moveTask(input: $input) {
    _id
  }
}
    `;
export const UpdateTaskDocument = `
    mutation UpdateTask($input: UpdateTaskInput!) {
  task: updateTask(updateTaskInput: $input) {
    title
  }
}
    `;
export const GetOneTaskDocument = `
    query GetOneTask($id: ID!) {
  task: getOneTask(id: $id) {
    content
    title
    content
    _id
    counterId
    column {
      _id
      title
    }
    assignee {
      _id
      name
      email
    }
    dailyProgress {
      _id
      content
      isDone
      timeSpent
      timeframe
      ts
      user {
        _id
        name
      }
    }
    comments {
      _id
      content
      ts
      user {
        _id
        name
      }
    }
    uploads {
      _id
      name
      key
      ts
      user {
        _id
        name
      }
    }
    labels {
      _id
      title
      color
    }
  }
}
    `;
export const CreateDailyProgressDocument = `
    mutation CreateDailyProgress($input: CreateDailyProgressInput!) {
  createDailyProgress(createDailyProgressInput: $input) {
    _id
    content
    ts
  }
}
    `;
export const RemoveDailyProgressDocument = `
    mutation RemoveDailyProgress($id: ID!) {
  removeDailyProgress(id: $id) {
    _id
  }
}
    `;
export const MarkDailyProgressDoneDocument = `
    mutation MarkDailyProgressDone($id: ID!) {
  markDailyProgressDone(id: $id) {
    _id
  }
}
    `;
export const UpdateDailyProgressDocument = `
    mutation UpdateDailyProgress($input: UpdateDailyProgressInput!) {
  updateDailyProgress(updateDailyProgressInput: $input) {
    _id
  }
}
    `;
export const MeDocument = `
    query Me {
  me {
    _id
    email
    name
  }
}
    `;
export const UpdateMeDocument = `
    mutation UpdateMe($input: UpdateMeInput!) {
  updateMe(updateUserInput: $input) {
    _id
  }
}
    `;
export const LogoutDocument = `
    mutation Logout($input: LogoutUserInput!) {
  logout(logoutUserInput: $input) {
    _id
    email
    name
  }
}
    `;
export const EndCurrentSprintDocument = `
    mutation EndCurrentSprint($input: EndCurrentSprintInput!) {
  endCurrentSprint(endCurrentSprintInput: $input) {
    _id
    previousSprint {
      _id
    }
    sprintNumber
  }
}
    `;
export const CreateSprintPlanDocument = `
    mutation CreateSprintPlan($input: CreateSprintPlanInput!) {
  createSprintPlan(createSprintPlanInput: $input) {
    _id
  }
}
    `;
export const UpdateSprintPlanDocument = `
    mutation UpdateSprintPlan($input: UpdateSprintPlanInput!) {
  updateSprintPlan(updateSprintPlanInput: $input) {
    _id
  }
}
    `;
export const GetAllUserNotificationsDocument = `
    query GetAllUserNotifications {
  getAllUserNotifications {
    _id
    message
    isRead
    ts
    fromUser {
      _id
    }
    type
    url
  }
}
    `;
export const UpdateNotificationDocument = `
    mutation UpdateNotification($input: UpdateNotificationInput!) {
  updateNotification(updateNotificationInput: $input) {
    _id
  }
}
    `;
export const MarkAllAsReadDocument = `
    mutation MarkAllAsRead {
  markAllAsRead
}
    `;
export const CreateErrorDocument = `
    mutation CreateError($input: CreateErrorInput!) {
  createError(createErrorInput: $input)
}
    `;
export const SuggestSprintDocument = `
    mutation SuggestSprint($input: SuggestSprintInput!) {
  suggestSprint(suggestSprintInput: $input) {
    _id
    releases {
      id
      title
      tasks {
        title
        id
        description
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ArchiveTask: build.mutation<
      ArchiveTaskMutation,
      ArchiveTaskMutationVariables
    >({
      query: (variables) => ({ document: ArchiveTaskDocument, variables }),
    }),
    CreateColumn: build.mutation<
      CreateColumnMutation,
      CreateColumnMutationVariables
    >({
      query: (variables) => ({ document: CreateColumnDocument, variables }),
    }),
    CreateComment: build.mutation<
      CreateCommentMutation,
      CreateCommentMutationVariables
    >({
      query: (variables) => ({ document: CreateCommentDocument, variables }),
    }),
    CreateDailyProgress: build.mutation<
      CreateDailyProgressMutation,
      CreateDailyProgressMutationVariables
    >({
      query: (variables) => ({
        document: CreateDailyProgressDocument,
        variables,
      }),
    }),
    CreateError: build.mutation<
      CreateErrorMutation,
      CreateErrorMutationVariables
    >({
      query: (variables) => ({ document: CreateErrorDocument, variables }),
    }),
    CreateLabel: build.mutation<
      CreateLabelMutation,
      CreateLabelMutationVariables
    >({
      query: (variables) => ({ document: CreateLabelDocument, variables }),
    }),
    CreateProject: build.mutation<
      CreateProjectMutation,
      CreateProjectMutationVariables
    >({
      query: (variables) => ({ document: CreateProjectDocument, variables }),
    }),
    CreateSprintPlan: build.mutation<
      CreateSprintPlanMutation,
      CreateSprintPlanMutationVariables
    >({
      query: (variables) => ({ document: CreateSprintPlanDocument, variables }),
    }),
    CreateTask: build.mutation<CreateTaskMutation, CreateTaskMutationVariables>(
      {
        query: (variables) => ({ document: CreateTaskDocument, variables }),
      },
    ),
    CreateUser: build.mutation<CreateUserMutation, CreateUserMutationVariables>(
      {
        query: (variables) => ({ document: CreateUserDocument, variables }),
      },
    ),
    EndCurrentSprint: build.mutation<
      EndCurrentSprintMutation,
      EndCurrentSprintMutationVariables
    >({
      query: (variables) => ({ document: EndCurrentSprintDocument, variables }),
    }),
    GetAllUserNotifications: build.query<
      GetAllUserNotificationsQuery,
      GetAllUserNotificationsQueryVariables | void
    >({
      query: (variables) => ({
        document: GetAllUserNotificationsDocument,
        variables,
      }),
    }),
    GetAllUserProjects: build.query<
      GetAllUserProjectsQuery,
      GetAllUserProjectsQueryVariables | void
    >({
      query: (variables) => ({
        document: GetAllUserProjectsDocument,
        variables,
      }),
    }),
    GetOneProject: build.query<GetOneProjectQuery, GetOneProjectQueryVariables>(
      {
        query: (variables) => ({ document: GetOneProjectDocument, variables }),
      },
    ),
    GetOneTask: build.query<GetOneTaskQuery, GetOneTaskQueryVariables>({
      query: (variables) => ({ document: GetOneTaskDocument, variables }),
    }),
    IncrementProjectTimeframe: build.mutation<
      IncrementProjectTimeframeMutation,
      IncrementProjectTimeframeMutationVariables
    >({
      query: (variables) => ({
        document: IncrementProjectTimeframeDocument,
        variables,
      }),
    }),
    IncrementProjectUserTimeframe: build.mutation<
      IncrementProjectUserTimeframeMutation,
      IncrementProjectUserTimeframeMutationVariables
    >({
      query: (variables) => ({
        document: IncrementProjectUserTimeframeDocument,
        variables,
      }),
    }),
    Login: build.mutation<LoginMutation, LoginMutationVariables>({
      query: (variables) => ({ document: LoginDocument, variables }),
    }),
    Logout: build.mutation<LogoutMutation, LogoutMutationVariables>({
      query: (variables) => ({ document: LogoutDocument, variables }),
    }),
    MarkAllAsRead: build.mutation<
      MarkAllAsReadMutation,
      MarkAllAsReadMutationVariables | void
    >({
      query: (variables) => ({ document: MarkAllAsReadDocument, variables }),
    }),
    MarkDailyProgressDone: build.mutation<
      MarkDailyProgressDoneMutation,
      MarkDailyProgressDoneMutationVariables
    >({
      query: (variables) => ({
        document: MarkDailyProgressDoneDocument,
        variables,
      }),
    }),
    Me: build.query<MeQuery, MeQueryVariables | void>({
      query: (variables) => ({ document: MeDocument, variables }),
    }),
    MoveColumn: build.mutation<MoveColumnMutation, MoveColumnMutationVariables>(
      {
        query: (variables) => ({ document: MoveColumnDocument, variables }),
      },
    ),
    MoveTask: build.mutation<MoveTaskMutation, MoveTaskMutationVariables>({
      query: (variables) => ({ document: MoveTaskDocument, variables }),
    }),
    RemoveColumn: build.mutation<
      RemoveColumnMutation,
      RemoveColumnMutationVariables
    >({
      query: (variables) => ({ document: RemoveColumnDocument, variables }),
    }),
    RemoveComment: build.mutation<
      RemoveCommentMutation,
      RemoveCommentMutationVariables
    >({
      query: (variables) => ({ document: RemoveCommentDocument, variables }),
    }),
    RemoveDailyProgress: build.mutation<
      RemoveDailyProgressMutation,
      RemoveDailyProgressMutationVariables
    >({
      query: (variables) => ({
        document: RemoveDailyProgressDocument,
        variables,
      }),
    }),
    RemoveLabel: build.mutation<
      RemoveLabelMutation,
      RemoveLabelMutationVariables
    >({
      query: (variables) => ({ document: RemoveLabelDocument, variables }),
    }),
    RemoveTask: build.mutation<RemoveTaskMutation, RemoveTaskMutationVariables>(
      {
        query: (variables) => ({ document: RemoveTaskDocument, variables }),
      },
    ),
    SuggestSprint: build.mutation<
      SuggestSprintMutation,
      SuggestSprintMutationVariables
    >({
      query: (variables) => ({ document: SuggestSprintDocument, variables }),
    }),
    UpdateDailyProgress: build.mutation<
      UpdateDailyProgressMutation,
      UpdateDailyProgressMutationVariables
    >({
      query: (variables) => ({
        document: UpdateDailyProgressDocument,
        variables,
      }),
    }),
    UpdateMe: build.mutation<UpdateMeMutation, UpdateMeMutationVariables>({
      query: (variables) => ({ document: UpdateMeDocument, variables }),
    }),
    UpdateNotification: build.mutation<
      UpdateNotificationMutation,
      UpdateNotificationMutationVariables
    >({
      query: (variables) => ({
        document: UpdateNotificationDocument,
        variables,
      }),
    }),
    UpdateSprintPlan: build.mutation<
      UpdateSprintPlanMutation,
      UpdateSprintPlanMutationVariables
    >({
      query: (variables) => ({ document: UpdateSprintPlanDocument, variables }),
    }),
    UpdateTask: build.mutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
      {
        query: (variables) => ({ document: UpdateTaskDocument, variables }),
      },
    ),
    UploadFile: build.mutation<UploadFileMutation, UploadFileMutationVariables>(
      {
        query: (variables) => ({ document: UploadFileDocument, variables }),
      },
    ),
    UserSelectedTask: build.mutation<
      UserSelectedTaskMutation,
      UserSelectedTaskMutationVariables
    >({
      query: (variables) => ({ document: UserSelectedTaskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useCreateUserMutation,
  useLoginMutation,
  useCreateCommentMutation,
  useRemoveCommentMutation,
  useCreateLabelMutation,
  useRemoveLabelMutation,
  useCreateProjectMutation,
  useGetAllUserProjectsQuery,
  useLazyGetAllUserProjectsQuery,
  useGetOneProjectQuery,
  useLazyGetOneProjectQuery,
  useIncrementProjectTimeframeMutation,
  useIncrementProjectUserTimeframeMutation,
  useUserSelectedTaskMutation,
  useUploadFileMutation,
  useCreateColumnMutation,
  useRemoveColumnMutation,
  useMoveColumnMutation,
  useArchiveTaskMutation,
  useCreateTaskMutation,
  useRemoveTaskMutation,
  useMoveTaskMutation,
  useUpdateTaskMutation,
  useGetOneTaskQuery,
  useLazyGetOneTaskQuery,
  useCreateDailyProgressMutation,
  useRemoveDailyProgressMutation,
  useMarkDailyProgressDoneMutation,
  useUpdateDailyProgressMutation,
  useMeQuery,
  useLazyMeQuery,
  useUpdateMeMutation,
  useLogoutMutation,
  useEndCurrentSprintMutation,
  useCreateSprintPlanMutation,
  useUpdateSprintPlanMutation,
  useGetAllUserNotificationsQuery,
  useLazyGetAllUserNotificationsQuery,
  useUpdateNotificationMutation,
  useMarkAllAsReadMutation,
  useCreateErrorMutation,
  useSuggestSprintMutation,
} = injectedRtkApi;
