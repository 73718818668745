export const ROOT_PATH = "/";
export const ONBOARDING_PATH = "/onboarding";
export const STANDUP_PATH = "/standup/:projectId";
export const SPRINT_PATH = "/sprint/:projectId";
export const PROJECT_PATH_CONTAINER = "/:projectId";
export const PROJECT_PATH = "/:projectId/kanban";
export const ROADMAP_PATH = "/:projectId/roadmap";
export const SPRINT_SUGGESTION_PATH = "/:projectId/sprint-suggestion";
export const TASK_PATH = "/:projectId/tasks/:taskId";

// Auth Routes
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
