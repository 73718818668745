import { NotificationUpdateHandler } from "../notification-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetAllUserNotificationsQuery,
  NotificationSubscriptionResponse,
} from "modules/api/generated-api";

export class NotificationCreatedHandler implements NotificationUpdateHandler {
  handleNotificationUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetAllUserNotificationsQuery>;
    subscriptionResponse: NotificationSubscriptionResponse;
  }) {
    const { notificationCreated } = subscriptionResponse;
    if (notificationCreated) {
      draft.getAllUserNotifications.unshift(notificationCreated);
    }
  }
  forSubscription: keyof NotificationSubscriptionResponse =
    "notificationCreated";
}
