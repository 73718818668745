import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { ColorPicker } from "components/ColorPicker";
import { Label } from "components/Label";
import { Modal } from "components/Modal";
import { FormTextInput } from "components/TextInput/FormTextInput";
import { useCurrentProject } from "hooks/useCurrentProject";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import { useCreateLabelMutation } from "modules/api/generated-api";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface AddLabelFormInputs {
  title: string;
}

const schema = yup
  .object({
    title: yup.string().required("Title is required"),
  })

  .required();

export function AddLabelModal({
  initialTitle,
  onClose,
}: {
  initialTitle?: string;
  onClose: (props: AddLabelFormInputs & { _id: string }) => void;
}) {
  const [createLabel] = useCreateLabelMutation();
  const { project } = useCurrentProject();
  const [selectedColor, setSelectedColor] = useState<string>("#FF0000");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AddLabelFormInputs>({
    defaultValues: {
      title: initialTitle,
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = async ({ title }: AddLabelFormInputs) => {
    const response = await apiToastPromise(
      createLabel({
        input: {
          color: selectedColor,
          project: project?._id!,
          title,
        },
      }),
      {
        error: "Failed to create label",
        loading: "Creating label...",
        success: "Label successfully created",
      },
    );

    if (response) {
      onClose({ _id: response.data?.createLabel._id as string, title });
    }
  };
  const title = watch("title");

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        <FormTextInput
          id="title"
          label="Name"
          type="title"
          error={errors.title}
          register={register}
        />
        <ColorPicker
          selectedColor={selectedColor}
          onSelectColor={(color) => {
            setSelectedColor(color.hex);
          }}
        />
        <div className="flex flex-col">
          <div className="font-semibold">Preview</div>
          <Label
            className="mx-2 flex-grow-0 flex-shrink-1"
            color={selectedColor}
            title={title}
          />
        </div>

        <Button type="submit" text="Create" />
      </form>
    </Modal>
  );
}
