import React, { createContext, useContext, useState } from "react";

// Create a context with a default value
const NavbarContext = createContext<{
  navbarConfig: navbarConfig;
  setNavbarConfig: React.Dispatch<React.SetStateAction<navbarConfig>>;
}>({
  navbarConfig: {},
  setNavbarConfig: () => ({}),
});

export const useNavbar = () => {
  return useContext(NavbarContext);
};

type navbarConfig = {
  rightIcon?: JSX.Element;
  title?: string;
};

// Provider component to manage navbar state
export const NavbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [navbarConfig, setNavbarConfig] = useState<navbarConfig>({});

  return (
    <NavbarContext.Provider value={{ navbarConfig, setNavbarConfig }}>
      {children}
    </NavbarContext.Provider>
  );
};
