import React from "react";
import { twMerge } from "tailwind-merge";

const standardColors = [
  { hex: "#FF0000" }, // Red
  { hex: "#00FF00" }, // Green
  { hex: "#0000FF" }, // Blue
  { hex: "#FFFF00" }, // Yellow
  { hex: "#00FFFF" }, // Cyan
  { hex: "#FF00FF" }, // Magenta
  { hex: "#FFA500" }, // Orange
  { hex: "#BA02BA" }, // Purple
  { hex: "#FFC0CB" }, // Pink
  { hex: "#A33939" }, // Brown
  { hex: "#808080" }, // Gray
  { hex: "#ADD8E6" }, // Light Blue
];

export function ColorPicker({
  customColors,
  onSelectColor,
  selectedColor,
  showPreview,
}: {
  customColors?: typeof standardColors;
  onSelectColor?: (color: (typeof colors)[0]) => void;
  selectedColor: (typeof colors)[0]["hex"];
  showPreview?: boolean;
}) {
  const colors = customColors ?? standardColors;

  return (
    <div className="flex flex-col items-center">
      <div className="mb-4">
        <div className="grid grid-cols-4">
          {colors.map((color, index) => (
            <div
              key={`col-${index}`}
              className={twMerge(
                `size-8 cursor-pointer rounded-lg border-2 border-background hover:border-gray-400`,
                selectedColor === color.hex && "border-black",
              )}
              style={{ backgroundColor: color.hex }}
              onClick={() => {
                onSelectColor && onSelectColor(color);
              }}
            ></div>
          ))}
        </div>
      </div>
      {showPreview && (
        <div
          className="size-10 w-20 rounded-lg"
          style={{ backgroundColor: selectedColor }}
        >
          <p className="text-center text-white font-bold">{selectedColor}</p>
        </div>
      )}
    </div>
  );
}
