import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { useEffect, useRef } from "react";

export function useSaveScrollPosition() {
  const dispatch = useAppDispatch();
  const { scrollPositionXKanban } = useAppSelector(
    (state) => state.projectsCache.vars,
  );
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let scrollLeft = scrollPositionXKanban || 0;
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollLeft;
      scrollRef.current.onscroll = () => {
        if (!scrollRef.current) return;
        scrollLeft = scrollRef.current.scrollLeft;
      };
    }
    return () => {
      dispatch(
        projectCacheSlice.actions.setVar({ scrollPositionXKanban: scrollLeft }),
      );
    };
  }, []);

  return { scrollRef };
}
