import { SprintPlanItemComponent } from "./SprintPlanItem.component";

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from "@hello-pangea/dnd";
import { yupResolver } from "@hookform/resolvers/yup";
import { Assignee } from "components/Assignee";
import { Button } from "components/Button";
import { SprintTimeSpent } from "components/TimeSpent/SprintTimeSpent";
import { useCurrentProject } from "hooks/useCurrentProject";
import { isNumber } from "lodash";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  SprintPlan,
  SprintPlanItem,
  SprintPlanStatusEnum,
  useCreateSprintPlanMutation,
  useUpdateSprintPlanMutation,
} from "modules/api/generated-api";
import { calculateDays } from "modules/daily-progress/functions";
import { useDailyTasks } from "modules/daily-progress/hooks/useDailyTasks";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import * as yup from "yup";

type Props = {
  mode: "retrospective" | "planning";
  taskProgress?: ReturnType<typeof useDailyTasks>["tasks"];
  viewOnly?: boolean;
};

export function SprintPlanDisplay({ viewOnly, taskProgress, mode }: Props) {
  const [sprintPlan, setSprintPlan] = useState<SprintPlanItem[]>([]);
  const { project, projectUsers } = useCurrentProject();
  const timerRef = useRef<NodeJS.Timeout>();

  const [createSprintPlan] = useCreateSprintPlanMutation();
  const [updateSprintPlan] = useUpdateSprintPlanMutation();

  const sprint =
    mode === "retrospective" ? project?.currentSprint : project?.nextSprint;

  const schema = yup.object({
    sprintPlan: yup
      .array(
        yup.object({
          complete: yup.boolean().nullable(),
          effort: yup
            .array(
              yup.object({
                estimate: yup.number().required("Estimate is required"),
                task: yup
                  .string()
                  .required(
                    "Task is required for all efforts, click on the pill that doesn't have a number to edit it",
                  ),
                user: yup.string().required("User is required"),
              }),
            )
            .min(
              1,
              "Feature must have at least one effort added, click on the '+' to add",
            )
            .required(),
          featureName: yup.string().required("Feature name is required"),
          id: yup.string().required(),
        }),
      )
      .min(1, "Add features to your sprint plan before submitting")
      .required(),
  });

  const {
    setValue,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitted, isValid },
  } = useForm<{ sprintPlan: SprintPlanItem[] }>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("sprintPlan", sprintPlan, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [setValue, sprintPlan, trigger]);

  useEffect(() => {
    if (sprint?.sprintPlan) {
      setSprintPlan([...sprint?.sprintPlan.items]);
    }
    setTimeout(() => {
      clearTimeout(timerRef.current as any);
    }, 200);
  }, [sprint?.sprintPlan]);

  useEffect(() => {
    if (sprintPlan == null) {
      return;
    }
    if (isValid) {
      clearTimeout(timerRef.current as any);
      timerRef.current = setTimeout(async () => {
        const sprintPlanId = sprint?.sprintPlan?._id;
        const toastText = {
          error: "Error while updating",
          loading: "Updating...",
          success: "Successfully updated",
        };
        const operation = sprintPlanId
          ? apiToastPromise(
              updateSprintPlan({
                input: {
                  id: sprintPlanId,
                  items: sprintPlan,
                  status: SprintPlanStatusEnum.Draft,
                },
              }),
              toastText,
            )
          : apiToastPromise(
              createSprintPlan({
                input: {
                  items: sprintPlan,
                  sprint: sprint?._id!,
                  status: SprintPlanStatusEnum.Draft,
                },
              }),
              toastText,
            );
        await operation;
      }, 500);
    }
  }, [
    createSprintPlan,
    isValid,
    sprint?._id,
    sprint?.sprintPlan?._id,
    sprintPlan,
    updateSprintPlan,
  ]);

  const userEstimates = useMemo(() => {
    const totalHours = sprintPlan
      .flatMap((sprintPlan) => sprintPlan.effort)
      .reduce(
        (acc: { [userId: string]: number }, curr) => ({
          ...acc,
          [curr.user]:
            (acc[curr.user] ? Number(acc[curr.user]) : 0) + curr.estimate,
        }),
        {},
      );
    return Object.keys(totalHours).map((k) => ({
      ...calculateDays(totalHours[k]),
      _id: k,
    }));
  }, [sprintPlan]);

  const errorsStringArray = useMemo(() => {
    let messages: string[] = [];
    const extractMessages = (obj: any) => {
      for (const key in obj) {
        if (obj[key]?.message) {
          messages.push(obj[key].message);
        }
        if (typeof obj[key] === "object") {
          extractMessages(obj[key]); // Recursively search for messages
        }
      }
    };
    extractMessages(errors);
    return messages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);

  const createNewSprintPlan = () => {
    if (mode === "planning" && project?.currentSprint?.sprintPlan?.items) {
      project?.currentSprint?.sprintPlan;
      setSprintPlan([
        ...(project?.currentSprint?.sprintPlan.items
          .filter((sprintPlanItem) => !sprintPlanItem.complete)
          .map((sprintPlanItem) => ({
            ...sprintPlanItem,
            noSprints: (sprintPlanItem.noSprints ?? 0) + 1,
          })) as SprintPlan["items"]),
      ]);
      toast("Automatically added previous incomplete sprint features", {
        duration: 10000,
      });
    } else {
      setSprintPlan([
        ...sprintPlan,
        {
          effort: [],
          featureName: "",
          id: crypto.randomUUID(),
        },
      ]);
    }
  };
  const addNewFeature = () => {
    setSprintPlan([
      ...sprintPlan,
      {
        effort: [],
        featureName: "",
        id: crypto.randomUUID(),
      },
    ]);
  };

  const showEmptyState = !sprint?.sprintPlan && sprintPlan.length === 0;

  return (
    <>
      {showEmptyState && (
        <div className="flex flex-col items-center gap-2 justify-center">
          <div>No sprint plan yet</div>
          <Button text="Create one now" onClick={createNewSprintPlan} />
        </div>
      )}
      {!showEmptyState && (
        <form
          onSubmit={handleSubmit(() => {})}
          className="flex flex-col justify-center items-center gap-2"
        >
          {sprintPlan.length > 1 && (
            <div className="flex justify-center items-center gap-3">
              Totals
              {userEstimates.map(({ days, hours, _id }) => (
                <div
                  key={`estimate-${_id}`}
                  className="flex flex-row justify-center items-center gap-1"
                >
                  <Assignee name={projectUsers[_id || ""]?.name} />
                  <SprintTimeSpent className="" time={{ days, hours }} />
                </div>
              ))}
            </div>
          )}

          <DragDropContext
            onDragStart={() => {
              if (window.navigator.vibrate) {
                window.navigator.vibrate(100);
              }
            }}
            onDragEnd={({ source, destination }) => {
              if (destination?.index == null || !isNumber(destination.index))
                return;
              const reorderedSprintPlan = Array.from(sprintPlan);
              const [planItemMoved] = reorderedSprintPlan.splice(
                source.index,
                1,
              );
              reorderedSprintPlan.splice(destination.index, 0, planItemMoved);
              setSprintPlan(reorderedSprintPlan);
            }}
          >
            <Droppable droppableId={"sprint-plan"} direction="vertical">
              {(provided: DroppableProvided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={twMerge("flex flex-col w-full items-center")}
                >
                  {sprintPlan.map((sprintPlan, index) => (
                    <Draggable
                      key={`sprintplan-draggable-${sprintPlan.id}`}
                      draggableId={sprintPlan.id}
                      index={index}
                    >
                      {(provided: DraggableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={"mb-2 w-full max-w-[800px] relative"}
                        >
                          <div className="absolute rounded-full border size-6 -left-8 text-center">
                            {index + 1}
                          </div>
                          <SprintPlanItemComponent
                            taskProgress={taskProgress}
                            errors={errors.sprintPlan?.[index]}
                            sprintPlanItem={sprintPlan}
                            key={`sprint-plan-${index}`}
                            onUpdate={(update, shouldDelete) => {
                              if (shouldDelete) {
                                setSprintPlan((previousValue) => {
                                  previousValue.splice(index, 1);
                                  return [...previousValue];
                                });
                                return;
                              }
                              setSprintPlan((previousValue) => {
                                previousValue[index] = Object.assign(
                                  {},
                                  previousValue[index],
                                  update,
                                );
                                return [...previousValue];
                              });
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {isSubmitted && !isValid && errorsStringArray.length > 0 && (
            <div>
              {errorsStringArray.map((err, index) => (
                <div className="text-error" key={`error-${index}`}>
                  - {err}
                </div>
              ))}
            </div>
          )}
          <div className="flex gap-2 self-stretch justify-center items-center">
            <Button text="Add feature" type="button" onClick={addNewFeature} />
            {/* @TODO: maybe remove */}
            {/* <Button color="white" text="Submit" type="submit" /> */}
          </div>
        </form>
      )}
    </>
  );
}
