import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const styles = tv({
  compoundVariants: [
    {
      class: { button: "bg-primary hover:bg-primary-hover focus:ring-primary" },
      color: "primary",
    },
    {
      class: {
        button: "bg-success hover:bg-success-hover focus:ring-success",
      },
      color: "secondary",
    },
    {
      class: {
        button: "bg-error hover:bg-error-hover focus:ring-error",
      },
      color: "error",
    },
    {
      class: {
        button:
          "bg-transparent hover:bg-transparent-hover focus:ring-transparent text-ink",
      },
      color: "transparent",
    },
    {
      class: {
        button: "bg-white hover:bg-white-hover focus:ring-white text-ink",
      },
      color: "white",
    },
  ],
  slots: {
    button: "text-white p-1 rounded",
  },
  variants: {
    color: {
      error: {},
      primary: {},
      secondary: {},
      transparent: {},
      white: {},
    },
  },
});

type Variant = {
  [K in keyof (typeof styles)["variants"]]: keyof (typeof styles)["variants"][K];
};

type ButtonColor = Variant["color"];

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  color?: ButtonColor;
  icon: JSX.Element;
  text?: string;
}

const IconButton = ({
  className,
  text,
  color = "primary",
  icon,
  ...props
}: IconButtonProps) => {
  const classNames = styles({ color });
  return (
    <button {...props} className={twMerge(classNames.button(), className)}>
      {icon}
      {text}
    </button>
  );
};

export default IconButton;
