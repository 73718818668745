import { useAppDispatch } from "@redux/hooks";
import { authSlice } from "@redux/slices/auth.slice";
import { projectSlice } from "@redux/slices/project.slice";

import { api } from "modules/api/base-api";
import {
  useCreateUserMutation,
  useLoginMutation,
} from "modules/api/generated-api";
import { restApi } from "modules/api/rest-api";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const [createUser] = useCreateUserMutation();
  const [_login] = useLoginMutation();

  const login = async (data: { email: string; password: string }) => {
    const response = await _login({ input: data });
    if (!response?.data?.login.authToken) return;
    dispatch(authSlice.actions.setToken(response?.data?.login.authToken));
  };

  const signup = async (data: {
    email: string;
    name: string;
    password: string;
  }) => {
    await createUser({ input: data });
  };

  const logout = () => {
    dispatch(authSlice.actions.logout());
    dispatch(api.util.resetApiState());
    dispatch(restApi.util.resetApiState());
    dispatch(projectSlice.actions.setCurrentProject(undefined));
  };

  return { login, logout, signup };
};

export default useAuth;
