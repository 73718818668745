import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import React from "react";

export default function Container({
  children,
  callbacks,
}: {
  callbacks: Callbacks;
  children: any;
}) {
  return (
    <DragDropContext
      onDragStart={() => {
        if (window.navigator.vibrate) {
          window.navigator.vibrate(100);
        }
      }}
      onDragEnd={callbacks.onDragEnd}
    >
      {children}
    </DragDropContext>
  );
}

type Callbacks = {
  onDragEnd: (dropResult: DropResult) => void;
};
