import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { appSlice } from "@redux/slices/app.slice";

import { CustomElement, DropdownChoice, Timeframe } from "../slate-extension";
import { SmartTextInputProps } from "..";

import useChoiceTypes from "./useChoiceTypes";

import { capitalize } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { BaseEditor, Descendant, Editor, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";

const useTagSearch = ({
  editor,
  callbacks,
  ref,
  flags,
}: {
  callbacks: {
    onAddEstimate?: (estimate: number) => void;
    onAddSprintEstimate?: (sprintEstimate: number) => void;
    onAddTimeframe?: (timeframe: Timeframe) => void;
  };
  clearOnSubmit?: boolean;
  editor: BaseEditor & ReactEditor & HistoryEditor;
  editorData?: Descendant[];
  flags: SmartTextInputProps["flags"];
  ref: React.MutableRefObject<HTMLDivElement | null | undefined>;
}) => {
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [target, setTarget] = useState<Range | undefined>();
  const { characterAtUsageCount } = useAppSelector((state) => state.app.flags);
  const dispatch = useAppDispatch();

  const insertMention = (editor: Editor, choice: DropdownChoice) => {
    const mention: CustomElement = {
      character: choice.value,
      children: [{ text: `_user:${choice.id} ` }],
      type: "mention",
    };
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
  };

  const { results } = useChoiceTypes({
    search,
    withoutChoicesFrom: [
      ...(callbacks.onAddTimeframe ? [] : ["Timeframe"]),
      ...(callbacks.onAddEstimate ? [] : ["Estimate"]),
      ...(callbacks.onAddSprintEstimate ? [] : ["SprintEstimate"]),
    ],
  });
  const resultsWithCategories = results.reduce(
    (result: typeof results, currentObj, index) => {
      if (
        index === 0 ||
        (index > 0 && currentObj.type !== results[index - 1].type)
      ) {
        result.push({
          type: "separator",
          value: `${capitalize(currentObj.type)}s`,
        } as any); // Add a separator when type changes
      }
      result.push(currentObj); // Add the value to the result array
      return result;
    },
    [],
  );
  const onSelectChoice = useCallback(
    (choice: DropdownChoice) => {
      if (choice.type === "user") {
        insertMention(editor, results[index]);
      } else if (choice.type === "estimate") {
        Transforms.delete(editor);
        Transforms.move(editor);
        if (callbacks.onAddEstimate) {
          callbacks.onAddEstimate(Number(results[index].value));
        }
      } else if (choice.type === "sprint estimate") {
        Transforms.delete(editor);
        Transforms.move(editor);
        if (callbacks.onAddSprintEstimate) {
          callbacks.onAddSprintEstimate(Number(results[index].value));
        }
      } else if (choice.type === "timeframe") {
        Transforms.delete(editor);
        Transforms.move(editor);
        if (callbacks.onAddTimeframe) {
          callbacks.onAddTimeframe(results[index].value as Timeframe);
        }
      }
    },
    [callbacks, editor, index, results],
  );
  const onKeyDown = useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      if (flags?.countAtCharacterUsage && event.key === "@") {
        if (characterAtUsageCount === undefined || characterAtUsageCount < 5) {
          dispatch(
            appSlice.actions.setFlag({
              characterAtUsageCount:
                characterAtUsageCount === undefined
                  ? 1
                  : characterAtUsageCount + 1,
            }),
          );
        }
      }
      if (target && results.length > 0) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            const prevIndex = index >= results.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case "ArrowUp":
            event.preventDefault();
            const nextIndex = index <= 0 ? results.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();
            Transforms.select(editor, target);
            onSelectChoice(results[index]);
            setTarget(undefined);
            break;
          case "Escape":
            event.preventDefault();
            setTarget(undefined);
            break;
        }
      }
    },
    [
      target,
      results,
      characterAtUsageCount,
      index,
      editor,
      onSelectChoice,
      dispatch,
    ],
  );
  const handleSearch = useCallback(() => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, {
        unit: "word",
      });
      const before =
        wordBefore &&
        (wordBefore.offset === 0
          ? wordBefore
          : Editor.before(editor, wordBefore));
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMentionMatch = beforeText && beforeText.match(/@(\w*)$/);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);
      if (beforeMentionMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMentionMatch[1]);
        setIndex(0);
        return;
      }
    }

    setTarget(undefined);
  }, [editor]);

  const handleChoiceClick = (
    resultWithCategory: (typeof resultsWithCategories)[0],
  ) => {
    if (resultWithCategory.type === "separator" || !target) return;
    Transforms.select(editor, target);
    onSelectChoice(resultWithCategory);
    setTarget(undefined);
  };

  useEffect(() => {
    if (target && results.length > 0 && ref.current) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [results.length, editor, index, search, target]);

  return {
    handleChoiceClick,
    handleSearch,
    index,
    onKeyDown,
    onSelectChoice,
    results,
    resultsWithCategories,
    target,
  };
};

export default useTagSearch;
