import { ProjectUpdateHandler } from "../project-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetOneProjectQuery,
  ProjectSubscriptionResponse,
} from "modules/api/generated-api";

export class ColumnCreatedHandler implements ProjectUpdateHandler {
  handleProjectUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetOneProjectQuery>;
    subscriptionResponse: ProjectSubscriptionResponse;
  }) {
    const { columnCreated } = subscriptionResponse;
    if (columnCreated) {
      draft.project.columns.push({
        ...columnCreated,
        tasks: [],
      });
    }
  }
  forSubscription: keyof ProjectSubscriptionResponse = "columnCreated";
}
