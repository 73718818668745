import { NotificationAllReadHandler } from "./handlers/notification-all-read-handler";
import { NotificationCreatedHandler } from "./handlers/notification-created-handler";
import { NotificationUpdatedHandler } from "./handlers/notification-updated-handler";
import { NotificationUpdateHandler } from "./notification-update-handler.interface";

import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  api,
  NotificationSubscriptionResponse,
} from "modules/api/generated-api";

const updateHandlers: NotificationUpdateHandler[] = [
  new NotificationCreatedHandler(),
  new NotificationUpdatedHandler(),
  new NotificationAllReadHandler(),
];

export function notificationSubscriptionUpdateCaches({
  dispatch,
  subscriptionResponse,
}: {
  dispatch: ThunkDispatch<any, any, UnknownAction>;
  subscriptionResponse: NotificationSubscriptionResponse;
}) {
  const patchesCalled: PatchCollection[] = [];
  updateHandlers.forEach((handler) => {
    if (subscriptionResponse[handler.forSubscription]) {
      if (handler.handleNotificationUpdate) {
        patchesCalled.push(
          dispatch(
            api.util.updateQueryData(
              "GetAllUserNotifications",
              undefined,
              (draft) =>
                handler.handleNotificationUpdate!({
                  draft,
                  subscriptionResponse,
                }),
            ),
          ),
        );
      }
    }
  });

  return patchesCalled;
}
