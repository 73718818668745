import Column from "./3-Column";

import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from "@hello-pangea/dnd";
import { Button } from "components/Button";
import { SprintSuggestion } from "modules/api/generated-api";
import React, { useRef } from "react";
import { twMerge } from "tailwind-merge";

export type DNDSprintControl = {
  delayIndex: number;
  stopped: boolean; // stops animations from being triggered again
};

export default function Table({
  callbacks = {},
  control: _control,
  suggestion,
}: {
  callbacks?: DNDTableCallbacks;
  control?: React.MutableRefObject<DNDSprintControl>;
  suggestion: SprintSuggestion;
}) {
  const controlInternal = useRef<DNDSprintControl>({
    delayIndex: 1,
    stopped: false,
  });
  const control = _control || controlInternal;

  return (
    <Droppable
      droppableId={"sprint-suggestion-column-droppable"}
      type="column"
      direction="horizontal"
    >
      {(provided: DroppableProvided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={twMerge("flex flex-row h-full")}
        >
          {suggestion.releases.map((release, index) => {
            return (
              <Draggable
                key={release.id}
                draggableId={release.id}
                index={index}
              >
                {(
                  provided: DraggableProvided,
                  snapshot: DraggableStateSnapshot,
                ) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={twMerge("mr-4 select-none h-full")}
                  >
                    <Column
                      release={release}
                      provided={provided}
                      snapshot={snapshot}
                      callbacks={callbacks}
                      control={control}
                    />
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
          {callbacks.onAddColumn && (
            <div className="pt-8">
              <Button text="Add Column" onClick={callbacks.onAddColumn} />
            </div>
          )}
        </div>
      )}
    </Droppable>
  );
}

export type DNDTableCallbacks = {
  onAddColumn?: () => void;
  onAddTask?: (releaseId: string) => void;
};
