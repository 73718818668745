import {
  addSeconds,
  differenceInMilliseconds,
  differenceInSeconds,
  format,
} from "date-fns";
import { useCallback, useEffect, useState } from "react";

export function useClock({
  startedSince,
  countdownSeconds,
}: {
  countdownSeconds?: number;
  startedSince: Date;
}) {
  const [time, setTime] = useState(new Date());
  const [percentage, setPercentage] = useState(0);
  const dateDiff = countdownSeconds
    ? differenceInMilliseconds(addSeconds(startedSince, countdownSeconds), time)
    : differenceInMilliseconds(time, startedSince);
  const hours = Math.floor(dateDiff / (1000 * 60 * 60));
  const timeIteration = useCallback(() => {
    setTime(new Date());
    if (countdownSeconds) {
      const percentageToSet =
        (countdownSeconds - differenceInSeconds(new Date(), startedSince)) /
        countdownSeconds;
      setPercentage(percentageToSet > 0 ? percentageToSet : 0);
    }
  }, [startedSince]);
  useEffect(() => {
    timeIteration();
    const interval = setInterval(() => {
      timeIteration();
    }, 1000);

    return () => clearInterval(interval);
  }, [startedSince]);
  return {
    clock: `${hours ? hours + ":" : ""}${format(dateDiff, "mm:ss")}`,
    percentage,
  };
}
