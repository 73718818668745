import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { FormTextInput } from "components/TextInput/FormTextInput";
import { setHours, setMinutes, startOfToday } from "date-fns";
import { useCreateProjectMutation } from "modules/api/generated-api";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface AddProjectFormInputs {
  title: string;
}

const schema = yup
  .object({
    title: yup.string().required("Title is required"),
  })

  .required();

export function AddProjectModal({
  onClose,
}: {
  onClose: (props: AddProjectFormInputs & { _id: string }) => void;
}) {
  const [createProject] = useCreateProjectMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddProjectFormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async ({ title }: AddProjectFormInputs) => {
    // TODO: add timeframe
    // TODO: add standup mins
    const response = await createProject({
      input: {
        code: title.replaceAll(" ", "").substring(0, 3).toUpperCase(),
        name: title,
        standupStart: setMinutes(setHours(startOfToday(), 17), 0),
      },
    });
    onClose({ _id: response.data?.createProject._id as string, title });
  };
  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextInput
          id="title"
          label="Project Title"
          type="title"
          error={errors.title}
          register={register}
          className="mb-4"
        />

        <Button type="submit" text="Create" />
      </form>
    </Modal>
  );
}
